/* eslint-disable no-param-reassign */
import utils from 'utils/utils';
import Model from 'utils/model';

/**
 * Parse related experiments
 * @param data
 */
function parseRelatedExperiments(data) {
    let start = 0;

    return data.split(',').map((experiment) => {
        const [name, traffic] = experiment.split(':');

        if (!traffic) {
            throw new Error('could not parse related experiments');
        }

        const end = parseInt(traffic, 10);
        const item = { name, start, end: start + end };

        start += end;

        return item;
    });
}

const Category = function (options) {
    this.attributes = {
        id: 0
    };

    this.initialize(options);
};

Category.prototype = {
    initialize(data) {
        utils.extend(this.attributes, this.parse(data));
    },

    parse(data) {
        if (data.additional) {
            utils.each(data.additional, (value, key) => {
                if (typeof data[key] === 'undefined') {
                    data[key] = value;
                }
            });

            data.images = utils.extend({}, data.images || {}, {
                background: data.background,
                main: data.image,
                logo: data.logo,
                playerLogo: data.playerLogoImage
            });

            data = utils.omit(data, ['additional', 'background', 'image', 'logo', 'playerLogoImage']);

            try {
                data.relatedExperiments = parseRelatedExperiments(data.metadata.relatedExperiments.value);
            } catch (e) {
                data.relatedExperiments = [];
            }
        }

        return data;
    },

    getRelatedExperiments() {
        return this.get('relatedExperiments') || [];
    },

    isSeries() {
        return this.get('isSeries') === true;
    },

    getLastAsset() {
        return this.get('lastAsset') || {}; // cant be object because of circular dependency
    }
};

utils.extend(Category.prototype, Model);

export default Category;
