export default function (stream, isEnabled) {
    // additional config options required for playback
    return function (config) {
        // allow only when account config is available
        if (isEnabled) {
            if (config.playlist && config.playlist[0]) {
                const streamConfiguration = stream.get('streamConfiguration')
                    && stream.get('streamConfiguration').properties;

                const category = stream.getCategory();

                config.playlist[0].youbora = {
                    'content.resource': stream.getUrl('hls'),
                    'content.isLive': stream.isLive(),
                    'content.title': stream.get('title'),
                    'content.duration': stream.getDuration(),
                    'content.cdn': 'AKAMAI',
                    'content.metadata': {
                        content_id: stream.getId(),
                        owner: stream.getVendor()
                    },

                    'extraparam.1': stream.getId(),
                    'extraparam.2': stream.getVendor(),
                    'extraparam.3': streamConfiguration.join(','),
                    'extraparam.4': category && category.get('id')
                };
            }
        }

        return config;
    };
}
