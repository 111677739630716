/* eslint-disable max-classes-per-file */
import Eventable from 'utils/eventable';
import { copyProperties } from 'utils/mixin';

class PluginModel extends Eventable {
    constructor(options) {
        super();

        this.player = null;
        this.el = null;

        if (typeof this.initialize === 'function') {
            this.initialize(options);
        }
    }

    // eslint-disable-next-line
    getName() {
        throw new Error('getName() method has to be implemented');
    }

    // eslint-disable-next-line
    initialize() {}
    // eslint-disable-next-line
    setup() {}
    // eslint-disable-next-line
    destroy() {}

    setPlayer(player) {
        this.player = player;
        this.setup(player);
    }
}

const createClass = (plugin) => {
    class CustomPluginModel extends PluginModel {}
    copyProperties(CustomPluginModel.prototype, plugin);
    return CustomPluginModel;
};

export {
    createClass
};

export default PluginModel;
