import config from 'player/config';

export default function (vendor) {
    // additional config options required for playback
    return function (jwConfig) {
        const { keys } = config.player;

        jwConfig.key = keys[vendor] || keys.default;

        return jwConfig;
    };
}
