const handle = () => {
    let supposedCurrentTime = 0;
    let video = null;

    const onSeeking = () => {
        // guard against infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        if (Math.abs(video.currentTime - supposedCurrentTime) > 0.01) {
            video.currentTime = supposedCurrentTime;
        }
    };

    const onTimeUpdate = () => {
        if (!video.seeking) {
            supposedCurrentTime = video.currentTime;
        }
    };

    const init = (videoElement) => {
        video = videoElement;

        if (video) {
            // prevent user from seeking
            video.addEventListener('seeking', onSeeking);
            video.addEventListener('timeupdate', onTimeUpdate);
        }
    };

    const remove = () => {
        if (video) {
            video.removeEventListener('seeking', onSeeking);
            video.removeEventListener('timeupdate', onTimeUpdate);
        }
    };

    return {
        init, remove
    };
};

const load = (svpPlayer) => {
    const handler = handle();

    /**
     * Get video tag from SVP Player
     *
     * @param player
     * @returns {Element | any}
     */
    const getVideo = (player) => player.getContainer().querySelector('video');

    /**
     * Add prevent seeking handler for every adslot
     */
    const onAdSlotStart = () => {
        handler.init(getVideo(svpPlayer));
    };

    /**
     * Remove it when ad completes
     */
    const onAdSlotComplete = () => {
        handler.remove();
    };

    svpPlayer.on('adSlotStart', onAdSlotStart);
    svpPlayer.on('adError adSlotComplete', onAdSlotComplete);
};

export { handle };
export default load;
