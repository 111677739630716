import { copyToClipboard } from 'utils/clipboard';

export default (svpPlayer, { icon }) => {
    if (!svpPlayer.player.getControls()) {
        return;
    }

    let isLoaded = false;

    svpPlayer.once('ready', () => {
        const container = svpPlayer.getContainer();

        container.addEventListener('contextmenu', () => {
            if (isLoaded === false) {
                const item = container.querySelectorAll('.jw-rightclick-list .jw-rightclick-item')[0];
                item.classList.add('svp-config-link');

                const infoNode = document.createElement('span');
                infoNode.innerHTML = icon;
                infoNode.classList.add('jw-icon', 'svp-config-info');
                infoNode.dataset.tip = 'Loaded, click to copy';

                item.appendChild(infoNode);

                let isPrettierLoaded = false;

                infoNode.addEventListener('click', async () => {
                    const config = await svpPlayer.config.dump();

                    infoNode.classList.add('svp-link-tip');

                    if (isPrettierLoaded === false) {
                        setTimeout(() => {
                            infoNode.classList.remove('svp-link-tip');
                        }, 1000);

                        isPrettierLoaded = true;
                        infoNode.classList.add('svp-config-info--loaded');
                    } else {
                        infoNode.dataset.tip = 'Copied';

                        copyToClipboard(config);

                        setTimeout(() => {
                            infoNode.classList.remove('svp-link-tip');
                        }, 300);
                    }
                }, false);

                isLoaded = true;
            }
        }, false);
    });
};
