/* global jwplayer */
import Events from './backbone.events';
import device from './device';
import merge from './merge';

/**
 * @param {*[]} tasks
 * @param {Function} fn
 * @returns {Promise<*>}
 */
function sequence(tasks, fn) {
    return tasks.reduce((promise, task) => promise.then(() => fn(task)), Promise.resolve());
}

    var jwUtils = jwplayer.utils,
        jwUnderscore = jwplayer._,
        // jwplayer is hard dependecy here
        utils = {
            Events: Events,

            // methods reused from jwplayer core
            extend: jwUtils.extend,
            // merge with deep copy, similar to jQuery extend
            merge: merge,

            ajax: jwUtils.ajax,

            each: jwUnderscore.each,
            isUndefined: jwUnderscore.isUndefined,
            isNumber: jwUnderscore.isNumber,
            isString: jwUnderscore.isString,
            isArray: jwUnderscore.isArray,
            omit: jwUnderscore.omit,
            memoize: jwUnderscore.memoize,

            createNode: jwUtils.createElement,
            addStyleSheet: jwUtils.addStyleSheet,
            addClass: jwUtils.addClass,
            removeClass: jwUtils.removeClass,
            throttle: jwUnderscore.throttle,
            sequence,

            /* jshint ignore:start */
            /**
             * Template system borrowed from http://ejohn.org/blog/javascript-micro-templating/
             */
            template: (function () {
                var cache = {};

                return function tmpl(str, data) {
                    // Figure out if we're getting a template, or if we need to
                    // load the template - and be sure to cache the result.
                    var fn = !/\W/.test(str) ?
                        cache[str] = cache[str] ||
                            tmpl(document.getElementById(str).innerHTML) :

                        // Generate a reusable function that will serve as a template
                        // generator (and which will be cached).
                        new Function("obj",
                            "var p=[],print=function(){p.push.apply(p,arguments);};" +

                                // Introduce the data as local variables using with(){}
                            "with(obj){p.push('" +

                                // Convert the template into pure JavaScript
                            str
                                .replace(/[\r\t\n]/g, " ")
                                .split("<%").join("\t")
                                .replace(/((^|%>)[^\t]*)'/g, "$1\r")
                                .replace(/\t=(.*?)%>/g, "',$1,'")
                                .split("\t").join("');")
                                .split("%>").join("p.push('")
                                .split("\r").join("\\'")
                            + "');}return p.join('');");

                    // Provide some basic currying to the user
                    return data ? fn(data) : fn({});
                };
            })(),
            /* jshint ignore:end */

            device: device,

            time: {
                durationToSeconds: function (time) {
                    const [hours, minutes, seconds] = time.split(':').map((i) => parseInt(i, 10));
                    return seconds + (60 * minutes) + (60 * 60 * hours);
                },

                shareTimeToSeconds: function (time) {
                    var hours, minutes, seconds, length;

                    time = jwUnderscore.compact(time.split(/[msh]/));
                    length = time.length; // last is empty

                    seconds = parseInt(time[length - 1], 10);
                    minutes = time[length - 2];
                    hours = time[length - 3];

                    return seconds + (minutes ? parseInt(minutes, 10) * 60 : 0) + (hours ? parseInt(hours, 10) * 3600 : 0);
                }
            },


            hasFlash: function () {
                return device.getFlashVersion() > 0;
            },

            number: {
                zeroPad: function (number) {
                    number = parseInt(number, 10);
                    return number < 10 ? '0' + number : number.toString();
                }
            },

            escape: function (str) {
                var escapeMap = {
                    '&': '&amp;',
                    '<': '&lt;',
                    '>': '&gt;',
                    '"': '&quot;',
                    "'": '&#x27;',
                    '`': '&#x60;'
                };

                return str.replace(/[&<>"'`]/g, function replaceTag(tag) {
                    return escapeMap[tag] || tag;
                });
            },

            string: {
                // timestamp [s]
                getTimeFormatted: function (timestamp) {
                    var hours = Math.floor(timestamp / 3600),
                        minutes = Math.floor((timestamp % 3600) / 60),
                        seconds = timestamp % 60;

                    return (hours > 0 ? hours + ':' : '') + utils.number.zeroPad(minutes) + ':' + utils.number.zeroPad(seconds);
                }
            },

            inherit: function (o) {
                function F() {
                } // Dummy constructor

                F.prototype = o;
                return new F();
            },

            model: {
                // Helper function to correctly set up the prototype chain, for subclasses.
                // Similar to `goog.inherits`, but uses a hash of prototype properties and
                // class properties to be extended.
                extend: function (protoProps, staticProps) {
                    var parent = this;
                    var child = function () {
                        return parent.apply(this, arguments);
                    };

                    // Add static properties to the constructor function, if supplied.
                    utils.extend(child, parent, staticProps);

                    // Set the prototype chain to inherit from `parent`, without calling
                    // `parent`'s constructor function.
                    var Surrogate = function () {
                        this.constructor = child;
                    };
                    Surrogate.prototype = parent.prototype;
                    child.prototype = new Surrogate();

                    // Add prototype properties (instance properties) to the subclass,
                    // if supplied.
                    if (protoProps) {
                        utils.extend(child.prototype, protoProps);
                    }

                    // Set a convenience property in case the parent's prototype is needed
                    // later.
                    child.__super__ = parent.prototype;

                    return child;
                }
            },

            /**
             * Check snap points support
             * @returns {boolean}
             */
            getScrollSnapPrefix: function () {
                var scrollSnapSupport = false;

                if('webkitScrollSnapType' in document.documentElement.style) {
                    scrollSnapSupport = '-webkit-';
                } else if('msScrollSnapType' in document.documentElement.style) {
                    scrollSnapSupport = '-ms-';
                } else if('scrollSnapType' in document.documentElement.style) {
                    scrollSnapSupport = '';
                }

                return scrollSnapSupport;
            },

            loadScript: function (path) {
                return new Promise(function (resolve, reject) {
                    var script = document.createElement("script"),
                        done = false;

                    script.type = "text/javascript";
                    script.src = path;
                    script.async = true;
                    script.onreadystatechange = script.onload = function() {
                        if ( !done && (!this.readyState ||
                                this.readyState === "loaded" || this.readyState === "complete") ) {
                            done = true;
                            resolve();

                            // Handle memory leak in IE
                            script.onload = script.onreadystatechange = null;
                        }
                    };
                    script.onerror = reject;

                    document.body.appendChild(script);
                });
            },

            jsonToUrl: function (obj) {
                return Object.keys(obj).map(function(key) {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
                }).join('&');
            }
        };

    /**
     * Polyfill for String.startsWith
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
     */
    (function() {
        if (!String.prototype.startsWith) {
            String.prototype.startsWith = function(searchString, position) {
                position = position || 0;
                return this.indexOf(searchString, position) === position;
            };
        }
    })();

    export default utils;

