import Eventable from 'utils/eventable';

/**
 * Parse SVP Player events and tailor data to pulse requirements
 *
 * Available events: Play, Pause, Stop, Watch
 */
class UiEvents extends Eventable {
    constructor(player) {
        super();
        this.player = player;
        this.listenTo(this.player, 'ready', this.onReady, this);

        this.listenTo(
            this.player,
            'RecommendedPlugin:playNextVisible',
            this.onRecommendedVisible,
            this
        );

        this.listenTo(this.player, 'nextShown', this.onRecommendedVisible, this);
    }

    onRecommendedVisible(event) {
        if (event.ui === 'nextup') {
            this.trigger('UiElement', {
                id: this.player.getAsset().get('id'),
                name: 'nextAssetCountdown'
            });
        }
    }

    destroy() {
        this.stopListening();
    }
}

export default UiEvents;
