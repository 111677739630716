/* global SVP */
import utils from 'utils/utils';
import logger from 'utils/logger';
import HomadAds from './homad';

import { createHomadSkin } from './homad-skin';
/**
     * Get Ad Player from player container
     *
     * @param parent
     * @param cb
     */
function getAdPlayer(parent, cb) {
    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.type === 'childList') {
                const nodes = mutation.addedNodes;

                if (nodes && nodes[0] && nodes[0].classList.length === 0) {
                    const video = nodes[0].querySelector('video');

                    if (video) {
                        cb(video, nodes[0]);
                    }
                }
            }
        });
    });

    // pass in the target node, as well as the observer options
    observer.observe(parent, {
        attributes: false,
        childList: true,
        characterData: false
    });
}

/**
 * Initialize ad player
 *
 * @param player
 * @returns {Function}
 */
function initializeAdPlayer(player) {
    /**
     * Called every time ad video player is shown
     */
    return function (adPlayer, adPlayerContainer) {
        adPlayerContainer.classList.add('svp-homad-player');
        player.getContainer().classList.remove('jw-state-buffering');
        createHomadSkin(player, adPlayer, adPlayerContainer);
    };
}

function onAdError() {
    const jw = this.player;
    const container = this.getContainer();

    this.hasAdBlocked = true;

    // just to be sure
    this.once('adStarted', function () {
        this.adPlayerLoading = false;
    }, this);

    this.once('adSlotComplete', function () {
        container.classList.remove('svp-homad-loading');
    }, this);

    this.listenToOnce(jw, 'play', function () {
        this.adPlayerLoading = true;
    }, this);

    this.listenToOnce(jw, 'pause', function (options) {
        // first external pause is triggered by homad
        // this equals to ad tag loaded and parsed
        if (options && options.pauseReason === 'external') {
            this.adPlayerLoading = false;
        }
    }, this);
}

function onAdBreakStart(data) {
    const duration = this.getDuration();
    const currentTime = this.getCurrentTime();
    const container = this.getContainer();

    // homad has strange constraints for midroll
    if ((data && data.adposition !== 'mid') || (currentTime > 30 && duration - currentTime > 30)) {
        container.classList.add('svp-homad-loading');

        // clean loading in case of any error
        setTimeout(function () {
            container.classList.remove('svp-homad-loading');
        }, 5000);

        this.listenToOnce(this.player, 'adPlay', () => {
            container.classList.remove('svp-homad-loading');
        });
    }
}

/**
     * Forward events which are the same
     * @param eventName
     */
function forwardEvent(eventName) {
    this.listenTo(this.events, eventName, function () {
        // trigger ad events only while ads are playing
        if (this.adPlaying === true) {
            // clear event data
            this.trigger(eventName);
        }
    }, this);
}

function onReady() {
    if (SVP.Player.isHomadLoaded !== true) {
        return;
    }

    // events are matched by player id
    const playerId = this.config.get('node');

    this.events = HomadAds.getEvents(playerId);

    this.listenTo(this.events, 'contentPlayerPlay', function () {
        this.getContainer().classList.remove('svp-homad-loading');
    }, this);

    this.listenTo(this.events, 'all', function (event, data) {
        logger('AAB').log(event, data);
    }, this);

    this.listenTo(this.events, 'adStart', function (data) {
        this.adPosition = data.state;

        if (this.adPlaying === false) {
            this.trigger('adSlotStart', {
                position: this.adPosition
            });

            this.adPlaying = true;
        }

        this.trigger('adStarted');
    }, this);

    this.listenTo(this.events, 'continueContent', function () {
        this.getContainer().classList.remove('jw-flag-ads');

        if (this.adPlaying === true) {
            this.trigger('adSlotComplete', {
                position: this.adPosition
            });

            this.adPlaying = false;
        }
    });

    this.listenTo(this.events, 'adTime', function (data) {
        if (data.currentTime && this.adPlaying) {
            this.trigger('adProgress', data.currentTime, data.duration);
        }
    }, this);

    this.listenTo(this.events, 'adMeta', function (data) {
        if (data) {
            this.trigger('adData', {
                adId: data.adId || null,
                impression: data.impressionURL || null
            });
        }
    }, this);

    utils.each(['adComplete', 'adSkipped', 'adPause', 'adPlay'], forwardEvent.bind(this));

    // cleanup events
    this.once('remove', HomadAds.removeEvents.bind(HomadAds, playerId));

    this.listenTo(this.player, 'adError', onAdError.bind(this));
    this.listenTo(this.player, 'adBreakStart', onAdBreakStart.bind(this));

    // turn off ad player loading when there is no ads
    this.listenTo(this.player, 'playlistItem', (playlistItem) => {
        if (playlistItem.item && !playlistItem.item.adschedule) {
            this.adPlaying = false;
            this.adPlayerLoading = false;
        }
    });

    // turn off ad player loading when there is no ads
    this.listenTo(this.player, 'adPlay', () => {
        this.adPlaying = true;
        this.adPlayerLoading = false;
    });

    this.adPlayerLoading = false;

    getAdPlayer(this.getContainer(), initializeAdPlayer(this));
}

export default function (Player) {
    const { setup } = Player.prototype;

    // anti adblock works only for desktops
    if (!utils.device.isDesktop()) {
        return Player;
    }

    /**
         * Proxy player method
         * Call source when adblock is not present
         *
         * @param name
         * @param cb
         */
    function proxyMethod(name, cb) {
        const sourceMethod = Player.prototype[name];

        Player.prototype[name] = function () {
            const args = Array.prototype.slice.call(arguments);

            if (SVP.Player.isHomadLoaded !== true) {
                return sourceMethod.apply(this, args);
            }

            return cb.call(this, sourceMethod, args);
        };
    }

    Player.prototype.setup = function () {
        this.once('ready', onReady);
        return setup.call(this);
    };


    proxyMethod('onFirstFrameLoad', function (onFirstFrameLoad) {
        if (this.hasAdBlocked) {
            this.player.once('play', function () {
                this.player.once('providerFirstFrame', function () {
                    onFirstFrameLoad.call(this, this.playlistItemId);
                }, this);
            }, this);
        } else {
            onFirstFrameLoad.call(this, this.playlistItemId);
        }
    });

    proxyMethod('onTime', function (onTime, args) {
        if (!this.adPlaying && this.adPlayerLoading === false) {
            onTime.apply(this, args);
        }
    });

    // eslint-disable-next-line consistent-return
    proxyMethod('onPlay', function (onPlay, args) {
        if (this.adPlayerLoading === false) {
            return onPlay.apply(this, args);
        }
    });

    //
    proxyMethod('onPause', function (onPause, args) {
        if (this.adPlaying === false && this.adPlayerLoading === false) {
            onPause.apply(this, args);
        }
    });

    proxyMethod('onComplete', function (onComplete, args) {
        if (this.adPlaying === false && (this.adPlayerLoading === false || this.adPlayerLoading === undefined)) {
            onComplete.apply(this, args);
        } else {
            this.listenToOnce(this.events, 'continueContent', function () {
                onComplete.apply(this, args);
            }, this);
        }
    });

    return Player;
}
