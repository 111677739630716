const counters = {};

const log = function(...args) {
    const counter = counters[args[0]] || 0;
    counters[args[0]] = counter + 1;

    args[0] = `${args[0]}: ${counter} %c"${args[1]}"`;
    args[1] = 'color: #ca2117;';

    // eslint-disable-next-line no-console
    console.log(...args);
};

const isEnabled = function(block) {
    const itemKey = `logSvpPlayer${block}`;

    // check global first
    if (window[itemKey] === true) {
        return true;
    }

    // fix Safari errors when cookies are disabled
    try {
        if (!window.localStorage) {
            return false;
        }

        return window.localStorage.getItem(itemKey);
    } catch (error) {
        return false;
    }
};

export default function(block = 'SVP') {
    // eslint-disable-next-line no-console
    const error = (...args) => console.error('SVP Player:', ...args);

    if (isEnabled(block)) {
        return {
            log(...args) {
                if (typeof args[0] === 'function') {
                    args[0](log.bind(this, block));
                } else {
                    args.unshift(block);
                    log.apply(this, args);
                }
            },
            error
        };
    }

    return {
        log() {},
        error
    };
}
