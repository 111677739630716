import ExcludedAssets from '../excluded-assets';

const omitRecurring = (vendor, assetId, assets) => {
    const excludedAssets = ExcludedAssets.getAll(vendor);

    // parse response
    if (!assets) {
        return null;
    }

    let nextAsset = null;

    for (let i = 0; i < assets.length; i += 1) {
        nextAsset = assets[i] || {};

        if (nextAsset.id !== assetId && excludedAssets.indexOf(nextAsset.id) === -1) {
            // exclude asset from recommended list
            ExcludedAssets.add(vendor, nextAsset.id);
            return nextAsset;
        }
    }

    return null;
};

/**
 *
 * @param array
 * @param randomIndex
 * @returns {*}
 */
const getExperimentName = (array, randomIndex) => {
    const element = array.find((item) => randomIndex >= item.start && randomIndex < item.end);

    if (element && element.name) {
        return element.name;
    }

    // fallback to default state
    return null;
};

const getSource = (useMlEngine, experimentsList, seed) => {
    const experimentVariant = Math.floor(seed * 100);

    if (useMlEngine === false) {
        return 'related';
    }

    if (experimentsList.length > 0) {
        const experimentName = getExperimentName(experimentsList, experimentVariant);

        if (experimentName) {
            return `related-exprmnts/${experimentName}`;
        }
    }

    return 'related-ml';
};


export default {
    omitRecurring,
    getSource
};
