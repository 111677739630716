import config from 'player/config';
import Player from 'player/player';
import image from 'utils/image';
import utils from 'utils/utils';
import hasAdblock from 'utils/adblock-detect';

import locale from 'player/model/locale';
import Domain from 'player/model/domain';
import HomadAds from 'player/playback/ads/homad';

import youbora from 'player/playback/youbora';
import SvpPlugins from './player/plugins';

const SVP = {};
SVP.Player = Player;
SVP.Player.Plugins = SvpPlugins;
SVP.Player.version = config.version;

// by default antiadblock is turned off
Object.defineProperty(SVP.Player, 'defaults', {
    get() {
        // eslint-disable-next-line no-underscore-dangle
        return this.__defaults || {};
    },
    set(value) {
        // if params are not object then
        // override and force to object
        if (value !== Object(value)) {
            value = {};
        }

        Object.defineProperty(SVP.Player, '__defaults', {
            value: value,
            enumerable: false,
            configurable: false,
            writable: false
        });
    }
});

/**
 * Determine if user is in 5% range of available traffic
 * We set half of 5% because autoplay gives around 2x traffic
 * @type {boolean}
 */
const loadYoubora = (options) => {
    // youbora plugin can be disabled completely
    try {
        if (localStorage.svpYoubora === 'true' || (options !== false && Math.random() < 0.025)) {
            youbora.load(options);
        }
    } catch (e) {
        // could not load youbora plugin
    }
};

const setLocale = (language = 'no') => {
    locale.setTranslations(config.translations(language));
};

const preInitialize = () => {
    image.initialize(utils.extend(config.images, {
        getPixelRatio: utils.device.getPixelRatio
    }));

    Domain.initialize(window.location.hostname);
};

const postInitialize = () => {
    const { aab } = SVP.Player.defaults;

    // set locale for all players
    setLocale(SVP.Player.defaults.locale);

    // load homad only for desktop
    // detect if adblock exist for "auto" mode
    // force loading when mode is set to "true" (forces for all desktops)
    if (utils.device.getDeviceType() === 'desktop' && ((aab === 'auto' && hasAdblock() === true) || aab === true)) {
        HomadAds.initialize();
    }

    loadYoubora(SVP.Player.defaults.youbora);
};

export default SVP;
export { preInitialize, postInitialize };
