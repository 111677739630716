import { capitalizeFirstLetter } from 'utils/string';
import logger from 'utils/logger';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import PluginModel from './model';
import PulseTracker from './pulse-stats/tracker';
import AdEvents from './pulse-stats/ad-events';
import PlaybackEvents from './pulse-stats/playback-events';
import UiEvents from './pulse-stats/ui-events';
import CategoryTree from '../model/category-tree';
import utils from '../../utils/utils';

/**
 * Retrieve tags from asset
 * @param asset
 */
const getTags = (asset) => (asset.get('tags') || [])
    .filter((tag) => isObject(tag) || !isEmpty(tag.tag))
    .map(({ tag }) => tag);

/**
 * Get array of plan3 stories ids
 *
 * @param asset
 * @returns {*|Array}
 */
const getStories = (asset) => (asset.get('stories') || []);

export const getMediaAssetId = (provider, mediaId) => `sdrn:${provider}:mediaasset:${mediaId}`;

/**
 * Integration with Pulse Tracker
 * https://github.schibsted.io/spt-dataanalytics/pulse-autotracker
 *
 * @param options
 * @constructor
 */
class PulseStatsPlugin extends PluginModel {
    constructor(options, experiment) {
        super(options);
        this.provider = options.provider;
        this.decorator = options.decorator || ((data) => data);
        this.embed = options.embed === true;
        this.previousAssetId = options.previousAssetId;
        this.tracker = null;
        this.experiment = experiment;
        this.adSequenceDuration = false;
        this.isHomadLoaded = (utils.device.isDesktop() && get(window, ['SVP', 'Player', 'defaults', 'aab']))
            ? get(window, ['SVP', 'Player', 'isHomadLoaded'], false)
            : undefined;
    }
    // eslint-disable-next-line
    getName() {
        return 'PulseStats';
    }

    get jw() {
        try {
            return this.player.model.player || {};
        } catch (e) {
            return {};
        }
    }

    get asset() {
        const asset = this.playerAsset;
        const additional = asset.get('additional') || {};
        const accessControl = Object.keys(additional.access || {});
        const tags = getTags(asset);
        const stories = getStories(asset);

        const data = {
            assetType: asset.get('assetType') === 'video' ? 'Video' : 'Audio',
            title: asset.get('title'),
            // duration should be in seconds
            duration: Math.round(asset.get('duration') / 1000)
        };

        if (accessControl.length > 0) {
            data.accessControl = accessControl.join(',');
        }

        if (tags.length > 0) {
            data.tags = tags;
        }

        if (stories.length > 0) {
            // disable stories until there is not id->title translation
            // data.stories = stories;
        }

        // check if stream has category
        if (asset.getCategory()) {
            data.category = asset.getCategory().get('id');
        }

        // delete duration from live assets
        if (asset.get('streamType') === 'live') {
            delete data.duration;
        }

        if (additional.originAssetId) {
            data.originAssetId = additional.originAssetId;
        }

        return data;
    }

    getCategoryPath(categoryId) {
        if (typeof categoryId !== 'number') {
            return null;
        }

        // check if id exist and create category path
        if (!this.categoryPath) {
            this.categoryPath = this.categoryTree.getPath(categoryId)
                .map((category) => (category.title.toLowerCase()))
                .join(' > ');
        }

        return this.categoryPath;
    }

    /**
     * Track Pulse event
     * Defer tracking when Pulse library is not loaded yet
     */
    async track(eventName, eventData) {
        const tracker = await this.getTracker();
        const data = {};
        Object.assign(data, eventData, this.experiment && {
            experiments: [{
                id: this.experiment.id,
                variant: this.experiment.variant ? this.experiment.variant.id : '0',
                platform: this.provider
            }]
        });
        logger('Pulse').log(JSON.stringify(data, null, 4));
        tracker.track(eventName, data);
    }

    async trackMediaEvent(data) {
        try {
            await this.categoryTree.fetch();

            // category data is available on ready event
            const categoryPath = this.getCategoryPath(data.object.category);

            if (categoryPath) {
                // eslint-disable-next-line
                data.object.category = categoryPath;
            }
            await this.track('engagementEvent', this.decorator(data, this.playerAsset));
        } catch (e) {
            // nothing to do
            logger('Pulse').log('Could not track event');
        }
    }

    getTracker() {
        return new Promise((resolve) => {
            const resolveTracker = () => resolve(this.tracker);

            if (this.tracker === null) {
                this.once('ready', resolveTracker);
            } else {
                resolveTracker();
            }
        });
    }

    setup() {
        // prevent asset to be changed with playnext before stream completes
        this.playerAsset = this.player.getAsset();

        this.categoryTree = CategoryTree(this.playerAsset.getVendor());

        this.adEvents = new AdEvents(this.player);
        this.playbackEvents = new PlaybackEvents(this.player);
        this.uiEvents = new UiEvents(this.player);

        // initialize only once
        Promise.all([
            PulseTracker.init(this.provider),
            this.categoryTree.fetch()
        ]).then((data) => this.onReady(data));

        this.listenTo(this.adEvents, 'all', this.onAdEvent, this);
        this.listenTo(this.playbackEvents, 'all', this.onPlaybackEvent, this);
        this.listenTo(this.uiEvents, 'all', this.onUiEvent, this);
        this.listenTo(this.player, 'adStarted', () => {
            this.listenToOnce(this.player.model.player, 'adMeta', this.onAdMeta);
        });
        this.listenTo(this.adEvents, 'homadAdMeta', this.onHomadAdMeta, this);
        // eslint-disable-next-line no-return-assign
        this.listenTo(this.player, 'playlistItem', () => this.adSequenceDuration = false);
    }

    onReady(data) {
        const [tracker] = data;

        this.tracker = tracker;
        this.trigger('ready');
        this.listenTo(this.adEvents, 'adStarted');
    }

    onHomadAdMeta(adData) {
        if (!this.adSequenceDuration) {
            this.adSequenceDuration = Array(adData.count);
        }

        // skip midroll and postroll
        if (adData.type !== 'preroll') {
            return;
        }

        this.adSequenceDuration[adData.sequence - 1] = adData.duration;
        this.adSequenceCount = this.adSequenceDuration.length;
    }

    onAdMeta(adData) {
        if (!this.adSequenceDuration) {
            this.adSequenceDuration = Array(adData.podcount);
        }

        // skip midroll and postroll
        if (get(adData, 'adschedule.offset') !== 'pre') {
            return;
        }

        const { textContent: duration } = adData.response
            .querySelector(`Ad:nth-of-type(${adData.sequence}) InLine Creatives Linear Duration`) || {};
        if (duration) {
            this.adSequenceDuration[adData.sequence - 1] = utils.time.durationToSeconds(duration);
        }
    }

    onPlaybackEvent(intent, data = {}) {
        const asset = this.playerAsset;
        const { config } = this.player;

        /**
         * Autoplay is not set for load method thus we use value set in player constructor
         * For other events method of playback start is checked
         * Autoplay is set to true when player actually autostarts or when its started with external call (.play())
         */
        const trackingData = {
            intent,
            '@type': 'Engagement',
            position: data.position,
            // duration is in ms
            duration: data.duration * 1000,
            object: Object.assign({
                '@id': getMediaAssetId(this.provider, asset.get('id')),
                '@type': 'MediaAsset',
                name: asset.getTitle(),
                muted: this.jw.getMute(),
                fullscreen: this.jw.getFullscreen(),
                autoplay: data.playMethod === null
                    ? (config.get('autoplay') === true)
                    : (data.playMethod === 'autostart' || data.playMethod === 'external'),
                adSequenceCount: data.count,
                embed: this.embed,
                streamType: asset.get('streamType'),
                adblock: this.isHomadLoaded
            }, this.asset)
        };

        if (this.adSequenceDuration) {
            trackingData.object.adSequenceDuration = this.adSequenceDuration;
            trackingData.object.adSequenceCount = this.adSequenceDuration.length;
        }

        if (data.start === true) {
            trackingData.start = true;
        }

        if (data.previousAssetId) {
            trackingData.object.previousAssetId = getMediaAssetId(this.provider, data.previousAssetId);
            trackingData.object.playbackSource = data.playbackSource;
        } else if (this.previousAssetId) {
            trackingData.object.previousAssetId = getMediaAssetId(this.provider, this.previousAssetId);
            trackingData.object.playbackSource = 'config';
        }

        this.trackMediaEvent(trackingData);
    }

    onAdEvent(intent, data) {
        // do not track homadAdMeta events
        if (intent === 'homadAdMeta') {
            return false;
        }

        const asset = this.playerAsset;
        const trackingData = {
            intent,
            '@type': 'Engagement',
            position: data.position,
            // position and duration are equal because ads does not allow to seek through content
            // duration is in ms
            duration: data.position * 1000,
            object: {
                '@id': `sdrn:${this.provider}:mediaad:${data.id}`,
                '@type': 'MediaAd',
                assetType: capitalizeFirstLetter(data.type),
                muted: this.jw.getMute(),
                fullscreen: this.jw.getFullscreen(),
                mediaAssetId: getMediaAssetId(this.provider, asset.get('id')),
                adSequenceCount: data.count,
                adblock: this.isHomadLoaded
            }
        };

        if (this.adSequenceDuration) {
            trackingData.object.adSequenceDuration = this.adSequenceDuration;
            trackingData.object.adSequenceCount = this.adSequenceDuration.length;
        }

        if (data.sequence) {
            trackingData.object.adSequencePosition = data.sequence;
        }

        if (data.duration) {
            trackingData.object.duration = data.duration;
        }

        if (data.start === true) {
            trackingData.start = true;
        }

        if (asset.getCategory()) {
            trackingData.object.category = this.categoryTree.getPath(asset.getCategory().attributes.id)
                .map((i) => i.title)
                .join(' > ')
                .toLocaleLowerCase();
        }

        return this.trackMediaEvent(trackingData);
    }

    async onUiEvent(eventName, data) {
        const trackingData = {
            '@type': 'View',
            object: Object.assign(data, {
                '@id': getMediaAssetId(this.provider, data.id),
                '@type': 'UIElement'
            })
        };

        this.track('viewEvent', trackingData);
    }

    destroy() {
        this.stopListening();

        this.adEvents.destroy();
        this.playbackEvents.destroy();
    }
}

export default PulseStatsPlugin;
