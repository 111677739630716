import config from 'player/config';

function handleErrors(response) {
    if (!response.ok) {
        return Promise.reject(response);
    }
    return response;
}

const APPNEXUS_PROVIDER_ID = 'appnexus';

const api = (vendor, endpoint) => fetch(config.api.getUrl(vendor, endpoint))
    .then(handleErrors)
    .then((response) => response.json());


export {
    APPNEXUS_PROVIDER_ID
};

export default api;
