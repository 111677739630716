
import assetService from 'player/services/api/asset';
import relatedService from 'player/services/api/related';
import Asset from '../asset';

// eslint-disable-next-line
const mapResponse = (vendor, assets) => assets.map((asset) => {
    // add vendor to assets
    return new Asset(Object.assign({
        vendor
    }, asset));
});

/**
 * Get related engine
 *
 * @param useMlEngine
 * @returns {string}
 */
const getEngine = (useMlEngine) => (useMlEngine === true ? 'related-ml' : 'related');

const fetchByIds = async ({ vendor, ids }) => {
    const assets = await assetService.getByIds({
        vendor,
        ids
    });

    return mapResponse(vendor, assets);
};

const fetchFromRelatedEngine = async({ vendor, assetId, useMlEngine }) => {
    let assets = await relatedService.getList({
        engine: getEngine(useMlEngine),
        vendor,
        assetId
    });

    // fetch from old recommended
    if (useMlEngine === true && assets.length === 0) {
        assets = await relatedService.getList({
            engine: getEngine(false),
            vendor,
            assetId
        });
    }

    return mapResponse(vendor, assets);
};


export default {
    fetchByIds,
    fetchFromRelatedEngine
};
