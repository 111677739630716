import utils from 'utils/utils';
import image from 'utils/image';
import Model from 'utils/model';
import ServerTime from 'utils/server-time';
import config from 'player/config';

const Stream = function (options) {
    this.attributes = {
        id: 0
    };

    this.initialize(options);
};

Stream.prototype = {
    initialize(data) {
        utils.extend(this.attributes, this.parse(data));
    },

    /**
     * Parse data from asset
     * Be aware, data went through Asset.parse method
     *
     * @param data
     */
    parse(data) {
        const result = {
            id: data.id,
            vendor: data.vendor,
            title: data.title,

            streams: {
                hls: data.streamUrls.hls,
                hds: data.streamUrls.hds,
                mp4: data.streamUrls.mp4 ? [data.streamUrls.mp4] : []
            },
            status: data.status,
            type: data.streamType,
            mediaType: data.assetType,
            streamConfiguration: data.streamConfiguration,

            images: {
                main: data.images.main,
                snapshots: data.images.snapshots || null
            },

            captions: data.captions || [],

            flightTimes: {
                start: null,
                end: null
            },

            playback: {
                begin: null,
                end: null
            },

            sourceFiles: data.sourceFiles || [],
            externalId: data.externalId || null,
            category: data.category
        };

        if (data.duration) {
            result.duration = data.duration / 1000;
        }

        if (data.metadata) {
            result.is360 = data.metadata.is360 === 'true';
        }

        // this array contains other mp4 streams but without main mp4
        if (data.streamUrls.pseudostreaming) {
            result.streams.mp4 = result.streams.mp4.concat(data.streamUrls.pseudostreaming);
        }

        if (data.additional && data.additional.metadata) {
            if (data.additional.metadata.audioStream) {
                result.streams.audio = data.additional.metadata.audioStream;
            }
            result.disableNextVideo = data.additional.metadata.disableNextVideo;
            result.chunkDuration = Number(data.additional.metadata.chunkDuration);
        }

        // override start and end if set in api
        if (data.flightTimes) {
            result.flightTimes = {
                start: (data.flightTimes.start ? data.flightTimes.start : null),
                end: (data.flightTimes.end ? data.flightTimes.end : null)
            };
        }

        if (data.playback) {
            result.playback = {
                begin: data.playback.begin,
                end: data.playback.end
            };
        }

        // additional stream settings
        if (data.settings) {
            result.showAds = data.settings.showAds;
        }

        // cue points, empty array if nothing set
        result.cuePoints = data.cuePoints;

        // playlist, empty array if nothing set
        result.playlist = data.playlist || [];

        // add midroll in 10s TODO remove after tests
        // result.cuePoints = [31];
        return result;
    },

    getId() {
        return this.get('id');
    },

    getVendor() {
        return this.get('vendor');
    },

    getCategory() {
        return this.get('category');
    },

    hasProperty(property) {
        return this.get('streamConfiguration').properties.indexOf(property) > -1;
    },

    /**
     * Get duration stored in API
     *
     * @returns {*}
     */
    getDuration() {
        return this.get('duration');
    },

    getCuePoints() {
        return this.get('cuePoints');
    },

    /**
     * Check if stream has Akamai Secure Token
     * @returns {*}
     */
    isSecure() {
        return this.hasProperty('tokenSecured');
    },

    /**
     * Check wheter give stream contains mock data to allow embedding player without asset id
     * @return {boolean}
     */
    isMock() {
        return this.get('type') === 'mock';
    },

    /**
     * Check if stream is available only in certain areas
     * @returns {*}
     */
    isGeoblocked() {
        return this.hasProperty('geoblocked');
    },

    isLive() {
        return this.get('type') === 'live';
    },

    wasLive() {
        return this.get('type') === 'wasLive';
    },

    isActive() {
        return this.get('status') === 'active' && !this.isPast();
    },

    isFuture() {
        return (this.get('flightTimes') && this.get('flightTimes').start
            ? this.get('flightTimes').start > new Date().getTime() : false);
    },

    isPast() {
        return (this.get('flightTimes') && this.get('flightTimes').end
            ? (this.get('flightTimes').end < new Date().getTime()) : false);
    },

    hasHds() {
        return this.getUrl('HDS').indexOf('f4m') > -1;
    },

    hasPlaylist() {
        return this.get('playlist').length > 0;
    },

    isDisabledNextVideo() {
        return !!this.get('disableNextVideo');
    },
    /**
     * Streams might not be available while asset is created
     *
     * @returns {boolean}
     */
    hasPlayableSource() {
        if (this.getTimeToStart() > 0 || !this.isActive()) {
            return false;
        }

        return true;
    },

    getUrl(streamType) {
        const isOnHttp = window.location.protocol.toLowerCase().startsWith('http:');
        const type = streamType.toLowerCase();

        let streamUrl = this.get('streams')[type];

        if (utils.device.isIE() && isOnHttp) {
            streamUrl = streamUrl.replace('https', 'http');
        }

        return streamUrl;
    },

    getPoster() {
        return this.get('images').main;
    },

    /**
     * Get chunk duration of live video
     *
     * @returns {number}
     */
    getChunkDuration() {
        return this.get('chunkDuration');
    },

    /**
     * Get poster src adjusted to given width
     *
     * @param containerWidth
     * @returns {*}
     */
    getPosterSrc(containerWidth) {
        return image.getImageSrc(this.getPoster(), 'poster', containerWidth);
    },

    /**
     * Get snapshots url
     * @returns {*} - url string or null if snapshots not found
     */
    getSnapshots() {
        return `${config.api.thumbnailsUrl + this.getVendor()}/assets/${this.getId()}`;
    },

    /**
     * Not every asset has snapshot (lives, wasLives)
     */
    hasSnapshots() {
        return this.get('images').snapshots !== null;
    },

    /**
     * Get captions associated with stream
     *
     * @returns {boolean}
     */
    getCaptions() {
        return this.get('captions') || [];
    },

    /**
     * Check if mp4 streams are available
     *
     * @returns {boolean}
     */
    hasMp4Streams() {
        return this.get('streams').mp4.length > 0;
    },

    hasAudioOnlyStream() {
        return typeof this.get('streams').audio === 'string';
    },

    getMp4Streams() {
        const streams = [];

        // mp4 streams are not available on live and secure hd
        if (this.hasMp4Streams()) {
            utils.each(this.get('streams').mp4, (stream) => {
                const streamData = stream.split('/').pop().replace('.mp4', '').split('_');

                streams.push({
                    source: stream,
                    width: streamData[0],
                    height: streamData[1],
                    bitrate: streamData[2]
                });
            });
        }

        return streams;
    },

    /**
     * Check if asset is published
     * Method relay on server time to avoid timezone differences
     *
     * @returns {number} seconds to live
     */
    getTimeToStart() {
        return (this.get('flightTimes').start - ServerTime.getTime()) / 1000;
    },


    /**
     * Asset can be virually sliced into small chunks
     * These are only indicators and do not slice stream
     *
     * @param position
     * @returns {*}
     */
    getPlaybackTime(position) {
        const playback = this.get('playback');

        if (position === 'begin') {
            if (this.get('playAhead') > 0) {
                return this.get('playAhead');
            }

            return (playback.begin > 0) ? playback.begin : null;
        }

        if (position === 'end' && playback.end > 0) {
            return playback.end;
        }

        return null;
    },

    /**
     * Check if ads are turned on
     *
     * @returns {boolean}
     */
    hasCreatives() {
        return this.get('na') !== true;
    },

    /**
     * External id
     * Used for JW Player statistics
     */
    getExternalId() {
        return this.get('externalId');
    },

    /**
     * Stream display mode
     *
     * @returns {*}
     */
    getDisplayType() {
        if (this.is360()) {
            return '360';
        }

        return '2d';
    },

    /**
     * Check if stream is 360 video
     */
    is360() {
        return this.get('is360') === true;
    },

    /**
     * Check wethever stream has ads disabled
     */
    hasAdsDisabled() {
        return this.get('showAds') === false;
    },

    /**
     * @returns {boolean}
     */
    isAudio() {
        return this.get('mediaType') === 'audio';
    }
};

utils.extend(Stream.prototype, Model);

export default Stream;
