import styleLoader from 'utils/style-loader';

import audioSkinResolver from './skin/audioSkinResolver';
import loadIcons, { loadDefs, getIcon } from './skin/icons-loader';
import settingsMenu from './skin/settings-menu';
import configButton from './skin/config-button';
import sharingBox from './skin/sharing-box';
import fullscreenButton from './skin/fullscreen-button';

import containerClass from './skin/container-class';
import levelsIcon from './skin/levels-icon';
import nextUp from './skin/nextUp';

/**
 * @param {object} jwConfig
 * @param {function} loader
 * @return {object}
 */
export function skinLoader(jwConfig, loader = styleLoader) {
    // disable multiple loading of JW skin
    if (jwConfig.skin && jwConfig.skin.url) {
        loader(jwConfig.skin.url);

        if (jwConfig.skin.name) {
            jwConfig.skin = {
                name: jwConfig.skin.name
            };
        } else {
            delete jwConfig.skin;
        }
    }

    return jwConfig;
}

/**
 * Load SVP Skin
 *
 * @param {PlayerModel} svpPlayerModel
 */
export default function svpSkin(svpPlayerModel) {
    loadDefs(svpPlayerModel);

    audioSkinResolver(svpPlayerModel);
    settingsMenu(svpPlayerModel);
    configButton(svpPlayerModel, {
        icon: getIcon('info')
    });
    loadIcons(svpPlayerModel);
    sharingBox(svpPlayerModel);
    fullscreenButton(svpPlayerModel);
    containerClass(svpPlayerModel);
    levelsIcon(svpPlayerModel);
    nextUp(svpPlayerModel);
}
