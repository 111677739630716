const EXCLUDED_ASSETS_KEY = 'svp-excludedAssets';

/**
 * Each vendor has own storage for excluded keys
 *
 * @param vendor
 * @returns {string}
 */
const getKey = function(vendor) {
    return `${EXCLUDED_ASSETS_KEY}-${vendor}`;
};

const ExcludedAssets = {
    add(vendor, id) {
        let assets = this.getAll(vendor);

        if (id) {
            if (assets.indexOf(id) === -1) {
                // put at top as the older stream will be removed first
                assets.unshift(id);
                // limit only to 10 restricted streams
                assets = assets.splice(0, 10);
            }

            this.getStorage().setItem(getKey(vendor), JSON.stringify(assets));
        }
    },

    getAll(vendor) {
        return JSON.parse(this.getStorage().getItem(getKey(vendor))) || [];
    },

    getStorage() {
        return window.localStorage;
    }
};

export default ExcludedAssets;
