/* jshint bitwise: false */
import utils from 'utils/utils';
import Events from 'utils/backbone.events';

function binarySearch(arr, compare) {
    // binary search, with custom compare function
    let l = 0;

    let r = arr.length - 1;
    let m;
    let comp;

    while (l <= r) {
        // eslint-disable-next-line no-bitwise
        m = l + ((r - l) >> 1);
        comp = compare(arr[m]);

        if (comp < 0) {
            // arr[m] comes before the element
            l = m + 1;
        } else if (comp > 0) {
            // arr[m] comes after the element
            r = m - 1;
        } else {
            return m;
        }
    }

    return l - 1; // return the index of the next left item
    // usually you would just return -1 in case nothing is found
}

const Viewport = function() {
    this.current = null;
};

Viewport.prototype = {
    labels: [
        'tiny', 'tiny-small', 'tiny-small-medium',
        'tiny-small-medium-big', 'tiny-small-medium-big-web', 'tiny-small-medium-big-web-wide'
    ],
    sizes: [0, 480, 640, 768, 1024, 1200],

    /**
     * Update player viewport which is not equal to browser wiewport
     *
     * @param width
     */
    update(width) {
        const newIndex = binarySearch(this.sizes, (x) => x - width);

        if (this.current !== newIndex) {
            const newBreakpoint = this.getViewportByIndex(newIndex);
            const currentBreakpoint = this.getViewportByIndex(this.current);

            this.trigger('change', newBreakpoint, currentBreakpoint);

            this.current = newIndex;
        }
    },

    getViewportByIndex(index) {
        return {
            width: this.sizes[index],
            label: this.labels[index]
        };
    }
};

utils.extend(Viewport.prototype, Events);

export default Viewport;
