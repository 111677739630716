import utils from 'utils/utils';
import image from 'utils/image';
import Recommended from 'player/model/recommended';
import closeIcon from 'icons/close.svg';
import nextIcon from 'icons/chevron-right.svg';
import recommendedTemplate from './recommended/recommended.html';
import nextAssetTemplate from './recommended/next-asset.html';
import gridTemplate from './recommended/grid.html';
import PluginModel from './model';

/**
 * Play next asset from the list
 *
 * @param {number} assetId - SVP Asset id
 * @param {string} source - source of playnext (auto - when autoplay next, single, grid)
 */
function playNext(assetId, source) {
    const clear = () => {
        utils.removeClass(this.el, ['is-grid-visible', 'is-next-visible', 'is-counting', 'is-next-closing']);
    };

    this.stopListening(this.player, 'complete');

    // autoplay of asset can be turned off from config
    if (this.settings.autoplay !== false) {
        this.player.playNext(assetId);
    }

    // nextAsset case, triggered by autoplay or user
    if (source === 'single' || source === 'auto') {
        this.trigger('nextAssetPlay', this.nextAsset, (source === 'auto') ? 'auto' : 'click', {
            source: this.nextAssetSource
        });

        utils.addClass(this.el, 'is-next-closing');
        setTimeout(clear, 700);
    } else {
        // grid click
        this.trigger('playRecommended', assetId);
        clear();
    }
}

/**
 * Turn off autoplay of recommended next asset
 */
function disableNext() {
    this.trigger('nextAssetClose', this.nextAsset);
    this.nextAsset = null;
    utils.removeClass(this.el, 'is-next-visible is-counting is-next-visible');
}

/**
 * Retrieve full url for play next and grid assets thumbnails
 *
 * @param {string} key
 * @returns {string|null}
 */
function getThumbnailUrl(key) {
    return image.getImageSrc(key, 'poster', 200);
}

/**
 * Add snap scroll for mobile grid
 */
function initSnapScroll() {
    const scrollSnapPrefix = utils.getScrollSnapPrefix();
    const gridItem = this.getByClass('svp-player-recommended-item')[0];

    // check if snap points are supported
    if (scrollSnapPrefix !== false) {
        this.el.style[`${scrollSnapPrefix}scroll-snap-type`] = 'mandatory';
        this.el.style[`${scrollSnapPrefix}scroll-snap-points-x`] = `repeat(${gridItem.clientWidth}px)`;
    }
}

/**
 * Click handler for recommended grid
 *
 * @param {HTMLElement} node
 */
function onGridItemClicked(node) {
    utils.addClass(node, 'svp-to-active');

    this.listenToOnce(this.player, 'assetPlay', function() {
        utils.removeClass(this.player.getContainer(), 'is-recommended-grid-visible');
    }, this);

    setTimeout(() => {
        playNext.call(this, parseInt(node.dataset.id, 10), 'grid');
        utils.removeClass(this.el, 'is-grid-visible');
    }, 500);
}

/**
 * Fetch recommended assets when player is ready
 */
function onPlaybackStart() {
    const recommended = new Recommended({
        asset: this.player.getAsset(),
        ml: this.settings.ml
    });

    // restore default state for each new asset
    this.gridItemsReady = false;

    // turn off option for grid
    if (this.settings.grid !== false) {
        recommended.getItems(this.settings.grid.items).then((items) => {
            if (utils.isArray(items) && items.length > 0) {
                this.gridItemsReady = true;
                this.trigger('gridItemsReady', items);
            } else {
                utils.removeClass(this.player.getContainer(), 'is-recommended-grid-visible');
            }
        }).catch(() => {
            try {
                utils.removeClass(this.player.getContainer(), 'is-recommended-grid-visible');
            } catch (e) {
                // player container not available
            }
        });
    }

    // next asset is available by default but can be turned off
    if (this.settings.next !== false) {
        recommended.getNextAsset(this.settings.next).then(({ nextAsset, source }) => {
            this.nextAsset = nextAsset;
            this.nextAssetSource = source;

            this.trigger('nextAssetReady', this.nextAsset, {
                source
            });
        }).catch(() => {
            if (this.el) {
                utils.removeClass(this.el, ['is-next-visible', 'is-counting', 'is-next-closing']);
            }
        });
    }
}

/**
 * Initialize lazy fetching
 */
function onInitialize() {
    // fetch recommended 15s before playback end
    let fetchDuration = this.player.getDuration() - 15;

    // for streams shorter than 15s load next in 2s
    if (fetchDuration < 0) {
        fetchDuration = 2;
    }
    const stream = this.player.model.getStream();
    const isLive = stream.isLive();
    const isDisabledNextVideo = stream.isDisabledNextVideo();
    const hasPlaylist = stream.hasPlaylist();
    const isAssetFromPlaylist = this.player.model.isAssetFromPlaylist(stream.getId());

    // do not enable recommended for live streams and asset with metadata.disableNextVideo set to true
    if (!isLive && !isDisabledNextVideo && !hasPlaylist && !isAssetFromPlaylist) {
        this.listenTo(this.player, 'time', function (time) {
            if (fetchDuration > 0 && time > fetchDuration) {
                fetchDuration = -1;
                onPlaybackStart.call(this);
            }
        }, this);
    }
}

/**
 * Handler for stream end (countdown end) event
 * Stream complete can't be used due to circular events triggering
 * @param {string} reason
 */
function onPlaybackComplete(reason) {
    const stream = this.player.model.getStream();
    const isAssetFromPlaylist = this.player.model.isAssetFromPlaylist(stream.getId());

    // do not take any action if complete is triggered by playNext
    if (
        stream.isAudio()
        || stream.hasPlaylist()
        || isAssetFromPlaylist
        || reason === 'manualNext'
        || reason === 'stop'
    ) {
        return;
    }

    if (this.nextAsset) {
        this.trigger('nextAssetComplete', this.nextAsset);
        playNext.call(this, this.nextAsset.get('id'), 'auto');
    } else if (this.gridItemsReady) {
        // display grid only when items are available
        utils.addClass(this.el, 'is-grid-visible');
        utils.addClass(this.player.getContainer(), 'is-recommended-grid-visible');

        initSnapScroll.call(this);
        this.trigger('gridVisible');
    }
}

/**
 * Handler for checking time in which playNext should be available
 * @param {number} time
 * @param {number} duration
 */
function onTimeNextVisible(time, duration) {
    if (duration - time < this.countdownTime) {
        this.trigger('playNextVisible', Math.floor(duration - time));
    }
}

/**
 * Display play next in given time
 *
 * @param {number} timeout - duration of countdown
 */
function onPlayNextVisible(timeout) {
    utils.addClass(this.el, 'is-next-visible is-counting');

    this.stopListening(this.player, 'time', onTimeNextVisible);
    // update transition duration as it may be smaller than default due to seek
    this.getByClass('svp-player-recommended-countdown')[0].style.transitionDuration = `${timeout}s`;
}

/**
 * Handler triggered when nextAsset is available for current stream
 */
function onNextAssetReady() {
    const img = new Image();
    // prefetch image
    img.src = getThumbnailUrl(this.nextAsset.getThumbnail());

    this.listenTo(this.player, 'time', onTimeNextVisible, this);

    this.getByClass('svp-player-recommended-next')[0].innerHTML = utils.template(nextAssetTemplate, {
        next: this.nextAsset,
        countdown: this.countdownTime, // default countdown
        getThumbnail: getThumbnailUrl,
        closeIcon: closeIcon,
        nextIcon: nextIcon
    });

    this.getByClass('svp-player-recommended-image')[0]
        .addEventListener('click', playNext.bind(this, this.nextAsset.get('id'), 'single'));

    this.getByClass('svp-player-recommended-close')[0]
        .addEventListener('click', disableNext.bind(this));
}

/**
 * Triggered when grid items are available
 * @param {Asset[]} recommended
 */
function onGridItemsReady(recommended) {
    this.getByClass('svp-player-recommended-grid')[0].innerHTML = utils.template(gridTemplate, {
        recommended: recommended,
        getThumbnail: getThumbnailUrl,
        utils: utils
    });

    utils.each(this.getByClass('svp-player-recommended-item'), (item) => {
        item.addEventListener('click', onGridItemClicked.bind(this, item));
    });
}

/**
 * Triggered when recommended grid is displayed
 */
function onGridVisible() {
    // replay asset triggers play
    // clicking on grid not intersects with this
    this.listenToOnce(this.player, 'play', this.trigger.bind(this, 'assetReplay'));
}

/**
 * Event fired when user decided to restart video
 */
function onAssetReplay() {
    // attach events to allow again display of grid
    this.setup();
    // cleanup state
    utils.removeClass(this.player.getContainer(), 'is-recommended-grid-visible');
    utils.removeClass(this.el, ['is-grid-visible', 'is-next-visible', 'is-counting', 'is-next-closing']);
}


/**
 * Renders countdown clock for streams which are not published yet
 *
 * @param options
 * @constructor
 */
class RecommendedPlugin extends PluginModel {
    constructor(options) {
        super(options);

        // time in seconds from end of stream
        this.countdownTime = (options.settings && options.settings.next && options.settings.next.countdown) || 10;
        this.nextAsset = null;
        this.settings = utils.extend({
            grid: {
                items: []
            },
            next: {}
        }, options.settings);

        // check if grid items are available
        this.gridItemsReady = false;
    }

    // eslint-disable-next-line
    getName() {
        return 'RecommendedPlugin';
    }

    setup() {
        // assetPlay
        this.stopListening(this.player);
        this.listenToOnce(this.player, 'assetPlay', this.render, this);
        this.listenToOnce(this.player, 'assetPlay', onInitialize, this);
        this.listenToOnce(this.player, 'complete', onPlaybackComplete, this);


        this.once('playNextVisible', onPlayNextVisible, this);
        this.once('nextAssetReady', onNextAssetReady, this);
        this.once('gridItemsReady', onGridItemsReady, this);
        this.once('gridVisible', onGridVisible, this);
        this.once('assetReplay', onAssetReplay, this);
    }

    /**
     * Get current element child node by class name
     *
     * @param className
     * @returns {NodeList|*}
     */
    getByClass(className) {
        return this.el.getElementsByClassName(className);
    }

    /**
     * Render default view holder for play next and recommended
     *
     */
    render() {
        const container = this.player.getContainer();
        const previousEl = container.getElementsByClassName('svp-player-recommended-box');

        // clean if previously grid was displayed
        utils.removeClass(this.player.getContainer(), 'is-recommended-grid-visible');

        // cleanup old element
        // recommended is "async" so it can't be destroyed like normal plugin
        if (previousEl.length > 0) {
            container.removeChild(previousEl[0]);
        }

        this.el = utils.createNode(utils.template(recommendedTemplate));

        container.appendChild(this.el);
    }
}

export default RecommendedPlugin;
