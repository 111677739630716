const getPlaybackState = (container) => {
    const playerClassList = container.classList;
    const adStarted = ['jw-flag-ads', 'svp-homad-playing'];

    const useClasses = (add, remove) => {
        playerClassList.add(...add);
        playerClassList.remove(...remove);
    };

    return {
        setAdPlaying: () => useClasses(adStarted, ['svp-homad-loading']),
        setAdComplete: () => useClasses([], adStarted)
    };
};

const getControlsContainer = (adPlayerContainer) => {
    const controlsContainer = adPlayerContainer.querySelector('.jw-icon').parentNode.parentNode;

    const appendControl = (domNode) => {
        controlsContainer.appendChild(domNode);
    };

    return {
        appendControl
    };
};

const createProgressBar = (container) => {
    const seekBar = container.querySelector('.jw-controlbar').cloneNode(true);
    seekBar.querySelector('.jw-button-container').remove();
    seekBar.querySelector('.jw-progress').style.width = '0%';

    const progressLoader = seekBar.querySelector('.jw-progress');

    const setProgress = (value) => {
        progressLoader.style.width = `${value}%`;
    };

    return {
        domNode: seekBar,
        reset: () => setProgress(0),
        setProgress
    };
};

const createFullscreenButton = (container) => {
    const fullscreenButton = container.querySelector('.jw-icon-fullscreen').cloneNode(true);
    const onClick = (handleClick) => {
        fullscreenButton.addEventListener('click', handleClick, true);
    };

    return {
        domNode: fullscreenButton,
        onClick
    };
};

const toggleFullscreen = (jwPlayer) => {
    try {
        jwPlayer.setFullscreen();
    } catch (e) {
        // fullscreen did not work
    }
};

const adjustVolumePosition = (adPlayerContainer) => {
    const volumeBtn = adPlayerContainer.querySelector('.jw-icon-volume');

    if (volumeBtn) {
        volumeBtn.parentNode.style.float = 'left';
    }
};

const createHomadSkin = (player, adPlayer, adPlayerContainer) => {
    const container = player.getContainer();
    const { appendControl } = getControlsContainer(adPlayerContainer);

    const fullScreenButton = createFullscreenButton(container);
    fullScreenButton.onClick(() => toggleFullscreen(player.player));
    appendControl(fullScreenButton.domNode);

    const seekBar = createProgressBar(container);
    adPlayer.addEventListener(
        'timeupdate',
        () => seekBar.setProgress((adPlayer.currentTime / adPlayer.duration) * 100),
        true
    );
    appendControl(seekBar.domNode);

    const { setAdPlaying, setAdComplete } = getPlaybackState(container);
    player.once('adSlotStart', seekBar.reset);
    player.once('adSlotStart', setAdPlaying);
    player.once('adSlotComplete', setAdComplete);

    adjustVolumePosition(adPlayerContainer);
};

export {
    createHomadSkin
};
