/* jshint ignore:start */

    var idCounter = 0;

    // we do not include underscore as it is our hard dependency
    var _ = jwplayer._,
        property = function (key) {
            return function (obj) {
                return obj == null ? void 0 : obj[key];
            };
        },
        MAX_ARRAY_INDEX = Math.pow(2, 53) - 1,
        getLength = property('length'),
        isArrayLike = function (collection) {
            var length = getLength(collection);
            return typeof length == 'number' && length >= 0 && length <= MAX_ARRAY_INDEX;
        };

    _.isEmpty = function (obj) {
        if (obj == null) return true;
        if (isArrayLike(obj) && (_.isArray(obj) || _.isString(obj) || _.isArguments(obj))) return obj.length === 0;
        return _.keys(obj).length === 0;
    };

    _.uniqueId = function(prefix) {
        var id = ++idCounter + '';
        return prefix ? prefix + id : id;
    };

    export default _;

/* jshint ignore:end */
