/* eslint-disable max-len */
import codeToString, * as STATUS_CODES from '../playback/config/status-codes';

// translation configuration for different locales
const translations = {
    en: {
        // Countdown plugin
        'Video will start at': 'Video will start at',
        'Video will start in': 'Video will start in',

        'ageLimit-0': 'A',

        // SponsorOverlay plugin
        'Ad from': 'Ad from',

        // months  is special case for translating month to human readable form
        months: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ],
        // general troubles, we don't know exactly what had happen
        [codeToString(STATUS_CODES.GENERAL_ERROR)]: 'Sorry. An error occured. Try again later.',
        // stream is available only in certain areas/countries
        [codeToString(STATUS_CODES.NOT_ACTIVE_GEOBLOCKED)]: 'The video failed to play due to geoblock restrictions.',
        // stream is 404 (wrong/deleted from akamai)
        [codeToString(STATUS_CODES.NOT_FOUND)]: 'Sorry. This video is unavailable.',
        // asset is not active
        [codeToString(STATUS_CODES.NOT_ACTIVE)]: 'Sorry. This video is not active.',
        // asset's flight times are in the past
        [codeToString(STATUS_CODES.NOT_ACTIVE_PAST)]: 'Sorry. This video is unavailable.',
        // network error while playing live stream
        [codeToString(STATUS_CODES.NETWORK_ERROR)]: 'Network issues. Please check your internet connection',
        // token can not be retrieved
        [codeToString(STATUS_CODES.TOKEN_FETCH_ERROR)]: 'Sorry. An error occured. Try again later.',

        // sharing box heading
        Sharing: 'Share this video',
        'Live button': 'Live',

        // error message
        'Error code': 'Error code',

        // closed captions
        en_GB: 'English',
        es_ES: 'Español',
        nb_NO: 'Bokmål',
        sv_SE: 'Swedish',

        'Audio video switch': 'Audio video switch',
        'Playing audio only': 'Playing audio only',

        ads: {
            // jw ad messages
            admessage: 'The ad will end in xx seconds',
            cuetext: 'Advertisement',
            skipmessage: 'Skip ad in xx',
            skiptext: 'Skip',
            podmessage: 'Ad __AD_POD_CURRENT__ of __AD_POD_LENGTH__'
        },

        adblock: {
            admessage: 'The ad will end in [time]s'
        },
        player: {
            listen: 'Listen',
            playback: 'Start playback',
            loginToListen: 'Sign in to listen',
            subscribeToListen: 'Subscribe to listen'
        }
    },
    no: {
        // Countdown plugin
        'Video will start at': 'Videoen starter',
        'Video will start in': 'Videoen starter om',

        'ageLimit-0': 'A',

        // SponsorOverlay plugin
        'Ad from': 'Annonse fra',

        // months  is special case for translating month to human readable form
        months: [
            'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun',
            'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'
        ],
        // general troubles, we don't know exactly what had happen
        [codeToString(STATUS_CODES.GENERAL_ERROR)]: 'Beklager, en feil oppstod. Prøv å spill av videoen senere',
        // stream is available only in certain areas/countries
        [codeToString(STATUS_CODES.NOT_ACTIVE_GEOBLOCKED)]: 'Denne videoen er kun tilgjengelig i Norge',
        // stream is 404 (wrong/deleted from akamai)
        [codeToString(STATUS_CODES.NOT_FOUND)]: 'Beklager, videoen er ikke tilgjengelig',
        // asset is not active
        [codeToString(STATUS_CODES.NOT_ACTIVE)]: 'Beklager, videoen er ikke tilgjengelig',
        // asset's flight times are in the past
        [codeToString(STATUS_CODES.NOT_ACTIVE_PAST)]: 'Beklager, vi har ikke rettigheter til å vise videoen lenger',
        // network error while playing live stream
        [codeToString(STATUS_CODES.NETWORK_ERROR)]: 'Kunne ikke spille av video, venligst sjekk internettforbindelsen din',
        // token can not be retrieved
        [codeToString(STATUS_CODES.TOKEN_FETCH_ERROR)]: 'Beklager, en feil oppstod. Prøv å spill av videoen senere',

        // jw player error messages
        'This video cannot be played because of a technical error.': 'Kunne ikke spille av video på grunn av en teknisk feil',
        'Sorry, the video player failed to load.': 'Kunne ikke spille av video, venligst sjekk<br /> internettforbindelsen din',
        'This video file cannot be played.': 'Kunne ikke spille av video',
        'The live stream is either down or has ended.': 'Livesendingen er nede eller er avsluttet',
        'There was a problem providing access to protected content.': 'Kunne ikke spille av video, venligst sjekk<br /> internettforbindelsen din',
        'This video cannot be played because of a problem with your internet connection.': 'Kunne ikke spille av video, venligst sjekk<br /> internettforbindelsen din',

        // sharing box heading
        Sharing: 'Del denne videoen',
        'Live button': 'Direkte',

        // error message
        'Error code': 'Feilkode',

        // closed captions
        en_GB: 'English',
        es_ES: 'Español',
        nb_NO: 'Bokmål',
        sv_SE: 'Svensk',

        // av button
        'Audio video switch': 'Kun lyd',
        'Playing audio only': 'Spiller kun lyd',

        ads: {
            admessage: 'Annonsen slutter om xx',
            cuetext: 'Annonse',
            skipmessage: 'Hopp over om xx',
            skiptext: 'Hopp over',
            podmessage: 'Annonse  __AD_POD_CURRENT__ av __AD_POD_LENGTH__'
        },

        adblock: {
            admessage: 'Annonsen slutter om [time]'
        },
        player: {
            player: 'Videospiller',
            play: 'Spill',
            playback: 'Start avspilling',
            pause: 'Pause',
            volume: 'Volum',
            prev: 'Forrige',
            next: 'Neste',
            cast: 'Chromecast',
            airplay: 'AirPlay',
            fullscreen: 'Fullskjerm',
            playlist: 'Spilleliste',
            hd: 'Kvalitet',
            cc: 'Undertekster',
            audioTracks: 'Lydspor',
            playbackRates: 'Avspillingshastighet',
            replay: 'Spill igjen',
            buffer: 'Laster inn',
            more: 'Mer',
            liveBroadcast: 'Live',
            loadingAd: 'Laster annonse',
            rewind: 'Spol tilbake 10 sekunder',
            nextUp: "Neste' video",
            nextUpClose: 'Lukk neste',
            related: 'Relaterte videoer',
            close: 'Lukk',
            settings: ' Innstillinger',
            unmute: 'Slå på lyd',
            copied: 'Kopiert',
            forward: 'Spol fram 30 sekunder',
            loginToListen: 'Logg inn for å lytte',
            subscribeToListen: 'Abonner for å lytte',
            listen: 'Lytt'
        }
    },

    se: {
        // Countdown plugin
        'Video will start at': 'Videon är tillgänglig',
        'Video will start in': 'Videon är tillgänglig om',

        'ageLimit-0': 'A',

        // SponsorOverlay plugin
        'Ad from': 'Annons från',

        // months  is special case for translating month to human readable form
        months: [
            'Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun',
            'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'
        ],
        // general troubles, we don't know exactly what had happen
        [codeToString(STATUS_CODES.GENERAL_ERROR)]: 'Något gick fel. Försök igen senare.',
        // stream is available only in certain areas/countries
        [codeToString(STATUS_CODES.NOT_ACTIVE_GEOBLOCKED)]: 'Vi har tyvärr endast rättigheter att spela detta innehåll i Sverige',
        // stream is 404 (wrong/deleted from akamai)
        [codeToString(STATUS_CODES.NOT_FOUND)]: 'Videon är inte tillgänglig',
        // asset is not active
        [codeToString(STATUS_CODES.NOT_ACTIVE)]: 'Videon är inte tillgänglig',
        // asset's flight times are in the past
        [codeToString(STATUS_CODES.NOT_ACTIVE_PAST)]: 'Videon är inte tillgänglig',
        // network error while playing live stream
        [codeToString(STATUS_CODES.NETWORK_ERROR)]: 'Något gick fel. Kolla gärna din internet koppling och försök lite senare',
        // token can not be retrieved
        [codeToString(STATUS_CODES.TOKEN_FETCH_ERROR)]: 'Token kunde inte hämtas',

        // jw player error messages
        'This video cannot be played because of a technical error.': 'Den här videon kan inte spelas på grund av ett tekniskt fel',
        'Sorry, the video player failed to load.': 'Något gick fel. Försök igen senare',
        'This video file cannot be played.': 'Det gick inte att spela videon',
        'The live stream is either down or has ended.': 'Live överföring är antingen nere eller har slutat',
        'There was a problem providing access to protected content.': 'Något gick fel. Kolla gärna din internet koppling och försök lite senare',
        'This video cannot be played because of a problem with your internet connection.': 'Något gick fel. Kolla gärna din internet koppling och försök lite senare',

        // sharing box heading
        Sharing: 'Dela',
        'Live button': 'Live',

        // error message
        'Error code': 'Felkod',

        // closed captions
        en_GB: 'English',
        es_ES: 'Español',
        nb_NO: 'Bokmål',
        sv_SE: 'Sverige',

        // av button
        'Audio video switch': 'Endas ljud',
        'Playing audio only': 'Spelar bara ljud',

        ads: {
            admessage: 'Annonsen tar slut om xx sekunder',
            cuetext: 'Annons',
            skipmessage: 'Skippa annons om xx',
            skiptext: 'Skippa',
            podmessage: 'Annons __AD_POD_CURRENT__ av __AD_POD_LENGTH__'
        },

        adblock: {
            admessage: 'Annonsen tar slut om [time] sekunder'
        },

        player: {
            player: 'Videospelare',
            play: 'Spela',
            playback: 'Spela video',
            pause: 'Pausa',
            volume: 'Volym',
            prev: 'Förra',
            next: 'Nästa',
            cast: 'Chromecast',
            airplay: 'AirPlay',
            fullscreen: 'Fullskärm',
            playlist: 'Spellista',
            hd: 'Kvalitet',
            cc: 'Undertexter',
            audioTracks: 'Ljudspår',
            playbackRates: 'Uppspelningshastighet',
            replay: 'Spela igen',
            buffer: 'Laddar',
            more: 'Mer',
            liveBroadcast: 'Live',
            loadingAd: 'Laddar annons',
            rewind: 'Spola tillbaka 10 sekunder',
            nextUp: 'Nästa',
            nextUpClose: 'Stäng',
            related: 'Fler videos',
            close: 'Stäng',
            settings: 'Inställningar',
            unmute: 'Slå på ljud',
            copied: 'Kopierad',
            forward: 'Spola framåt 30 sekunder',
            loginToListen: 'Logga in för att lyssna',
            subscribeToListen: 'Prenumerera för att lyssna',
            listen: 'Lyssna'
        }
    }
};

/**
 * Get single locale translations hash map
 */
export default function (locale) {
    return translations[locale];
}
