import moduleLoader from './module-loader';

const objectToString = (obj) => {
    // create an array that will later be joined into a string.
    const string = [];

    // is object
    //    Both arrays and objects seem to return "object"
    //    when typeof(obj) is applied to them. So instead
    //    I am checking to see if they have the property
    //    join, which normal objects don't have but
    //    arrays do.
    if (obj === undefined) {
        return String(obj);
    } if (typeof (obj) === 'object' && obj !== null && (obj.join === undefined)) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) string.push(`${prop}: ${objectToString(obj[prop])}`);
        }

        return `{${string.join(',')}}`;

        // is array
    } if (typeof (obj) === 'object' && obj !== null && !(obj.join === undefined)) {
        for (const prop in obj) {
            string.push(objectToString(obj[prop]));
        }
        return `[${string.join(',')}]`;

        // is function
    } if (typeof (obj) === 'function') {
        string.push(obj.toString());

        // all other values can be done with JSON.stringify
    } else {
        string.push(JSON.stringify(obj));
    }

    return string.join(',');
};

const capitalizeFirstLetter = (string) => {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (e) {
        // return what was sent
        return string;
    }
};

const prettyObject = () => moduleLoader('https://cdn.stream.schibsted.media/tools/beautify.min.js')
    .then((module) => {
        if (module && typeof module.js_beautify === 'function') {
            return module.js_beautify;
        }
        return window.js_beautify;
    });

export {
    objectToString,
    prettyObject,
    capitalizeFirstLetter
};
