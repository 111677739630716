import utils from 'utils/utils';
import * as icons from 'icons';
import * as audioIcons from 'icons/audio';

/** @type {object<string, SVGElement>} */
const iconsMap = {
    '.jw-svg-icon-play': icons.play,
    '.jw-svg-icon-pause': icons.pause,
    '.jw-skip-icon .jw-svg-icon-next': icons.chevronRight,
    '.jw-svg-icon-settings': icons.settings,
    '.jw-svg-icon-sharing': icons.share,

    '.jw-svg-icon-cc-on': icons.captions,
    '.jw-svg-icon-cc-off': icons.captions,

    '.jw-svg-icon-volume-0': icons.audioMuted,
    '.jw-svg-icon-volume-50': icons.audioHalf,
    '.jw-svg-icon-volume-100': icons.audio,

    '.jw-svg-icon-fullscreen-on': icons.fullscreenOn,
    '.jw-svg-icon-fullscreen-off': icons.fullscreenOff,

    info: icons.info,
    avButton: icons.avButton
};

/** @type {object<string, SVGElement>} */
const audioIconsMap = Object.freeze({
    '.jw-svg-icon-play': audioIcons.playIcon,
    '.jw-svg-icon-pause': audioIcons.pauseIcon,
    '.jw-svg-icon-replay': audioIcons.replayIcon,
    '.jw-svg-icon-rewind': audioIcons.rewind10Icon,

    '.jw-svg-icon-volume-0': audioIcons.audioOffIcon,
    '.jw-svg-icon-volume-50': audioIcons.audioHalfIcon,
    '.jw-svg-icon-volume-100': audioIcons.audioOnIcon
});

/** @type {boolean} */
let defsLoaded = false;

/**
 * @param {PlayerModel} svpPlayer
 */
export function loadDefs(svpPlayer) {
    if (defsLoaded) {
        return;
    }

    defsLoaded = true;

    svpPlayer.once('ready', () => {
        const container = svpPlayer.getContainer();

        if (container) {
            container.appendChild(utils.createNode(icons.defs));
        }
    });
}

/**
 * @param {string} name
 */
export function getIcon(name) {
    return iconsMap[name];
}

/**
 * @param withAudioIcons
 * @return {object<string, SVGElement>}
 */
function getReplaceableIconsMap(withAudioIcons) {
    if (!withAudioIcons) {
        return iconsMap;
    }

    return utils.merge(iconsMap, audioIconsMap);
}

/**
 * @param {PlayerModel} svpPlayer
 */
export default function loadIcons(svpPlayer) {
    const jwPlayer = svpPlayer.player;
    const stream = svpPlayer.getStream();
    const isAudioStream = stream ? stream.isAudio() : false;
    const replaceableIconsMap = getReplaceableIconsMap(isAudioStream);

    jwPlayer.once('playlist', () => {
        const container = svpPlayer.getContainer();

        utils.each(replaceableIconsMap, (customIcon, selector) => {
            const replaceableIcons = Array.prototype.slice.call(container.querySelectorAll(selector));

            if (!replaceableIcons.length) {
                return;
            }

            utils.each(replaceableIcons, (replaceableIcon) => {
                if (!replaceableIcon) {
                    return;
                }

                const svpIcon = utils.createNode(customIcon);
                svpIcon.setAttribute('class', replaceableIcon.getAttribute('class'));
                replaceableIcon.parentNode.replaceChild(svpIcon, replaceableIcon);
            });
        });
    });
}
