/* global __BUILD_VERSION__ */
import translations from './config/translations';
import jwKeys from './config/jw-keys';

const VERSION = __BUILD_VERSION__;

const config = {
    version: VERSION,
    player: {
        keys: jwKeys
    },
    /**
       * SVP API configuration
       */
    api: {
        url: '//svp.vg.no/svp/api/v1/',
        tokenUrl: '//svp.vg.no/svp/token/v1/',
        thumbnailsUrl: '//svp.vg.no/svp/thumbnails/v1/',

        vendor: null,
        /**
           * API identifier
           */
        identifier: 'svp-player',

        /**
           * Get full endpoint url to requested resource
           *
           * @param vendor
           * @param endpoint
           * @returns {string}
           */
        getUrl(vendor, endpoint) {
            const joiner = (endpoint.indexOf('?') > -1) ? '&' : '?';
            let url = `${this.url + vendor + endpoint + joiner}appName=${this.identifier}`;

            // enable cachebreaking in preview mode
            if (this.cb === true) {
                url += `&cb=${Math.random() * 10000}`;
            }

            return url;
        }
    },

    ads: {
        homad: {
            global: 'https://s3.amazonaws.com/homad-global-configs.schneevonmorgen.com/global_config.json',
            client: '/homad-config.json'
        },

        appnexus: {
            vmap: 'https://ib.adnxs.com/vmap',
            ptv: 'https://ib.adnxs.com/ptv',
            cr: 'https://ib.adnxs.com/cr',
            ast: 'https://acdn.adnxs.com/ast/ast.js',
            debug: false
        }
    },

    cdn: {
        svp: `//cdn.stream.schibsted.media/player/${VERSION}`,
        player: '//cdn.stream.schibsted.media/jw/jwplayer-8.17.7',
        pollyfils: '//svp.vgc.no/pollyfils/es6-promise.min.js',
        youbora: 'https://smartplugin.youbora.com/v6/js/adapters/jwplayer/6.5.0/sp.min.js'
    },

    time: '//svp.vg.no/svp/api/v1/time',

    /**
       * Imbo images server
       */
    images: {
        servers: ['imbo', 'imbo01', 'imbo02', 'imbo03'],

        sizes: {
            poster: {
                autoHeight: [
                    380, 480, 580, 680, 780, 800, 980, 1080, 1440, 2048
                ],
                autoWidth: [
                    452, 613, 740, 900, 1080, 1440
                ]
            }

        }
    },

    /**
       * Default skins for providers
       */
    skins: {
        default: {
            url: `//cdn.stream.schibsted.media/player/${VERSION}/css/player.css`,
            name: 'svp-default jw-skin-seven'
        }
    },

    about: {
        vgtv: {
            link: 'http://www.vgtv.no/',
            text: 'VGTV'
        }
    },

    sharing: {
        global: {
            sites: ['facebook', 'twitter']
        },

        vgtv: {
            link: 'http://www.vgtv.no/#!/MEDIAID',
            // eslint-disable-next-line max-len
            code: '<iframe width="560" height="315" src="//www.vgtv.no/embed/?id=MEDIAID" frameborder="0" allowfullscreen="true"></iframe>'
        }
    },

    domains: {
        blacklist: {
            global: [],
            ab: ['*avpixlat.info', '*exponerat.net']
        },

        whitelist: {
            global: [
                '*localhost', '*schibsted.io', '*schibsted.media', '*schibsted.ws', '*schibsted.se', '*smp.no',
                '*plan3dev.se', '*plan3.se',
                '*vg.no', '*vgtv.no', '*vgnett.no',
                '*aftonbladet.se',
                '*aftenposten.no', '*ap.no',
                '*sa.no', '*aftenbladet.no',
                '*vglive.no',
                '*e24.no',

                // brandstudio cms
                '*vev.design',
                // stream cluster
                '*pubops.net',

                '*adresseavisen.no',
                '*polarismedia.no',
                '*adressa.no',
                '*bt.no',
                '*fvn.no',

                '*htmlinstant.com',
                '*ht.no',
                '*rbnett.no',
                '*itromso.no',
                '*folkebladet.no',
                '*framtidinord.no',
                '*osloby.no',
                '*av-avis.no',
                '*medianorge.no',
                '*herokuapp.com',
                '*bergenstidende.no',
                '*bonansa.no',
                '*sysla.no',
                '*bygdanytt.no',
                '*vestnytt.no',
                '*strilen.no',
                '*snd.no', '*fedrelandsvennen.no', '*lister24.no', '*l-a.no', '*lindesnesfyr.no',

                // Polaris related newspapers
                // addressa.no
                '*adressa.no', '*avisa-st.no', '*banett.no', '*fosna-folket.no', '*hitra-froya.no', '*innherred.no',
                '*mb.no', '*bladet.no', '*opdalingen.no', '*tronderbladet.no',
                // rbnett
                '*rbnett.no', '*andalsnes-avis.no', '*driva.no',
                // smp newspaper
                '*smp.no', '*dolen.no', '*fjordabladet.no', '*fjt.no', '*fjordingen.no', '*fjuken.no', '*heroynytt.no',
                '*morenytt.no', '*sunnmoringen.no', '*vigga.no', '*vikebladet.no',
                // itromso.no
                '*itromso.no', '*framtidinord.no',
                // ht.no
                '*ht.no', '*vol.no',
                // t-a.no
                '*t-a.no', '*namdalsavisa.no',
                // other polaris newspapers
                '*altaposten.no', '*folkebladet.no',
                '*svd.se',
                // marketplaces
                '*finn.no',
                '*familieklubben.no',
                '*tek.no',

                // testing env
                '*mylocal.com'
            ]
        },

        preview: {
            global: [
                '*vgnett.no',
                '*svp.schibsted.io',
                '*cms.schibsted.media',
                '*localhost',
                '*pubops.net',
                '*stream.schibsted.media'
            ]
        }
    },

    vendors: {
        se: ['ab', 'svd']
    },

    translations: translations
};

export default config;
