import utils from 'utils/utils';

const ClickTracker = {
    vendor: null,

    mode: 'TRACK',
    url: '//click.vgnett.no/svp.gif?s=',

    /**
         * Track media event
         *
         * @param data
         */
    trackMediaEvent(data) {
        const query = [];

        query.push(`d=${this.getDeviceType()}`);
        query.push(`v=${data.assetId}`);

        this.sendRequest('media', query.join('&'));
    },

    /**
         * Track playback events in certain time
         *
         * @param data
         */
    trackStreamEvent(data) {
        const query = [];

        query.push(`d=${this.getDeviceType()}`);
        query.push(`v=${data.assetId}`);
        query.push(`p=${data.time}`);

        // randomize request time for avoiding api peaks
        setTimeout(this.sendRequest.bind(this, 'stream', query.join('&')), Math.floor((Math.random() * 100) + 1));
    },

    /**
         * Send API request using 1x1px image
         *
         * @param type
         * @param queryString
         */
    sendRequest(type, queryString) {
        const image = new Image();
        const apiUrl = this.getApiUrl(type);
        const hypen = apiUrl.indexOf('?') > -1 ? '&' : '?';

        // track only if tracker is initialized
        if (this.vendor) {
            if (this.mode === 'LOG') {
                // eslint-disable-next-line no-console
                console.log('SVP STATS: ', [
                    queryString,
                    `${apiUrl + hypen + queryString}&cb=${Math.random() * 10000}`
                ]);
            }

            image.src = `${apiUrl + hypen + queryString}&cb=${Math.random() * 10000}`;
        }

        return image;
    },

    /**
         * Return full API endpoint url
         *
         * @returns {string}
         */
    getApiUrl(type) {
        if (this.vendor === 'vgtv' && type === 'media') {
            return '//click.vgnett.no/vgtv.gif';
        }

        return this.url + this.vendor;
    },

    /**
         * Tracking is divided by device type
         *
         * @returns {*}
         */
    getDeviceType() {
        if (utils.device.isTablet()) {
            return 'tablet';
        }

        if (utils.device.isMobile()) {
            return 'mobile';
        }

        return 'desktop';
    }
};

export default ClickTracker;
