import utils from 'utils/utils';
import locale from 'player/model/locale';
import PluginModel from './model';

/**
 * Displays sponsor label in top left corner of player
 *
 * @param options
 * @constructor
 */
class SponsorLabelPlugin extends PluginModel {
    constructor(options) {
        super(options);
        this.sponsor = options.sponsor;
        this.pluginName = 'SponsorLabelPlugin';
    }

    getName() {
        return this.pluginName;
    }

    setup() {
        this.listenToOnce(this.player, 'viewable', this.render, this);
    }

    render() {
        const adFrom = locale.translate('Ad from');

        if (this.sponsor) {
            this.el = utils.createNode(`<div class="svp-sponsor-label">${adFrom} ${this.sponsor}</div>`);
            this.player.getContainer().appendChild(this.el);
        }
    }

    destroy() {
        this.stopListening(this.player);

        if (this.el) {
            this.player.getContainer().removeChild(this.el);
            this.el = null;
        }
    }
}

export default SponsorLabelPlugin;
