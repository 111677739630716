
    var requestNumber, currentServerId, image, config, getServer, getImageTransform, serversAmount;

    getServer = (function() {
        var MAX_REQUEST_PER_SERVER = 6;

        return function(imageId) {
            if(!imageId) {
                return '';
            }

            if(requestNumber === MAX_REQUEST_PER_SERVER) {
                if(currentServerId === serversAmount) {
                    currentServerId = -1;
                }

                requestNumber = 0;
                currentServerId++;
            }

            requestNumber++;

            return imageId.replace("imbo", config.servers[currentServerId]);
        };
    }());

    function getImageQuality(pixelRatio) {
        return (pixelRatio >= 2) ? 50 : 80;
    }

    function normalizeTransformSize(size, pixelRatio) {
        // return default value
        return [
            size,
            getImageQuality(pixelRatio)
        ];
    }

    function getClosestSize(transformSizes, maxDimension) {
        return transformSizes.reduce(function (prev, curr) {
            return (Math.abs(curr - maxDimension) < Math.abs(prev - maxDimension) ? curr : prev);
        });
    }

    function getPixelRatio() {
        // we do not support px lower than 1 and bigger than 2
        return Math.max(1, Math.min(2, config.getPixelRatio()));
    }

    /**
     * Returns transformation for given image key and width
     * Adjusts the best
     */
    getImageTransform = (function() {
        var cache = {};

        return function(key, width, height) {
            var pixelRatio = getPixelRatio(),
                cacheKey = key + width + height + pixelRatio;

            // first check if there is cached object
            if(cache[cacheKey]) {
                return cache[cacheKey];
            }

            var id = key.split('.'),
                i = 0,
                idLen = id.length,
                transformType = (height > width) ? 'autoWidth' : 'autoHeight',
                transformSizes = config.sizes, transform, maxDimension;

            while(i < idLen) {
                transformSizes = transformSizes[id[i]];

                if(!transformSizes) {
                    throw new Error('Image transformation missing for key ' + key);
                }

                i++;
            }

            transformSizes = transformSizes[transformType];

            // transformation is adjusted to not 'auto' side
            maxDimension = (transformType === 'autoHeight') ? width : height;
            maxDimension = maxDimension * pixelRatio;

            var dimensions = normalizeTransformSize(getClosestSize(transformSizes, maxDimension), pixelRatio);

            // auto width transform starts with 'x'
            // http://svp.vg.no/svp/api/v1/docs/
            transform = (transformType === 'autoWidth') ? 'x' : '';
            transform = transform + dimensions[0] + 'q' + dimensions[1];

            cache[cacheKey] = transform;

            return transform;
        };
    }());

    image = {
        initialize: function(options) {
            config = options;
            serversAmount = options.servers.length - 1;
            requestNumber = 0;
            currentServerId = 0;
        },
        getImageSrc: function(imageId, key, width, height) {
            if(imageId) {
                var extension = (imageId.indexOf('.jpg') === -1 && imageId.indexOf('.png') === -1 ? '.jpg' : '');
                return getServer(imageId) + extension + '?t[]=' + getImageTransform(key, width, height);
            }
            return null;
        }
    };

    export default image;

