import scriptLoader from './script-loader';

const loadModule = (moduleSrc) => {
    const root = window;

    if (root.define && root.define.amd) {
        return new Promise((resolve) => {
            root.require([moduleSrc], resolve);
        });
    }

    return scriptLoader(moduleSrc);
};

export default loadModule;
