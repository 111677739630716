import config from 'player/config';
import utils from 'utils/utils';

function isDomainInArray(origin, domains) {
    let domain;
    let pos;
    let domainBeginning;

    // check if domain has access for given provider
    // otherwise deny it
    // allow all dev builds set settings
    if (config.version === 'dev') {
        return true;
    }

    // list is not provided
    if (!utils.isArray(domains)) {
        return false;
    }

    const len = domains.length;

    for (let i = 0; i < len; i += 1) {
        domain = domains[i];

        // subdomain parsing, subdomain has to end with domain
        if (domain[0] === '*') {
            // remove asterisk
            domain = domain.slice(1);
            pos = origin.indexOf(domain);

            domainBeginning = origin[origin.length - domain.length - 1];

            // check if origin has exactly the same ending of domain
            if (pos > -1 && origin.length - pos === domain.length
                    && (domainBeginning === undefined || domainBeginning === '.')) {
                return true;
            }

            // eslint-disable-next-line no-continue
            continue;
        }

        if (domain.indexOf(origin) > -1) {
            return true;
        }
    }

    return false;
}

/**
     * Inherit data from parent array to child
     * Prevents undefined to persist if one of given array is empty
     *
     * @param parent
     * @param child
     * @returns {Array.<T>}
     */
function inherit(parent, child) {
    return (parent || []).concat(child || []);
}

const Domain = {
    domain: null,

    // default settings configuration
    settings: {
        // boolean Preview of not published videos
        preview: false,
        // completely turn off all ads
        na: false
    },

    vendors: {},

    initialize(domain) {
        this.domain = domain;
    },

    /**
         * Fetch permissions for extended settings
         *
         * @param vendor
         * @returns {*}
         */
    fetch(vendor) {
        if (!this.vendors[vendor]) {
            this.vendors[vendor] = Promise.resolve(config.domains);
        }

        return this.vendors[vendor];
    },

    /**
         * Check whatever custom settings may be used on current domain or not
         *
         * @param vendor - api key vendor
         * @returns {Promise}
         */
    getPermissions(vendor) {
        return this.fetch(vendor).then((data) => {
            const result = {};

            utils.each(data, (item, key) => {
                result[key] = isDomainInArray(
                    this.domain,
                    inherit(item.global, item[vendor])
                );
            });

            return result;
        });
    },

    /**
         * Check if current domain is blacklisted
         *
         * @param vendor - api vendor key
         * @returns {*}
         */
    isBlacklisted(vendor) {
        const { blacklist } = config.domains;

        // check if domain has access for given provider
        // otherwise deny it
        // allow all dev builds set settings
        if (config.version === 'dev') {
            return false;
        }

        return isDomainInArray(this.domain, inherit(blacklist.global, blacklist[vendor]));
    }
};

export default Domain;
