/* eslint-disable no-underscore-dangle */
import utils from 'utils/utils';
import nextAsset from './next-asset';
import recommendedList from './recommended/list';

class Recommended {
    constructor({ asset, ml }) {
        this.asset = asset;
        this.useMlEngine = (ml !== false);
    }

    /**
     * Fetch next asset
     *
     * @returns {Promise}
     */
    getNextAsset(options = {}) {
        const { asset, useMlEngine } = this;

        // next asset can be provided in player configuration
        if (options.asset) {
            return nextAsset.fetchNextById({
                // set vendor is specified or use main asset as default
                vendor: options.asset.vendor || asset.getVendor(),
                // asset can be object or number with asset id
                assetId: options.asset.id || options.asset
            });
        }

        return nextAsset.fetchNext(this.asset, {
            useMlEngine
        });
    }


    /**
     * Fetch recommended asset list
     * Fetch assets by ids when items provided as a param
     *
     * @param items optional array of ids
     * @returns {*}
     */
    async getItems(items) {
        const { useMlEngine } = this;

        const vendor = this.asset.getVendor();
        const assetId = this.asset.get('id');

        if (!utils.isArray(items) || items.length === 0) {
            return recommendedList.fetchFromRelatedEngine({
                vendor,
                assetId,
                useMlEngine
            });
        }

        return recommendedList.fetchByIds({
            ids: items,
            vendor
        });
    }
}

export default Recommended;
