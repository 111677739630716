import utils from 'utils/utils';
import device from 'utils/device';
import logger from 'utils/logger';
import Viewport from './viewport';
import HomadAdsJw from './ads/homad-jw';
import autoplay from './config/autoplay';
import appnexus from './ads/appnexus';
import preventAdSeeking from './ads/prevent-seeking';

import youbora from './youbora';
import svpSkin, { skinLoader } from './skin';

const PlayerModel = function (config) {
    /**
     * JW Player instance
     * @type {null}
     */
    this.player = null;
    this.stream = null;
    this.config = config;

    this.isInitalized = false;

    /**
     * Indicates whenever stream was was stopped
     * @type {boolean}
     */
    this.isStopped = false;

    /**
     * Current playlist item
     *
     * @type {null}
     */
    this.playlistItem = null;

    /**
     * Current time holder for seek and seeked events
     * Fix for JW getPosition bug
     * @type {null}
     */
    this.currentTime = null;

    /**
     * Store an asset with playlist to allow load pulse data properly on replay whole playlist
     * @type {Asset|null}
     */
    this.masterAsset = null;

    /**
     * Breakpoints for visuals
     * @type {{xsmall: number, small: number, medium: number, big: number, web: number, webWide: number}}
     */
    this.viewport = new Viewport();
    this.listenTo(this.viewport, 'change', this.onViewportChange, this);


    /**
     * Indicates if ads are currently playing
     * @type {boolean}
     */
    this.adPlaying = false;

    /**
     * Adposition
     *
     * @type {null} preroll|midroll|postroll
     */
    this.adPosition = null;
};

/**
 * Forward JW Events without any change of them as they fits our needs
 *
 * List of proxied events
 * 'play', 'pause'
 *
 * @param event
 */
function forwardEvent(event) {
    this.listenTo(this.player, event, this.trigger.bind(this, event));
}

/**
 * Simple proxy for JW Methods
 *
 * @param method
 * @returns {Function}
 */
function bindJwMethod(method) {
    return function () {
        const args = Array.prototype.slice.call(arguments);

        if (!this.player) {
            // eslint-disable-next-line no-console
            console.warn(`Method ${method} called before player has been initialised`);
            return null;
        }

        return this.player[method].apply(this, args);
    };
}

/**
 * Proxy methods from JW
 */
function forwardMethods(methods) {
    const forwardedMethods = {};

    utils.each(methods, function (method) {
        forwardedMethods[method] = bindJwMethod.call(this, method);
    }, this);

    return forwardedMethods;
}

function getAdPosition(slotId) {
    const slotKeys = { pre: 'preroll', mid: 'midroll', post: 'postroll' };

    return slotKeys[slotId];
}

/**
 * Parse adresponse for each adslot
 *
 * @param xml
 */
function parseAdData(data) {
    const { sequence } = data;
    const xml = data && data.response;

    let source = 'WRAPPER';
    let adSelector = 'Ad';
    let adData = null;

    if (xml) {
        // only when additional data is given we set source to appnexus
        if (xml.URL === data.tag) {
            source = 'INLINE';
            adSelector = `Ad[sequence="${sequence}"]`;
        }

        adData = Array.prototype.slice.call(xml.querySelectorAll(adSelector));
        // get first element if available
        adData = adData && adData[0] ? adData[0] : null;
    }

    return {
        sequence: sequence,
        count: data.podcount,
        raw: adData,
        source: source,
        meta: data
    };
}

/**
 * Initialize playback end method
 * Clear after each completion of stream or playbackEnd reached
 *
 * @param playbackEnd
 */
function onPlaybackEndAvailable(playbackEnd) {
    const onTime = (position) => {
        if (position > playbackEnd) { //
            this.trigger('playbackEnd', Math.round(position));
        }
    };

    this.on('time', onTime);

    this.once('playbackEnd complete', function () {
        this.off('time', onTime);
    });
}

/**
 * Extended ready event with info about device autoplay ability
 * @param data
 */
function onReady(options, eventData) {
    this.trigger('ready', utils.extend(options, eventData));
}

PlayerModel.prototype = {
    initialize() {
        this.player = jwplayer(this.config.get('node'));
        this.trigger('initialize');
    },

    setup() {
        this.initialize();

        // player could not be setup
        if (!this.player.setup) {
            // eslint-disable-next-line no-console
            console.error(`SVP Player initialization error. DOM ${this.config.get('node')} not found`);
            return;
        }

        this.getConfig().then((config) => {
            // speedup lookup
            const { player } = this;

            player.setup(config);

            svpSkin(this);
            preventAdSeeking(this);
            // load youbora only for non-mock streams
            if (this.stream && this.stream.get('type') !== 'mock') {
                youbora.initialize(this.player, {
                    accountCode: 'schibsted',
                    enableAnalytics: true
                });
            }
            logger('JW').log('config', config);

            // forward native JW events without changing their behaviour
            utils.each([
                'seek', 'displayClick', 'captionsChanged', 'adPause', 'adPlay', 'autostartNotAllowed', 'adClick',
                'fullscreen', 'volume', 'mute', 'nextShown', 'nextAutoAdvance', 'nextClick', 'relatedReady',
                'playlistComplete'
            ], forwardEvent, this);

            this.listenTo(player, 'error setupError', this.onError, this);
            this.listenTo(player, 'playlistItem', this.onPlaylistItemLoad, this);

            this.listenTo(player, 'play', this.onPlay, this);
            this.listenTo(player, 'pause', this.onPause, this);
            this.listenTo(player, 'complete', this.onComplete, this);
            this.listenTo(player, 'ready', onReady.bind(this, {
                canAutoplay: autoplay.canAutoplay(config)
            }));

            this.listenTo(player, 'time', this.onTime, this);
            this.listenTo(player, 'resize', this.onPlayerResize, this);
            this.listenTo(player, 'viewable', this.onViewable, this);
            this.listenTo(player, 'autostartNotAllowed', this.onAutostartNotAllowed, this);

            this.listenTo(player, 'meta', this.onMeta, this);
            this.listenTo(player, 'metadataCueParsed', this.onMetadataCueParsed, this);

            this.listenTo(player, 'seek', function (data) {
                this.currentTime = data.offset;
            }, this);
            this.listenTo(player, 'seeked', function () {
                this.trigger('seeked', this.currentTime);
                // clearing has to occur after seekend event as getCurrentTime needs it
                this.currentTime = null;
            }, this);

            // 'restart' stream only when flash has been blocked
            this.once('flashBlocked', function () {
                this.listenTo(player, 'providerChanged', this.play, this);
            }, this);

            this.once('initialPlay', function () {
                this.listenToOnce(player, 'captionsList', function () {
                    this.trigger('captionsList', this.getCaptionsList());
                }, this);
            }, this);

            this.on('initialPlay', () => {
                this.isStopped = false;
            });

            this.listenTo(player, 'adImpression', function (data) {
                if (data && data.adposition) {
                    this.adPosition = getAdPosition(data.adposition);
                }

                if (this.adPlaying === false) {
                    this.trigger('adSlotStart', {
                        position: this.adPosition,
                        response: data && data.response,
                        meta: data
                    });

                    this.adPlaying = true;
                }

                this.trigger('adStarted');

                if (data && data.response) {
                    this.trigger('adData', parseAdData(data));
                }
            }, this);

            this.listenTo(player, 'adError', function (data) {
                if (data && data.adposition) {
                    this.adPosition = getAdPosition(data.adposition);
                }
            }, this);

            this.listenTo(player, 'adTime', function (data) {
                if (data.position && data.duration) {
                    this.trigger('adProgress', data.position, data.duration);
                }
            }, this);

            this.on('adSlotStart', function () {
                this.listenToOnce(player, 'adBreakEnd', function () {
                    if (this.adPlaying === true) {
                        this.trigger('adSlotComplete', {
                            position: this.adPosition
                        });

                        this.adPlaying = false;
                    }
                }, this);
            }, this);

            this.listenTo(player, 'adSkipped', function () {
                this.trigger('adSkipped');
            }, this);

            this.listenTo(player, 'adComplete', function () {
                this.trigger('adFinished');
            }, this);

            this.isInitalized = true;

            logger('JW').log((log) => {
                this.listenTo(player, 'all', function (event) {
                    if (['bufferChange'].indexOf(event) < 0) {
                        log(event, Array.prototype.slice.call(arguments, 1));
                    }
                });
            });

            this.trigger('setup');
        });
    },

    playNext(stream, { disableAutoplay }) {
        // clear time
        this.stream = stream;

        this.complete('playNext');

        if (!this.isInitalized) {
            this.setup();
        } else {
            this.getConfig(stream).then((config) => {
                this.player.load(config.playlist);

                if (disableAutoplay) {
                    return;
                }

                // stream is not available yet, display countdown
                if (stream.getTimeToStart() < 0) {
                    // enable autoplay if stream is not available
                    this.player.play(true);
                } else {
                    this.player.stop();
                }
            });
        }
    },

    async togglePlayback(type) {
        const streamType = type === 'audio' && this.stream.hasMp4Streams() ? 'mp4' : 'hls';
        console.log(device.isIOS());
        console.log(streamType);
        const streamUrl = await this.config.getStreamUrl(streamType);
        const streamPoster = this.config.getPoster(this.stream);
        this.player.load([{
            id: this.stream.getId(),
            image: streamPoster,
            // image: '',
            sources: [{
                file: streamUrl,
                // force mp4 stream type for ads
                type: streamType,
                default: true
            }],
            starttime: this.getCurrentTime(),
            playbackType: type,
            tracks: []
        }]);

        this.player.play(true);
    },

    /**
     * @param {Asset|null} [asset=null]
     */
    setMasterAsset(asset = null) {
        this.masterAsset = asset;
    },

    /**
     * Checks if the current playlist contains a given asset
     * @param {number} assetId
     * @returns {boolean}
     */
    isAssetFromPlaylist(assetId) {
        return (
            this.masterAsset != null
            && (this.masterAsset.get('id') === assetId
                || this.masterAsset.get('playlist').some((assetData) => assetData.id === assetId))
        );
    },

    /**
     * Immediately completes currently playing stream
     */
    complete() {
        this.adPlaying = false;

        // trigger complete event only when stream is not finished to prevent double 'complete' event triggering
        if (this.getCurrentTime() > 0 && this.getCurrentTime() !== this.getDuration()) {
            this.trigger('complete');
        }
    },

    /**
     * Get DOM Node where player is inserted
     * @returns {*}
     */
    getContainer() {
        return this.player.getContainer();
    },

    /**
     * Get playback volume
     * @returns {*}
     */
    getVolume() {
        return this.player.getVolume();
    },

    /**
     * Set playback volume
     * @param volume
     */
    setVolume(volume) {
        this.player.setVolume(volume);
    },

    /**
     * Get mute flag
     * @return {*|boolean}
     */
    getMute() {
        return this.player.getMute();
    },

    /**
     * Set mute param in player
     * @param value
     */
    setMute(value) {
        this.player.setMute(value);
    },

    /**
     * Seek
     */
    seek(time) {
        // flash bug - player is not seeking when stream hasn't started playing
        if (this.getCurrentTime() === 0) {
            // start stream only it's not playing
            // move this to play method?
            if (this.player.getState() !== 'playing') {
                this.play();
            }

            this.once('assetPlay', this.player.seek.bind(this.player, time));
        } else {
            this.player.seek(time);
        }
    },

    /**
     * Get player config. Method is asynchronous due to loading info from api
     *
     * @param stream
     */
    getConfig(stream) {
        const nextStream = stream || this.getStream();

        return this.config
            .getJwConfig(nextStream)
            .then(skinLoader)
            .catch((reason) => {
                this.trigger('error', reason);
            });
    },

    /**
     * Set stream data
     *
     * @param stream
     */
    setStream(stream) {
        this.stream = stream;
    },

    /**
     * Get current stream loaded with player
     *
     * @returns {null|Stream|*}
     */
    getStream() {
        return this.stream;
    },

    /**
     * Check is ad is playing or not
     */
    isAdPlaying() {
        return this.adPlaying;
    },

    /**
     * Load given stream
     * Perform all required checks before stream playback
     *
     * @returns {Promise}
     */
    loadStream() {
        return new Promise((resolve) => {
            // for lazy loaded player
            if (!this.isInitalized) {
                this.setup();
                this.player.on('ready', resolve);
            } else {
                resolve();
            }
        });
    },

    /**
     * Play stream
     */
    play() {
        // ensure stream is loaded properly
        this.loadStream()
            .then(this.player.play.bind(this.player));
    },

    /**
     * Pause stream
     * @param force state
     */
    pause(force) {
        this.player.pause(force);
    },

    /**
     * Stop playback
     */
    async stop() {
        // playlistitemId is set when first frame is shown
        // stop shouldn't change stream when it was not played
        if (this.isStopped === false) {
            this.trigger('complete', 'stop');

            const { playlist } = await this.getConfig();

            this.player.load(playlist);
            this.player.stop();

            // reset playlist item to allow replaying this stream
            this.playlistItem = null;

            this.isStopped = true;
        }
    },

    /**
     * Destroy the player instance, reset DOM, clean up listeners
     */
    remove() {
        // cleanup any bind listener
        this.off();

        if (this.player) {
            this.pause(true);

            this.player.off();
            this.player.remove();

            this.trigger('remove');
        }
    },

    /**
     * Get stream duration
     *
     * @returns {*}
     */
    getDuration() {
        const duration = this.player.getDuration();

        if (duration < 0) {
            return -duration;
        }

        return duration;
    },

    /**
     * Get current playback time
     *
     * @returns {*}
     */
    getCurrentTime() {
        let { currentTime } = this;

        if (currentTime) {
            return currentTime;
        }

        // player not initialized (error/geoblock)
        // return 0
        if (!this.player) {
            return 0;
        }

        currentTime = this.player.getPosition();

        if (currentTime < 0) {
            return (this.getDuration() + currentTime);
        }

        return this.player.getPosition();
    },

    getState() {
        if (this.isAdPlaying()) {
            return 'adPlaying';
        }

        return this.player.getState();
    },

    // eslint-disable-next-line consistent-return
    getAdBlock() {
        if (this.player) {
            return this.player.getAdBlock();
        }
    },

    /**
     * Get poster for current stream
     * Poster may be changed in config
     *
     * @returns {*}
     */
    getPoster(stream, container) {
        return this.config.getPoster(stream, container);
    },

    /**
     *
     * Get playback mode
     *
     * @returns {*}
     */
    getProvider() {
        const provider = this.player.getProvider();

        if (provider && provider.name === 'flash') {
            return 'flash';
        }

        return 'html5';
    },

    /**
     * Get list of captions extended by data from api
     *
     * @returns {*}
     */
    getCaptionsList() {
        const playerCaptions = this.player.getCaptionsList();
        const captions = this.getStream().getCaptions();

        // merge player captions data with result from api
        utils.each(playerCaptions, (caption) => {
            utils.each(captions, (item) => {
                if (caption.id === item.url) {
                    caption.language = item.language;
                    caption.default = item.default;
                }
            });
        });

        return playerCaptions;
    },

    /**
     * Set current captions
     * @param index (number|string) - string is language key, number is index in array
     */
    setCurrentCaptions(index) {
        let newIndex = index || 0;

        if (!utils.isNumber(index)) {
            utils.each(this.getCaptionsList(), (caption, captionsIndex) => {
                if (caption && caption.language === index) {
                    newIndex = captionsIndex;
                }
            });

            // fallback to off in case index is not found in captions array
            if (!utils.isNumber(newIndex)) {
                newIndex = 0;
            }
        }

        // set captions in player
        this.player.setCurrentCaptions(newIndex);
    },

    /**
     * Handle all errors from player
     */
    onError(data) {
        logger('SVP').error(data);
        const { code } = data;
        const message = data.message ? data.message.toString() : '';

        /**
         * Some errors do not prevent playback to play
         * thus they should be omitted
         */
        const skipErrors = [
            'Captions failed to load', // could not load
            'Casting failed to load' // chrome disabled
        ];

        // skip errors
        if (skipErrors.indexOf(message) > -1) {
            return;
        }

        if (code === 210002) {
            // Flash plugin failed to load
            // click to play
            this.pause();
            this.trigger('flashBlocked');
            return;
        }

        this.trigger('error', {
            message,
            code
        });
    },

    /**
     * Event triggered before first stream play
     * Useful for statistics
     */
    onPlaylistItemLoad(playlistItem) {
        if (!this.playlistItem || this.playlistItem.file !== playlistItem.item.file) {
            // cleanup any previous beforePlay event (playnext after countdown or error)
            this.stopListening(this.player, 'beforePlay');

            if (!this.playlistItem || this.playlistItem.id !== playlistItem.item.id) {
                // trigger this event every time new playlist item is loaded
                this.listenToOnce(this.player, 'beforePlay', function () {
                    if (this.stream.getTimeToStart() < 0) {
                        this.trigger('initialPlay');
                    }
                }, this);

                // ads should not if stream has future start time
                // attach event only for secure streams
                if (this.stream.isSecure()) {
                    // reload playlist only if token expired
                    // play method do this out of the box
                    this.listenToOnce(this.player, 'displayClick', function () {
                        if (!this.config.hasValidToken() && this.player.getState() !== 'playing') {
                            this.play();
                        }
                    }, this);
                }

                this.trigger('playlistItem', playlistItem.item);

                // clear ad playing flag for current content
                // important when changing stream while adslot is playing
                this.adPlaying = false;

                this.listenToOnce(this.player, 'firstFrame', () => {
                    this.playlistItemId = playlistItem.item.id;
                    this.onFirstFrameLoad(this.playlistItemId);
                });
            }

            this.playlistItem = playlistItem.item;

            // for streams which end time is not equal to video length
            const playbackEndTime = this.stream.getPlaybackTime('end');

            // attach playback listener
            if (playbackEndTime > 0) {
                onPlaybackEndAvailable.call(this, playbackEndTime);
            }
        }
    },

    /**
     * Time
     *
     * @param data.position - current playback time
     * @param data.duration - current stream duration
     */
    onTime(data) {
        if (data.position < 0) {
            this.trigger('time', -(data.duration - data.position), -(data.duration));
        } else {
            this.trigger('time', data.position, data.duration);
        }
    },

    /**
     * Triggered on first frame of content playback - after ads
     */
    onFirstFrameLoad(playlistItemId) {
        // first play of stream
        const mediaType = this.stream.get('mediaType');
        const startTime = this.stream.getPlaybackTime('begin') || 0;

        if (device.isSamsungInternet() && mediaType === 'video') {
            utils.removeClass(this.getContainer(), 'jw-flag-media-audio');
        }

        this.once('time', () => {
            // check if currently set video id is the same as played one
            // it can happen when playnext is called before assetPlay
            if (playlistItemId === this.stream.getId()) {
                this.trigger('assetPlay', startTime);
            }
        });
    },

    /**
     * Handler for checking player size
     */
    onPlayerResize(data) {
        this.viewport.update(data.width);
    },

    onViewable(eventData) {
        const { viewable } = eventData;

        this.trigger('viewable', {
            viewable
        });
    },

    onAutostartNotAllowed(data) {
        const { reason } = data;

        if (reason === 'autoplayDisabled') {
            this.player.setMute(false);
        }
    },

    onViewportChange(currentViewport, previousViewport) {
        // remove current class from container if it exist
        if (previousViewport.label) {
            utils.removeClass(this.getContainer(), `svp-viewport-${previousViewport.label}`);
        }

        utils.addClass(this.getContainer(), `svp-viewport-${currentViewport.label}`);

        this.trigger('viewport', currentViewport, previousViewport);
    },

    /**
     * Schedule midroll to
     * @param slotDuration
     * @param startTime
     * @returns {Promise<void>}
     */
    async createMidroll(slotDuration, startTime) {
        const midroll = appnexus.createMidroll({
            tag: await this.config.getLiveMidrollTag(slotDuration),
            startTime
        });

        const onTimeChange = () => midroll.setTime(this.player.getCurrentTime());

        midroll.onReady(() => {
            this.on('time', onTimeChange);
            // cleanup when stream has changed or finished
            this.on('complete', () => this.off('time', onTimeChange));
        });

        midroll.onEnter(function (adTag) {
            const position = -(Math.abs(this.player.getPosition()));
            this.off('time', onTimeChange);

            this.once('adSlotComplete', () => {
                this.listenToOnce(this.player, 'providerFirstFrame', () => {
                    this.player.seek(position);
                });
            });

            this.player.playAd(adTag);
        }.bind(this));
    },

    /**
     * Handling midrolls with scte35 tags in HLS live
     * @param data
     */
    onMetadataCueParsed(data) {
        if (!this.stream.isLive()) {
            return;
        }

        const { tag, content, start } = data.metadata || {};

        if (tag === 'EXT-X-CUE-OUT') {
            this.createMidroll(parseInt(content, 10), start);
        } else if (tag === 'EXT-X-CUE-IN') {
            this.player.skipAd();
        }
    },

    /**
     * Handling midrolls in RTMP live streams
     * @param data
     */
    onMeta(data) {
        if (!this.stream.isLive()) {
            return;
        }

        const params = data.metadata && data.metadata.TXXX;

        if (params) {
            // Workaround for live midrolls SCTE-35 tags in Safari
            // @see https://github.schibsted.io/svp/platform/issues/328
            if (params.insertAd && device.isSafari()) {
                const duration = parseInt(params.insertAd, 10);
                this.createMidroll(duration, 'NOW');
            }

            // Workaround for live midrolls SCTE-35 tags in Safari
            // @see https://github.schibsted.io/svp/platform/issues/328
            if (params.cancelAd === 'now' && device.isSafari()) {
                this.player.skipAd();
            }
        }
    },

    onPlay(data) {
        this.trigger('play', {
            playReason: data.playReason
        });
    },
    onPause(data) {
        utils.removeClass(this.getContainer(), 'jw-state-buffering');

        this.trigger('pause', data);
    },

    /**
     * Correct event flow for end of streaming
     */
    onComplete() {
        // wait until postroll will finish
        this.trigger('complete');
    }
};

/**
 * Extend player with events
 * Add methods from JW which are used without any rewriting
 */
utils.extend(PlayerModel.prototype, utils.Events, forwardMethods.call(PlayerModel.prototype, [
    'getCurrentCaptions', 'setCaptionsStyles'
]));

/* jshint newcap: false */
export default HomadAdsJw(PlayerModel);
