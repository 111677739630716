import config from 'player/config';
import moduleLoader from 'utils/module-loader';

// eslint-disable-next-line
const loadSvpLibrary = () => {
    return import(/* webpackMode: "eager" */ './index').then((SVP) => [
        SVP.default,
        SVP.preInitialize,
        SVP.postInitialize
    ]);
};

const loadJwPlayer = (root) => {
    const PLAYER_LIB = `${config.cdn.player}/jwplayer.js`;

    return moduleLoader(PLAYER_LIB).then((jwplayer = root.jwplayer) => {
        root.jwplayer = jwplayer;
    });
};

(async function (root) {
    const onReady = root.onSvpPlayerReady;

    // onSvpPlayerReady is required callback to handle due to SDK async loading
    loadJwPlayer(root)
        .then(loadSvpLibrary)
        .then((library) => {
            const [SVP, preInitialize, postInitialize] = library;

            if ((root && !root.SVP) || (root.SVP && root.SVP.isLoaded !== true)) {
                root.SVP = SVP;
                root.SVP.isLoaded = true;

                preInitialize();

                if (window.dispatchEvent) {
                    const event = document.createEvent('Event');
                    event.initEvent('onSvpPlayerReady', false, true);
                    window.dispatchEvent(event);
                }

                if (onReady) {
                    onReady();
                }

                postInitialize();
            }
        });
}(window));
