import configuration from 'player/config';
import locale from 'player/model/locale';
/**
 * Create sharing config based on passed options
 *
 * @param options
 * @param stream
 * @param globals
 * @return {*}
 */
const getConfig = ({
    options, stream, globals = {
        sharing: configuration.sharing,
        title: locale.translate('Sharing')
    }
}) => {
    if (options === false) {
        return null;
    }

    const defaults = globals.sharing || {};
    const vendor = stream.getVendor();

    const sharing = Object.assign({}, defaults.global);

    // use provider specific sharing info
    if (defaults[vendor]) {
        Object.assign(sharing, defaults[vendor]);
    }

    // extend sharing options when object is provided as param for it
    if (typeof options === 'object') {
        Object.assign(sharing, options);
    } else if (typeof options === 'function') {
        Object.assign(sharing, options(stream.attributes));
    }

    // add uri encoding to prevent sharing problems
    if (sharing.code) {
        sharing.code = encodeURIComponent(sharing.code);
    }

    if (!sharing.heading) {
        sharing.heading = globals.title;
    }

    return sharing;
};

/**
 * JW configuration adapter
 *
 * @param args
 * @return {function(*): *}
 */
const addSharing = (...args) => function (config) {
    const sharing = getConfig(...args);

    if (sharing === null) {
        delete config.sharing;
    } else {
        config.sharing = sharing;
    }

    return config;
};

export {
    getConfig,
    addSharing
};
