import api from '../api';

const getEndpoint = (assetId, limit = 20, engine) => {
    if (engine.indexOf('related-exprmnts') > -1) {
        return `/assets/${engine}/${assetId}`;
    }
    return `/assets/${assetId}/${engine}?limit=${limit}`;
};

/**
 * Fetch related list from related endpoint
 *
 * @param vendor
 * @param assetId
 * @param limit
 * @param engine - curently supported engines related (tags); related-ml (machine learning related);
 *                 related-exprmnts build on top of ML engine with additional adjustments
 *
 * @returns {Promise<*>}
 */
const getList = async ({
    vendor, assetId, limit = 20, engine = 'related'
}) => {
    const data = await api(
        vendor,
        getEndpoint(assetId, limit, engine)
    );

    // eslint-disable-next-line
    return data && data._embedded && data._embedded.assets;
};


export default {
    getList
};
