/* eslint-disable no-param-reassign */
import utils from 'utils/utils';
import Model from 'utils/model';
import config from 'player/config';
import Category from './category';

function handleErrors(response) {
    if (!response.ok) {
        return Promise.reject(response);
    }
    return response;
}

const Asset = function (options) {
    this.attributes = {
        id: 0
    };

    this.initialize(options);
};

Asset.prototype = {
    initialize(data) {
        utils.extend(this.attributes, this.parse(data));
    },

    url() {
        const additional = [
            'settings', 'chapters', 'cuePoints', 'externalId', 'barrels',
            'externalCategoryId', 'nextAsset', 'sourceFiles', 'subtitles',
            'metadata', 'access', 'tags', 'stories', 'playlist'
        ];

        return config.api.getUrl(this.getVendor(), `/assets/${this.get('id')}?additional=${additional.join('|')}`);
    },

    parse(data) {
        data = data || {};

        function unifyTimestamp(time) {
            if (!time) {
                return null;
            }

            if (time.toString().length === 10) {
                return time * 1000;
            }

            return time;
        }

        // rewrite additional fields
        if (data.additional) {
            utils.each(data.additional, (value, key) => {
                // featured chapters are fetched on the lists
                // merge them
                if (key === 'featuredChapters') {
                    key = 'chapters';
                }

                if (key === 'subtitles') {
                    key = 'captions';
                }

                if (typeof data[key] === 'undefined') {
                    data[key] = value;
                }
            });

            if (data.additional.settings) {
                if (data.additional.settings.startIndexPoint) {
                    data.startIndexPoint = data.additional.settings.startIndexPoint;
                }

                if (!utils.isUndefined(data.additional.settings.ageLimit)) {
                    data.ageLimit = data.additional.settings.ageLimit;
                }
            }

            if (data.additional.sourceFiles) {
                data.sourceFiles = data.additional.sourceFiles;
            }
        }

        if (data.series) {
            data.season = parseInt(data.series.seasonNumber, 10);
            data.episode = parseInt(data.series.episodeNumber, 10);

            data = utils.omit(data, 'series');
        }

        // multiply timestamps by 1000 to use ms instead of s
        if (data.flightTimes) {
            data.flightTimes = {
                start: (data.flightTimes.start ? unifyTimestamp(data.flightTimes.start) : null),
                end: (data.flightTimes.end ? unifyTimestamp(data.flightTimes.end) : null)
            };
        }

        data.created = (data.created ? unifyTimestamp(data.created) : null);
        data.published = (data.published ? unifyTimestamp(data.published) : null);
        data.updated = (data.updated ? unifyTimestamp(data.updated) : null);

        if (data.category && !(data.category instanceof Category)) {
            data.category = new Category(data.category);
        }

        if (data.chapters) {
            utils.each(data.chapters, (chapter, index) => {
                chapter.time = utils.string.getTimeFormatted(chapter.timeline);
                chapter.index = (index + 1);
            });
        }

        // next asset is optional, fill with basic object to avoid undefined errors
        if (!data.nextAsset) {
            data.nextAsset = {};
        }

        data.tags = data.tags || [];

        // eslint-disable-next-line no-underscore-dangle
        if (data._embedded && data._embedded.tags) {
            // eslint-disable-next-line no-underscore-dangle
            data.tags = data._embedded.tags;
        }

        data.playlist = [];

        // eslint-disable-next-line no-underscore-dangle
        if (data._embedded && data._embedded.playlist) {
            // eslint-disable-next-line no-underscore-dangle
            data.playlist = data._embedded.playlist;
        }

        return data;
    },

    fetch() {
        return fetch(this.url())
            .then(handleErrors)
            .then((response) => response.json())
            .then((json) => {
                Object.assign(this.attributes, this.parse(json));
            });
    },

    getVendor() {
        return this.get('vendor');
    },

    destroy() {
        // in case of any cleanup
    },

    getAgeLimit() {
        return utils.isNumber(this.get('ageLimit')) ? this.get('ageLimit') : -1;
    },

    isSponsored() {
        const metadata = this.get('metadata');

        let isSponsored = false;

        /**
         * SVP API metadata values are always strings. There can be different structures serialized to string though.
         */
        if (metadata && metadata.sponsored) {
            try {
                isSponsored = JSON.parse(metadata.sponsored.toLowerCase()) === true;
            } catch (e) {
                // All the cases that fail to be json decoded are treated as false
            }
        }

        return isSponsored;
    },

    getCategoryTitle() {
        const category = this.get('category');
        const {
            attributes: {
                title
            } = {}
        } = category || {};
        return title;
    },

    getNextAssetId() {
        return (this.get('nextAsset') && this.get('nextAsset').id) || null;
    },

    getNextAssetSource() {
        const metadataKey = 'relatedExperiments';
        const metadata = this.get('metadata');

        if (metadata && metadata[metadataKey]) {
            // byId manually set
            if (metadata[metadataKey] === 'byId') {
                return 'manual';
            }

            return metadata[metadataKey];
        }

        return null;
    },

    /**
     * Get chapter by its index (numbered from 1)
     *
     * @param chapterIndex
     * @returns {*} or undefined if not found
     */
    getChapter(chapterIndex) {
        return this.getChapters()[chapterIndex - 1];
    },

    /**
     * Get chapters for single stream
     *
     * @returns {*|Array}
     */
    getChapters() {
        return this.get('chapters') || [];
    },

    getThumbnail() {
        return this.get('images').main;
    },

    getTitle() {
        return this.get('title');
    },

    getCategory() {
        return this.get('category');
    }
};

utils.extend(Asset.prototype, Model);

export default Asset;
