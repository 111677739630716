import utils from 'utils/utils';
import closeIcon from 'icons/close.svg';
import appnexus from 'player/playback/ads/appnexus-ast';
import pauseTemplate from './pause.html';
import PluginModel from './model';

class PausePlugin extends PluginModel {
    constructor(options) {
        super(options);

        this.maxDisplays = -1;
        this.displayCounter = 0;
    }
}
/**
 * Check if ad is loaded
 *
 * @param sourceMethod
 * @returns {Function}
 */
function withAdTag(sourceMethod) {
    // eslint-disable-next-line consistent-return
    return function () {
        if (this.adPlacement) {
            return sourceMethod.apply(this, Array.prototype.slice.call(arguments));
        }
    };
}

function withCounter(sourceMethod) {
    // eslint-disable-next-line consistent-return
    return function () {
        if (this.maxDisplays === -1 || this.displayCounter < this.maxDisplays) {
            return sourceMethod.apply(this, Array.prototype.slice.call(arguments));
        }
    };
}

utils.extend(PausePlugin.prototype, {
    counter: 0,
    getName() {
        return 'PausePlugin';
    },

    resizeAd: withAdTag(function (width, height) {
        let size = (width && height) ? [width, height] : null;

        if (!size) {
            size = this.player.getContainer().getBoundingClientRect();
            size = [size.width, size.height];
        }

        appnexus.resizeTag(this.containerId, size);
    }),

    load: withAdTag(function (appnexusAdId) {
        this.listenTo(appnexus, 'adLoaded', function (adId, adData) {
            // different adId
            if (appnexusAdId !== adId) {
                return;
            }

            this.resizeAd();
            utils.addClass(this.el, 'svp-pause-view--visible');

            this.trigger('adVisible', {
                adId,
                creativeId: adData.creativeId
            });

            this.isAdTagLoaded = true;
        }, this);

        appnexus.defineTag(
            appnexusAdId,
            this.adPlacement
        ).then(() => {
            appnexus.showTag(appnexusAdId);
        });
    }),

    show: withCounter(withAdTag(function (data) {
        const { pauseReason } = data;

        // undefined is returned on ie11 win7
        // settingsInteraction is triggered on mobile phones while using quality or share button
        if (pauseReason !== undefined && pauseReason !== 'external' && pauseReason !== 'settingsInteraction'
            && this.player.model.player.getViewable() === 1) {
            this.displayCounter += 1;

            if (this.isAdTagLoaded === true) {
                appnexus.refreshTag(this.containerId);
            } else {
                this.load(this.containerId);
            }
        }
    })),

    hide: withAdTag(function () {
        utils.removeClass(this.el, 'svp-pause-view--visible');
    }),

    /**
     * Fixing pause event triggered while interacting with scrub bar
     * @param data
     */
    onPause: withCounter(function (data) {
        let timerId = null;

        function onSeek() {
            if (timerId) {
                clearTimeout(timerId);
                timerId = null;
            }
        }

        timerId = setTimeout(() => {
            // cleanup seek listener when seek wasn't performed
            // eg regular pause button click
            this.stopListening(this.player.model, 'seek', onSeek);
            // display pause ad
            this.show(data);
        }, 50);

        // prevent to display pause ad when user seeked stream
        this.listenToOnce(this.player.model, 'seek', onSeek);
    }),

    onResumeClick() {
        this.player.play();
        utils.removeClass(this.el, 'svp-pause-view--visible');
    },

    setup() {
        PausePlugin.prototype.counter += 1;
        this.containerId = `svpPauseContainer${PausePlugin.prototype.counter}`;

        this.listenTo(this.player.model, 'playlistItem', function (item) {
            const { adPlacements } = item;

            if (adPlacements && adPlacements.pause) {
                // eslint-disable-next-line max-len
                this.maxDisplays = (typeof adPlacements.pause.maxDisplays === 'undefined') ? -1 : parseInt(adPlacements.pause.maxDisplays, 10);

                this.displayCounter = 0;
                this.render(adPlacements.pause);
            }
        }, this);

        this.listenTo(this.player.model, 'pause', this.onPause, this);
        this.listenTo(this.player, 'play', this.hide, this);
    },

    render(adTagOptions) {
        const appnexusAdContainerId = this.containerId;

        this.adPlacement = adTagOptions;
        this.el = utils.createNode(utils.template(pauseTemplate, {
            appnexusAdContainerId: appnexusAdContainerId,
            closeIcon: closeIcon
        }));

        const playButton = this.el.querySelector('.jw-icon-playback');
        const closeButton = this.el.querySelector('.svp-pause-close');

        if (playButton) {
            playButton.addEventListener('click', this.onResumeClick.bind(this), true);
        }

        if (closeButton) {
            closeButton.addEventListener('click', (event) => {
                event.stopPropagation();
                this.hide();
            }, true);
        }
        this.player.getContainer().appendChild(this.el);

        this.listenTo(this.player.model.player, 'resize', function (width, height) {
            if (this.player.getState() === 'paused') {
                this.resizeAd(width, height);
            }
        }, this);

        this.trigger('render');
    },

    destroy() {
        appnexus.off(this.containerId);

        this.stopListening(this.player);
        this.stopListening(this.player.model);
        this.stopListening(appnexus);

        this.adPlacement = null;
        this.isAdTagLoaded = false;

        if (this.el) {
            this.player.getContainer().removeChild(this.el);
            this.el = null;
        }
    }

});

export default PausePlugin;
