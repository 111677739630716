import Eventable from 'utils/eventable';
import get from 'lodash/get';

/**
 * Parse SVP Player events and tailor data to pulse requirements
 *
 * Available events: Play, Pause, Stop, Watch
 */
class AdEvents extends Eventable {
    constructor(player) {
        super();
        this.player = player;
        this.adData = {};
        this.adSequencePosition = 0;
        this.adSequenceCount = 0;
        this.beforeUnload = ['onpagehide' in window.self
            ? 'pagehide' : 'beforeunload', this.onAdSlotComplete.bind(this), true];

        this.listenTo(this.player, 'playlistItem', this.onPlaylistItem, this);
        this.listenTo(this.player, 'adSlotStart', this.onAdSlotStart, this);
        this.listenTo(this.player, 'adStarted', this.onAdStarted, this);
        this.listenTo(this.player, 'adProgress', this.onAdProgress, this);
        this.listenTo(this.player, 'adSlotComplete', this.onAdSlotComplete, this);
        this.listenTo(this.player, 'adFinished', this.onAdFinished, this);

        this.listenTo(this.player, 'adPause', this.onAdPause, this);
        this.listenTo(this.player, 'adPlay', this.onAdPlay, this);

        this.listenTo(this.player, 'PausePlugin:adVisible', this.onPauseAdVisible, this);

        if (get(window, ['SVP', 'Player', 'isHomadLoaded']) === true) {
            window.addEventListener('hdEvent', this.onHomadEvent.bind(this));
        }

        window.addEventListener(...this.beforeUnload);
    }

    /**
     * Homad AAB ads have single event bus for all players
     *
     * @param event
     */
    onHomadEvent(event) {
        const details = event && event.detail;

        if (details.type === 'adRequest' && details.adIds) {
            this.adSequencePosition = 0;
        }

        if (details.type === 'adComplete' && details.adIds) {
            this.onAdFinished();
        }

        if (details.type === 'adStart' && details.adIds) {
            if (!this.adSequencePosition) {
                this.adSequenceCount = details.adIds.length;
            }

            this.adSequencePosition += 1;
            this.adIds = [...details.adIds];
            const { adID } = this.adIds[this.adIds.length - 1];

            Object.assign(this.adData, {
                type: details.state,
                duration: details.duration,
                id: adID,
                sequence: this.adSequencePosition,
                count: this.adSequenceCount,
                position: 0
            });

            this.trigger('homadAdMeta', this.adData);

            return this.onAdPlay();
        }

        return false;
    }

    onPlaylistItem() {
        this.adSequencePosition = 0;
        this.adSequenceCount = 0;
        this.listenToOnce(this.player, 'complete', this.onAdSlotComplete, this);
        this.listenTo(this.player.model.player, 'adImpression', (adData) => {
            Object.assign(this.adData, {
                duration: Math.round(adData.duration)
            });
        });
    }

    onHdEvent(event) {
        if (event && event.detail && event.detail.name === 'contentPlayerPlay') {
            this.abbAdPlayed = true;

            this.trigger('Play', Object.assign(this.eventData, {
                start: true,
                duration: 0
            }));
            return window.removeEventListener('hdEvent', this.onHdEvent);
        }
        return false;
    }

    onAdSlotStart(slotData) {
        Object.assign(this.adData, {
            type: slotData.position
        });
    }

    onAdStarted() {
        Object.assign(this.adData, {
            start: true
        });

        this.listenToOnce(this.player.model.player, 'adMeta', (adData) => {
            let adId = null;

            try {
                const adXml = adData.response.querySelectorAll('Ad')[adData.sequence - 1];
                adId = adXml.id;
            } catch (e) {
                // could not find Ad element
            }

            Object.assign(this.adData, {
                id: adId,
                sequence: adData.sequence,
                count: adData.podcount,
                position: 0
            });
        });
    }

    onAdProgress(time) {
        if (!this.adData.id) {
            return;
        }

        const position = Math.floor(time);
        const previousPosition = this.adData.position;

        Object.assign(this.adData, {
            position
        });

        if (previousPosition !== position) {
            this.trigger('Watch', this.adData);
        }
    }

    onAdSlotComplete() {
        this.sendStopEvent();
        window.removeEventListener(...this.beforeUnload);
    }

    onAdFinished() {
        this.sendStopEvent();
    }

    onAdPlay() {
        this.trigger('Play', this.adData);
        // start indicates first play of ad
        this.adData.start = false;
    }

    onAdPause() {
        this.trigger('Pause', this.adData);
    }

    onPauseAdVisible(data) {
        this.trigger('View', {
            id: data.creativeId,
            type: 'Pausead'
        });
    }

    sendStopEvent() {
        if (this.adData.id) {
            this.trigger('Stop', Object.assign({}, this.adData, { duration: this.adData.position }));
            this.adData = {};
        }
    }

    destroy() {
        window.removeEventListener(...this.beforeUnload);
        this.stopListening();
    }
}

export default AdEvents;
