import utils from 'utils/utils';
import config from 'player/config';

import { TOKEN_FETCH_ERROR } from '../playback/config/status-codes';

const fetchToken = function(params) {
    this.isFetching = true;

    return new Promise((resolve, reject) => {
        // fetch new token
        utils.ajax(this.url(params), (response) => {
            this.expire = +new Date() + 1000;
            this.value = response.responseText;
            this.isFetching = false;
            resolve(this.value);
        }, () => {
            this.isFetching = false;
            reject(TOKEN_FETCH_ERROR);
        }, true);
    });
};

class Token {
    constructor(assetId, assetAccess, maxBitrate) {
        /**
             * Id of SVP related asset
             */
        this.assetId = assetId;

        /**
             * An access level key string
             * See https://svp.vg.no/svp/api/v1/vgtv/assets/access-definitions?appName=docs for available levels
             */
        this.assetAccess = assetAccess;

        /**
             * Akamai token value retrieved from SVP api
             * @type {null}
             */
        this.value = null;
        /**
             * Expiration time, timestamp
             * @type {null}
             */
        this.expire = null;

        /**
             * Maximum allowed bitrate for stream
             * @type {null}
             */
        this.maxBitrate = maxBitrate || null;

        this.request = null;
        this.isFetching = false;
    }

    fetch(configuration) {
        if (!this.isValid()) {
            if (this.request && this.isFetching) {
                return this.request;
            }

            if (typeof configuration === 'function') {
                this.request = configuration(this.assetId, this.assetAccess).then(fetchToken.bind(this));
            } else {
                this.request = fetchToken.call(this);
            }
        }

        return this.request;
    }

    url(params) {
        const query = [
            `vendor=${config.api.vendor}`
        ];

        if (params && params.expiry && params.value) {
            query.push(`assetId=${this.assetId}`);
            query.push(`expires=${params.expiry}`);
            query.push(`hmac=${params.value}`);
        } else {
            query.push('acl=/*');
        }

        if (this.maxBitrate) {
            query.push(`maxBitrate=${this.maxBitrate}`);
        }

        return `${config.api.tokenUrl}?${query.join('&')}`;
    }

    getValue() {
        return this.value;
    }

    isValid() {
        // on load expire is null therefore it will always return false
        return (+new Date() < this.expire);
    }
}


utils.extend(Token.prototype, utils.Events);

export default Token;
