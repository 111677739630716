/* global apntag */
import config from 'player/config';
import utils from 'utils/utils';
import logger from 'utils/logger';

export default utils.extend({
    isLoaded: null,

    load() {
        if (window.apntag) {
            this.isLoaded = true;
            return Promise.resolve();
        }
        if (this.isLoaded === false) {
            // after loading fail we do not retry
            return Promise.reject({
                firstLoad: false
            });
        }

        // load this only once
        return utils.loadScript(config.ads.appnexus.ast).then(() => {
            // check if object really exist
            this.isLoaded = typeof window.apntag === 'object';
        }).catch((error) => {
            // appnexus can be blocked by adblock
            this.isLoaded = false;

            return Promise.reject({
                firstLoad: true,
                error
            });
        });
    },

    defineTag(tagId, tagOptions) {
        return this.load().then(() => {
            const tagConfig = utils.merge({
                targetId: tagId
            }, tagOptions);

            apntag.debug = config.ads.appnexus.debug;
            // apntag tag overriden by reference
            apntag.defineTag(utils.extend({}, tagConfig));

            apntag.loadTags([tagId]);
            apntag.onEvent('adLoaded', tagId, this.trigger.bind(this, 'adLoaded', tagId));
        }).catch((error) => {
            if (error.firstLoad) {
                logger('SVP').log('Could not load AST tag');
            }
        });
    },

    showTag(tagId) {
        if (this.isLoaded) {
            apntag.showTag(tagId);
        }
    },

    refreshTag(tagId) {
        if (this.isLoaded) {
            apntag.refresh([tagId]);
        }
    },

    resizeTag(tagId, dimmensions) {
        const size = [
            parseInt(dimmensions[0], 10),
            parseInt(dimmensions[1], 10)
        ];

        if (this.isLoaded) {
            apntag.resizeAd(tagId, size);
        }
    },

    off(targetId) {
        if (this.isLoaded) {
            apntag.offEvent('adLoaded', targetId);
        }
    }
}, utils.Events);
