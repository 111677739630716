import device from 'utils/device';

export default (svpPlayer) => {
    if (!svpPlayer.player.getControls()) {
        return;
    }

    svpPlayer.on('playlistItem', (playlistItem = {}) => {
        if (device.isIOS()) {
            const fullscreenButton = svpPlayer.getContainer().querySelector('.jw-icon-fullscreen');
            const display = playlistItem.stereomode === 'monoscopic' ? 'none' : 'inherit';

            svpPlayer.on('initialPlay', () => {
                fullscreenButton.style.display = display;
            });
        }
    });
};
