import ClickTracker from './svp-stats/tracker';
import PluginModel from './model';

/**
 * Seconds intervals for tracking
 * @returns {number}
 */
function getTrackingInterval(duration) {
    // if duration is not set then do not track it
    if (duration > 0) {
        // for streams shorter than 250s tracking interval is 5seconds
        if (duration < 250) {
            return 5;
        }

        // otherwise it's every 2% of stream
        return Math.floor(duration * 0.02);
    }

    return -1;
}


/**
 * Basic SVP statistics tool
 *
 * @param options
 * @constructor
 */
class SvpStatsPlugin extends PluginModel {
    constructor(options) {
        super(options);

        this.env = options.env;
        this.vendor = options.vendor;
        this.mode = options.mode;
    }

    // eslint-disable-next-line
    getName() {
        return 'SvpStatsPlugin';
    }

    setup() {
        // stats can be turned off only on non production env
        if (this.mode === 'OFF' && this.env !== 'production') {
            return;
        }

        ClickTracker.mode = this.mode;
        ClickTracker.vendor = this.vendor;

        this.listenTo(this.player, 'assetPlay', function (startTime) {
            const asset = this.player.getAsset();
            const assetId = asset.get('id');
            const duration = this.player.getDuration();
            const trackingInterval = getTrackingInterval(duration);

            let initialStartTime = startTime || -1;
            let currentTime = -1;

            ClickTracker.trackMediaEvent({
                assetId: assetId
            });

            // tracking interval maybe invalid when stream is live or do not have proper duration
            if (trackingInterval > 0 && asset.get('streamType') !== 'live') {
                // assetPlay prevents from sending events when it has start time
                this.listenTo(this.player, 'time', (playerTime) => {
                    const time = Math.floor(playerTime);

                    // engagement tracking
                    if (time !== currentTime && time % trackingInterval === 0 && time > initialStartTime) {
                        currentTime = time;
                        // reset start time counter for tracking seek before start point in indexed stream
                        initialStartTime = -1;

                        // prevent requests outside stream size
                        if (time > -1 && time <= duration) {
                            ClickTracker.trackStreamEvent({
                                assetId: assetId,
                                time: time
                            });
                        }
                    }
                });
            }
        });

        this.listenTo(this.player, 'adSlotStart', function (adSlot) {
            if (adSlot.position === 'preroll') {
                ClickTracker.trackStreamEvent({
                    assetId: this.player.getAsset().get('id'),
                    time: -1
                });
            }
        });

        this.listenTo(this.player, 'adSlotComplete', function (adSlot) {
            if (adSlot.position === 'preroll') {
                ClickTracker.trackStreamEvent({
                    assetId: this.player.getAsset().get('id'),
                    time: -2
                });
            }
        });
    }

    destroy() {
        ClickTracker.vendor = null;
        this.stopListening(this.player);
    }
}


export default SvpStatsPlugin;
