/**
 * Audio video switching button
 *
 */
const copyToClipboard = data => {
    const el = document.createElement('textarea');
    el.value = data;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.focus();
    el.select();

    try {
        document.execCommand('copy');
    } catch (err) {
        console.log('Unable to copy');
    }

    document.body.removeChild(el);
};


export {
    copyToClipboard
};
