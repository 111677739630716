import config from 'player/config';
import moduleLoader from 'utils/module-loader';

let request = null;

const youboraSdk = {
    state: 'UNLOADED',
    options: {},

    load(options = {}) {
        this.options = options;
        if (request === null) {
            this.state = 'LOADING';

            request = moduleLoader(config.cdn.youbora);
            request.then((youbora = window.youbora) => {
                this.state = 'READY';
                window.youbora = youbora;
            });
        }

        return request;
    },

    initialize(player, options = {}) {
        // skip when library is not loaded
        if (this.state !== 'READY' || this.state === 'LOADED') {
            return;
        }

        const { youbora } = window;

        // skip if youbora sdk does not exist
        if (!youbora) {
            return;
        }

        try {
            const plugin = new youbora.Plugin(Object.assign(
                options, this.options
            ));

            plugin.setAdapter(new youbora.adapters.JWPlayer(player));

            this.state = 'LOADED';
        } catch (e) {
            console.error('Could not load youbora plugin', e);
        }
    }
};

export default youboraSdk;
