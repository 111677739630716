let translations = {};

const setTranslations = (data) => {
    translations = data;
};

// eslint-disable-next-line
const translate = (key, substitute) => {
    // typeof to check keys like "0"
    return translations[key] || (typeof substitute === 'undefined' ? key : substitute);
};

const getMonthName = (date) => {
    const months = translations.months || [];
    return months[date.getMonth()] || date.getMonth();
};

export default {
    translate,
    setTranslations,
    getMonthName
};
