/**
 * @param {PlayerModel & Eventable} svpPlayerModel
 * @returns {HTMLDivElement}
 */
function getTitleNode(svpPlayerModel) {
    const container = svpPlayerModel.getContainer();
    return container.getElementsByClassName('jw-nextup-title')[0];
}

/**
 * @param {PlayerModel & Eventable} svpPlayerModel
 */
export default function (svpPlayerModel) {
    const { player: jwPlayer } = svpPlayerModel;

    jwPlayer.on('nextShown', (playlistItem) => {
        const nextUpTitle = getTitleNode(svpPlayerModel);
        nextUpTitle.innerHTML = playlistItem.itemsShown[0].svp.stream.get('title');
    });

    jwPlayer.on('nextAutoAdvance', () => {
        const nextUpTitle = getTitleNode(svpPlayerModel);
        nextUpTitle.innerHTML = '';
    });

    jwPlayer.on('nextClick', () => {
        const nextUpTitle = getTitleNode(svpPlayerModel);
        nextUpTitle.innerHTML = '';
    });
}
