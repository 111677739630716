/* eslint-disable no-underscore-dangle */

import seriesService from 'player/services/api/series';
import relatedService from 'player/services/api/related';
import assetService from 'player/services/api/asset';
import Stream from './stream';
import Asset from './asset';

import relatedHelpers from './recommended/related';

const createResponse = ({ nextAsset, source }) => ({
    nextAsset: nextAsset ? new Asset(nextAsset) : null,
    source
});

const handlers = {
    /**
     * Check if given asset has next stream
     * Used for setting/disabling postrolls
     *
     * @param asset
     * @returns {boolean}
     */
    hasPlayNext(asset, includeCategory) {
        if (asset.get('streamType') === 'live') {
            return false;
        }

        if (asset.get('nextAsset').id) {
            return true;
        }

        if (includeCategory === true) {
            return this.hasNextInCategory(asset);
        }

        return false;
    },

    /**
     * Check if given asset has next stream
     *
     * @param asset
     * @returns {boolean}
     */
    hasNextInCategory(asset) {
        const category = asset.getCategory();

        if (category) {
            if (category.isSeries()) {
                return true;
            }

            if (category.get('autoPlayNextAsset')) {
                return true;
            }
        }

        return false;
    },


    /**
     * Fetch next asset from asset series
     * Rejected when next asset is not found
     *
     * @param asset
     */
    async fetchNextFromSeries({
        vendor, assetId, season, categoryId
    }) {
        const nextAsset = await seriesService.getNextEpisode({
            vendor,
            assetId,
            categoryId,
            season
        });

        return createResponse({
            source: 'series',
            nextAsset
        });
    },

    /**
     * Fetch next asset from parent category
     * Rejected when next asset is not found
     *
     * @param asset object
     * @param settings
     */
    async fetchNextFromCategory ({
        vendor, published, categoryId, order
    }) {
        const nextAsset = await assetService.getClosestAsset({
            vendor,
            published,
            order,
            categoryId
        });

        return createResponse({
            source: `category-${order}`,
            nextAsset
        });
    },

    /**
     * Fetch next asset by providing it's id
     *
     * @param vendor
     * @param assetId
     * @param source
     * @returns {Promise<{nextAsset, source}>}
     */
    async fetchNextById({ vendor, assetId }) {
        const nextAsset = await assetService.getById({
            vendor,
            assetId
        });

        return createResponse({
            source: 'byId',
            nextAsset
        });
    },

    async fetchNextFromRelatedEngine({ vendor, assetId, source = 'related' }) {
        const assets = await relatedService.getList({
            engine: source,
            vendor,
            assetId
        });

        let nextAsset = null;

        if (source === 'related-exprmnts/getbyid') {
            // eslint-disable-next-line
            nextAsset = assets[0];
        } else {
            nextAsset = relatedHelpers.omitRecurring(vendor, assetId, assets);
        }

        return createResponse({
            nextAsset,
            source
        });
    },

    /**
     * Fetch next for given asset
     * Cascade  lookup from direct linkage (nextAsset.id) to retrieving from category
     * Rejected when nextAsset is not found
     *
     * @param asset
     * @returns {Promise}
     */
    async getNext(asset, { useMlEngine }) {
        const vendor = asset.getVendor();
        const assetId = asset.get('id');
        const category = asset.getCategory();
        const nextAssetId = asset.getNextAssetId();

        const categoryId = category.get('id');
        const season = parseInt(asset.get('season'), 10);

        // fetch next from series as user expects next episode to be played
        // when there is no next episode then we just display recommended grid
        if (category.isSeries() && season > 0) {
            const fetchNextParams = {
                season,
                categoryId,
                vendor,
                assetId
            };

            const result = await this.fetchNextFromSeries(fetchNextParams);

            // fetch next episode from following season
            if (!result.nextAsset) {
                return this.fetchNextFromSeries(Object.assign(fetchNextParams, {
                    season: fetchNextParams.season + 1,
                    assetId: null
                }));
            }
            return result;
        }

        const experiments = category.getRelatedExperiments();

        // no experiments set
        if (experiments.length === 0 && nextAssetId) {
            return this.fetchNextById({
                assetId: nextAssetId,
                vendor
            });
        }

        // with fallback to category related when there is no video from ml engine
        try {
            return await this.fetchNextFromRelatedEngine({
                source: relatedHelpers.getSource(
                    useMlEngine,
                    experiments,
                    Math.random()
                ),
                vendor,
                assetId
            });
        } catch (e) {
            return this.fetchNextFromRelatedEngine({
                source: relatedHelpers.getSource(
                    useMlEngine,
                    [{ name: 'related-ml', begin: 0, end: 100 }],
                    0
                ),
                vendor,
                assetId
            });
        }
    },

    /**
     * Fetch next for given asset
     * Cascade  lookup from direct linkage (nextAsset.id) to retrieving from category
     * Rejected when nextAsset is not found
     *
     * @param asset
     * @param options
     * @returns {Promise}
     */
    async fetchNext(asset, options) {
        const { nextAsset, source } = await this.getNext(asset, options);

        if (!nextAsset) {
            return Promise.reject({
                reason: 'nextAssetNotFound',
                source
            });
        }

        const stream = new Stream(nextAsset.attributes);

        if (!stream.isActive()) {
            return Promise.reject({
                reason: 'nextAssetNotActive',
                source
            });
        }

        return {
            nextAsset,
            source
        };
    }
};

export default handlers;
