const getLevelSettingsIcon = (container) => container.querySelector('.jw-icon-settings');

const handleLevelSettingsIcon = (data, icon) => {
    if (icon) {
        icon.style.display = '';

        // hide settings icon when there is only one level available
        // usually this is a case for iOS or when hls has limited bitrate
        if (data && data.levels && data.levels.length === 1) {
            icon.style.display = 'none';
        }
    }
};

export default (svpPlayer) => {
    svpPlayer.on('playlistItem', function () {
        this.listenToOnce(
            svpPlayer.player,
            'levels',
            (levels) => handleLevelSettingsIcon(levels, getLevelSettingsIcon(svpPlayer.getContainer()))
        );
    }, this);
};
