import api from '../api';
import { getMockAsset } from './asset/mock';

const getAssetEndpoint = (id) => {
    const additional = [
        'settings', 'chapters', 'cuePoints', 'externalId', 'barrels',
        'externalCategoryId', 'nextAsset', 'sourceFiles', 'subtitles',
        'metadata', 'access', 'tags', 'stories', 'playlist'
    ];

    return `/assets/${id}?additional=${additional.join('|')}`;
};

const getListEndpoint = ({ params = [], filters = [] }) => {
    const queryParams = [...params];

    if (filters.length > 0) {
        queryParams.push(`filters=${filters.join('|')}`);
    }

    return `/assets${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`;
};

const getById = async ({ vendor, assetId }) => api(
    vendor,
    getAssetEndpoint(assetId)
);

const getByIds = async ({ vendor, ids }) => {
    const response = await api(
        vendor,
        getListEndpoint({
            params: [`limit=${ids.length}`],
            filters: [`assetId::${ids.join(',')}`]
        })
    );

    // eslint-disable-next-line
    return response._embedded && response._embedded.assets || [];
};

const getClosestAsset = async ({
    vendor, order, categoryId, assetId, publishedDate
}) => {
    const endpoint = getListEndpoint({
        params: [
            'limit=1',
            'sort=-published',
            // depending on from which "side" we're asking
            (order === 'next' ? `after=${publishedDate + 1}` : `before=${publishedDate - 1}`)
        ],

        filters: [
            `categoryId::${categoryId}`
        ]
    });

    const response = await api(
        vendor,
        endpoint
    );

    // parse response
    // eslint-disable-next-line
    if (response && response._embedded && response._embedded.assets) {
        // eslint-disable-next-line
        const [nextAsset] = response._embedded.assets;

        if (nextAsset && nextAsset.id !== assetId) {
            return nextAsset;
        }
    }

    return null;
};

export default {
    getById,
    getByIds,
    getClosestAsset,
    getMockAsset
};
