import logger from 'utils/logger';
import utils from 'utils/utils';
import locale from 'player/model/locale';
import { forward30Icon } from 'icons/audio';

const DEFAULT_USE_AUDIO_SKIN = true;

const CN_FORWARD_BUTTON = 'jw-icon-forward';
const CN_FORWARD_CONTROL = 'jw-display-icon-forward';
const CN_DURATION_INFO = 'svp-duration';

/** {string} An icon with proper class names */
const forwardIcon = forward30Icon.replace('<svg', '<svg class="jw-svg-icon jw-svg-icon-forward"');

/**
 * @param {number} seconds
 * @return {string}
 */
function timeToText(seconds) {
    if (seconds < 60) {
        return `${Math.floor(seconds)} sec`;
    }
    if (seconds < 3600) {
        return `${Math.floor(seconds / 60)} min`;
    }
    return `${Math.round(seconds / 36) / 100} hrs`;
}

/**
 * @param {string} label
 * @return {HTMLElement}
 */
function createForwardControl(label) {
    return utils.createNode(`
        <div class="jw-display-icon-container ${CN_FORWARD_CONTROL} jw-reset">
            <div
                class="jw-icon ${CN_FORWARD_BUTTON} jw-button-color jw-reset"
                role="button"
                tabindex="0"
                aria-label="${label}"
            >
                ${forwardIcon}
            </div>
        </div>`);
}

/**
 * Resolves whether the player needs an audio skin or not and adds necessary elements to the UI if needed
 * @param {PlayerModel} svpPlayer
 */
export default function audioSkinResolver(svpPlayer) {
    const translate = locale.translate('player');
    const { config, player: jwPlayer } = svpPlayer;
    const useAudioSkin = Boolean(config.get('audioSkin') || DEFAULT_USE_AUDIO_SKIN);
    logger('AudioSkin').log(useAudioSkin ? 'on' : 'off');

    if (!useAudioSkin) {
        return;
    }

    const seekForward = () => {
        const position = jwPlayer.getPosition();
        const duration = jwPlayer.getDuration();
        svpPlayer.seek(Math.min(duration, position + 30));
    };

    svpPlayer.on('playlistItem', () => {
        if (!svpPlayer.player.getControls()) {
            return;
        }

        const container = svpPlayer.getContainer();
        const stream = svpPlayer.getStream();
        const isAudio = stream.isAudio();
        const duration = stream.getDuration();
        const forwardTooltip = translate.forward;

        const displayControl = container.querySelector('.jw-display-icon-display .jw-icon-display');
        const cover = container.querySelector('.jw-preview .svp-cover');
        let forwardButton = container.querySelector(`.jw-controlbar .${CN_FORWARD_BUTTON}`);
        let forwardControl = container.querySelector(`.jw-display-container .${CN_FORWARD_CONTROL}`);
        let durationInfo = container.querySelector(`.jw-title .${CN_DURATION_INFO}`);

        if (!cover) {
            container.querySelector('.jw-preview').appendChild(
                utils.createNode('<div class="svp-cover" />')
            );
        }

        if (!forwardButton) {
            jwPlayer.addButton(forwardIcon, forwardTooltip, seekForward, 'forward', CN_FORWARD_BUTTON);
            forwardButton = container.querySelector(`.jw-controlbar .${CN_FORWARD_BUTTON}`);
        }

        if (!forwardControl) {
            const controls = container.querySelector('.jw-display-controls');
            forwardControl = createForwardControl(forwardTooltip);
            forwardControl.addEventListener('click', seekForward);
            controls.appendChild(forwardControl);
        }

        if (!durationInfo) {
            const title = container.querySelector('.jw-title');
            durationInfo = utils.createNode(`<div class="${CN_DURATION_INFO}" />`);
            title.appendChild(durationInfo);
        }

        const displayStyle = isAudio ? null : 'none';
        const addOrRemoveClass = isAudio ? utils.addClass : utils.removeClass;
        addOrRemoveClass(container, 'svp-flag-media-audio');
        displayControl.setAttribute('aria-label', isAudio ? translate.listen : translate.playback);
        forwardButton.style.display = displayStyle;
        forwardControl.style.display = displayStyle;
        durationInfo.style.display = displayStyle;
        durationInfo.innerText = isAudio ? timeToText(duration) : '';
    });
}
