/**
 * Check if preroll is scheduled in configuration
 *
 * @param config
 * @returns {boolean}
 */
const hasPreroll = (config) => {
    const playlistItem = config && config.playlist[0];

    // check if preroll exists in array
    if (playlistItem.adschedule) {
        return Object.values(playlistItem.adschedule)
            .some((slot) => slot.offset === 'pre');
    }

    return false;
};

export default function (stream, options) {
    // additional config options required for playback
    return function (config) {
        let canAutostart = options.autoplay;

        // firefox requires this flag to be set when playing SecureHD stream
        if (stream && stream.isSecure()) {
            config.withCredentials = true;
        }

        // stream is not available yet, display countdown
        if (!stream.hasPlayableSource()) {
            // disable autoplay if stream is not available
            config.preload = 'none';
            config.autostart = false;
        }

        // force sound mute when configuration is set
        if (options.mute === true) {
            config.mute = true;
        }

        // load skin if available
        if (options.skin) {
            config.skin = options.skin;
        }

        // fix jw bug
        // by default you can't play muted ads
        if (hasPreroll(config) && config.advertising.autoplayadsmuted !== true) {
            try {
                if (config.mute === true || localStorage.getItem('jwplayer.mute') === 'true') {
                    canAutostart = false;
                    // unmute player when autostart is not available
                    config.mute = false;
                }
            } catch (error) {
                // for private browsing
            }
        }

        // disable autostart for future streams
        if (stream.isFuture()) {
            canAutostart = false;
        }

        if (typeof canAutostart !== 'undefined') {
            config.autostart = canAutostart;
        }

        // set autopause to viewable
        if (options.autopause === 'viewable') {
            config.autoPause = {
                viewability: true
            };
        }

        // set repeat mode
        if (options.repeat === true) {
            config.repeat = options.repeat;
        }

        return config;
    };
}
