import utils from 'utils/utils';
import locale from 'player/model/locale';

import countdownTemplate from './countdown.html';
import PluginModel from './model';

/**
 * Format number by adding leading zeros eg 7 -> 07
 *
 * @param numValue
 * @param numLen
 * @returns {string}
 */
const leadingZeros = (numValue, numLen) => {
    const length = numLen || 2;
    let num = String(numValue);

    while (num.length < length) {
        num = `0${num}`;
    }

    return num;
};

/**
 * Renders countdown clock for streams which are not published yet
 *
 * @param options
 * @constructor
 */
class CountdownPlugin extends PluginModel {
    constructor(options) {
        super(options);

        /**
         * Start date for stream (flightTime.start)
         * Javascript Date object
         */
        this.start = options.start;

        this.liveDOM = null;
        this.countdownDOM = null;
    }

    // eslint-disable-next-line
    getName() {
        return 'countdown';
    }

    setup() {
        this.listenTo(this.player, 'playlistItem', this.render, this);
    }

    /**
     * Cleanup old node
     */
    destroy() {
        this.stopListening(this.player);

        if (this.el) {
            this.player.getContainer().removeChild(this.el);
            this.el = null;
        }
    }

    render() {
        try {
            this.player.getContainer();
        } catch (error) {
            return;
        }

        this.el = utils.createNode(utils.template(countdownTemplate));
        this.player.getContainer().appendChild(this.el);

        utils.addClass(this.player.getContainer(), 'svp-countdown-loaded');

        // cached live label dom element
        // eslint-disable-next-line prefer-destructuring
        this.liveDOM = this.el.getElementsByClassName('svp-live-label')[0];
        // eslint-disable-next-line prefer-destructuring
        this.countdownDOM = this.el.getElementsByClassName('svp-countdown-live-timer')[0];

        // countdown is ready
        this.trigger('ready');
        // start event runs on first change of countdown
        this.once('change', this.trigger.bind(this, 'start'));

        // interval has to be initialized on
        this.intervalId = setInterval(this.refresh.bind(this), 1000);
        this.refresh();
    }

    refresh() {
        const start = this.getDateData(this.start);

        // trigger only if date has changed
        if (this.repaint(start)) {
            this.trigger('change', start);
        }
    }

    repaint(date) {
        const string = [];
        const liveDate = this.start;

        if (this.isCountdownActive()) {
            string.push(`${leadingZeros(date.min)}:`);
            string.push(`${leadingZeros(date.sec)}<span class="svp-timer-unit">s</span>`);

            if (this.currentState !== 'timer') {
                this.currentState = 'timer';
                this.liveDOM.innerHTML = locale.translate('Video will start in');

                utils.addClass(this.player.getContainer(), 'svp-countdown-active');
            }
        } else if (this.currentState !== 'date') {
            this.currentState = 'date';

            string.push(`${liveDate.getDate()} ${locale.getMonthName(liveDate)} `);
            string.push(`${leadingZeros(liveDate.getHours())}:`);
            string.push((leadingZeros(liveDate.getMinutes())));

            this.liveDOM.innerHTML = locale.translate('Video will start at');
        }

        if (string.length > 0) {
            this.countdownDOM.innerHTML = (string.join(''));
            return true;
        }

        return false;
    }

    /**
     * End of countdown
     */
    stop() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        this.intervalId = null;
        this.el.parentNode.removeChild(this.el);
        this.el = null;

        utils.removeClass(this.player.getContainer(), ['svp-countdown-active', 'svp-countdown-loaded']);

        this.trigger('end');
    }

    getDateData(endDate) {
        let diff = (endDate - Date.parse(new Date())) / 1000;

        // end of counting
        if (diff <= 0) {
            diff = 0;

            if (this.intervalId) {
                this.stop();
            }
        }

        const dateData = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0
        };

        if (diff >= (365.25 * 86400)) {
            dateData.years = Math.floor(diff / (365.25 * 86400));
            diff -= dateData.years * 365.25 * 86400;
        }

        if (diff >= 86400) {
            dateData.days = Math.floor(diff / 86400);
            diff -= dateData.days * 86400;
        }

        if (diff >= 3600) {
            dateData.hours = Math.floor(diff / 3600);
            diff -= dateData.hours * 3600;
        }

        if (diff >= 60) {
            dateData.min = Math.floor(diff / 60);
            diff -= dateData.min * 60;
        }

        dateData.sec = Math.floor(diff);

        return dateData;
    }

    /**
     * Check whatever countdown or date should be displayed
     * Countdown is active when less than 1 hour reminded to start
     * @returns {boolean}
     */
    isCountdownActive() {
        const now = new Date();

        // less than 1 hour
        return (Math.abs(this.start.getTime() - now.getTime())) < 3599999;
    }
}

export default CountdownPlugin;
