import utils from 'utils/utils';
import logger from 'utils/logger';
import * as appnexusTagBuilder from './appnexus-tag-builder';
import JwAdsConfig from './appnexus-jw-config';

// move to appnexus jw
function getAdsConfig(adConfig, stream, settings) {
    const adSlots = adConfig.adSlots || {};
    const params = adConfig.params || [];
    const adsConfig = new JwAdsConfig(stream);
    const { hasNext } = (settings || {});

    adsConfig.setParams(params);
    adsConfig.add(adSlots.preroll, 'pre', 'preroll');

    utils.each(stream.getCuePoints(), function (cuePoint) {
        if (cuePoint && cuePoint.timeline) {
            adsConfig.add(adSlots.midroll, cuePoint.timeline, 'midroll');
        }
    });

    // postroll is disabled when playnext is available
    if (hasNext === false || (hasNext !== true && stream.get('hasNext') === false)) {
        adsConfig.add(adSlots.postroll, 'post', 'postroll');
    }

    return adsConfig.getConfig();
}

function getParams(params, memberId) {
    if (memberId) {
        return utils.extend({}, {
            member: memberId
        }, params);
    }

    return params;
}

function parseAdPlacements(adPlacements, globalParams) {
    const placements = {};

    utils.each(adPlacements, function (values, placementId) {
        placements[placementId] = appnexusTagBuilder.getAstConfig(values, globalParams);
    });

    return placements;
}

function getJwAdSchedule(clientConfig, stream, settings) {
    const { adSlots } = clientConfig;

    if (adSlots) {
        return getAdsConfig({
            adSlots: adSlots.schedule,
            params: getParams(adSlots.params, clientConfig.member)
        }, stream, settings);
    }

    return {};
}

function getAdPlacements(clientConfig) {
    const { adPlacements } = clientConfig;

    if (clientConfig.member && adPlacements) {
        return parseAdPlacements(
            adPlacements.schedule,
            getParams(adPlacements.params, clientConfig.member)
        );
    }

    return {};
}

const getClientConfig = async (stream, settings) => {
    // ads are disabled
    // check stream config also
    const { na, adn } = settings;

    if (na === true || !adn || stream.hasAdsDisabled()) {
        return null;
    }

    let svpAdConfig = {};

    if (typeof adn === 'function') {
        svpAdConfig = await adn(stream, appnexusTagBuilder.getDefaultParams(stream), adn.svpPlayer);
    }

    logger('SVP').log('adsConfig', svpAdConfig);

    return svpAdConfig;
};

/**
 * Handle midroll scheduling in player
 *
 * @param tag
 * @param startTime
 * @returns {{onEnter: onEnter, setTime: setTime, onReady: onReady}}
 */
function createMidroll({ tag, startTime }) {
    let hasEntered = false;
    let onEnterCallback = () => {};

    const onEnter = (callback) => {
        onEnterCallback = callback;
    };

    const onReady = (callback) => {
        if (tag) {
            callback();
        }
    };

    const setTime = (time) => {
        if (hasEntered === false && (time >= startTime || startTime === 'NOW')) {
            onEnterCallback(tag);
            hasEntered = true;
        }
    };

    return {
        setTime,
        onEnter,
        onReady
    };
}

function getConfig(stream, settings) {
    return getClientConfig(stream, settings).then(function (clientConfig) {
        // null equals to empty ads
        if (clientConfig === null) {
            return {};
        }

        return {
            adSchedule: getJwAdSchedule(clientConfig, stream, settings),
            adPlacements: getAdPlacements(clientConfig),
            adOptions: clientConfig.options || {}
        };
    });
}

function getLiveMidrollTag(maxDuration, stream, settings) {
    return getClientConfig(stream, settings).then(function (clientConfig) {
        if (!clientConfig) {
            return undefined;
        }

        const { adSlots } = clientConfig;
        const midroll = adSlots && adSlots.schedule && adSlots.schedule.midroll;
        if (!midroll) {
            return undefined;
        }

        const params = getParams((adSlots || {}).params, clientConfig.member);
        params.vmaxduration = maxDuration;

        return appnexusTagBuilder.getVmapTag(utils.merge(params, midroll));
    });
}

export default {
    getConfig,
    getLiveMidrollTag,
    createMidroll
};
