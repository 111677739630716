import utils from 'utils/utils';
import logger from 'utils/logger';
import ServerTime from 'utils/server-time';
import config from 'player/config';
import errorIcon from 'icons/error.svg';
import Asset from './model/asset';
import NextAsset from './model/next-asset';
import Stream from './model/stream';
import locale from './model/locale';
import Config from './playback/config';
import Provider from './playback/provider';
import { APPNEXUS_PROVIDER_ID } from './services/api';
import assetService from './services/api/asset';
import Domain from './model/domain';
import SvpPlugins from './plugins';
import PausePlugin from './plugins/pause';
import AgeLimitPlugin from './plugins/age-limit';
import SponsorLabelPlugin from './plugins/sponsor-label';
import PodcastExperimentPlugin, { prepareVariantsForExperiment, chooseVariant } from './plugins/podcast-experiment';
import CountdownPlugin from './plugins/countdown';
import RecommendedPlugin from './plugins/recommended';
import SvpStatsPlugin from './plugins/svp-stats';
import PulseTracker from './plugins/pulse-stats/tracker';
import PulseStatsPlugin from './plugins/pulse-stats';

import statusCode, { NOT_FOUND, GENERAL_ERROR } from './playback/config/status-codes';

/**
 * Remove single plugin instance
 * Clear all listeners
 * Remove from plugins hashmap
 *
 * @param {PluginModel} plugin
 */
function destroyPlugin(plugin) {
    if (plugin.off) {
        plugin.off();
    }
    plugin.destroy();

    this.stopListening(plugin);
    delete this.plugins[plugin.getName()];
}


/**
 * Clear error message
 * @param {HTMLElement} node
 */
function clearError(node) {
    const previousErrorNode = node.getElementsByClassName('svp-player-error-wrapper');

    // clear old node
    if (previousErrorNode.length > 0) {
        utils.removeClass(node, ['svp-player-error', 'svp-player-error-hasImage']);

        if (previousErrorNode[0].parentNode) {
            previousErrorNode[0].parentNode.removeChild(previousErrorNode[0]);
        }
    }
}

/**
 * Display error message in given node
 * @param node
 * @param message
 * @param code
 */
function displayError(node, message, code = null) {
    let template = `<div class="svp-player-message"><span class="svp-player-error-icon">${errorIcon}</span>${message}`;

    if (code) {
        template += `<div class="svp-player-error-code">${locale.translate('Error code')}: ${code}</div>`;
    }

    template += '</div>';

    const posterSrc = this.model && this.model.getStream() && this.model.getPoster(this.model.getStream(), node);

    clearError(node);
    utils.addClass(node, 'svp-player-error');

    // add image
    if (posterSrc) {
        utils.addClass(node, 'svp-player-error-hasImage');
        template += `<div class="svp-player-poster" style="background-image: url(${posterSrc})"></div>`;
    }

    const errorNode = utils.createNode(`<div class="svp-player-error-wrapper">${template}</div>`);

    node.appendChild(errorNode);

    this.once('complete', function () {
        utils.removeClass(node, ['svp-player-error', 'svp-player-error-hasImage']);

        if (errorNode.parentNode) {
            errorNode.parentNode.removeChild(errorNode);
        }
    }, this);
}

/**
 * Display error message
 *
 * @param {Error} [details]
 */
function onError(details) {
    logger('SVP').error(details);
    const node = document.getElementById(this.config.get('node'));
    const { code, message } = details || {};

    let translatedMessage = locale.translate(message);

    // translation not found
    if (translatedMessage === message) {
        translatedMessage = locale.translate(statusCode(GENERAL_ERROR));
    }

    // details - message in english
    // message - localized, displayed message
    const errorDetails = {
        code,
        details: message,
        message: translatedMessage
    };

    if (details.type) {
        errorDetails.type = details.type;
    }

    displayError.call(this, node, translatedMessage, code);

    if (this.model.player) {
        this.model.player.stop();
    }

    this.trigger('error', errorDetails);
}

/**
 * @param {Object} playlistItem
 * @param {number} playlistItem.id
 */
function playNextFromPlaylist(playlistItem) {
    const { asset } = this;
    const { masterAsset } = this.model;

    if (!asset || !masterAsset || asset.get('id') === playlistItem.id) {
        return;
    }

    const nextAssetData = masterAsset.get('id') === playlistItem.id
        ? utils.extend({}, masterAsset.attributes)
        : masterAsset.get('playlist').find((assetData) => assetData.id === playlistItem.id);

    if (!nextAssetData) {
        return;
    }

    this.setAsset(nextAssetData);
}

/**
 * Player initialized and single playlistItem is laoded
 * @param {Object} playlistItem
 */
function onPlaylistItem(playlistItem) {
    const container = this.getContainer();
    // add class for live streams

    utils.removeClass(container, 'svp-player-live');

    if (this.model.getStream().isLive()) {
        utils.addClass(container, 'svp-player-live');
        // add translation for live button via data-label
        container.getElementsByClassName('jw-icon-display')[0]
            .setAttribute('data-label', locale.translate('Live button'));
    }

    playNextFromPlaylist.call(this, playlistItem);

    this.trigger('playlistItem');
}

/**
 * Stream completed, fired for every item in playlist
 * Clear settings which are only for one stream
 */
function onComplete(reason) {
    if (!this.isCompleted) {
        this.isCompleted = true;

        this.config.set('time', null);
        this.config.set('chapter', null);

        this.trigger('complete', reason);
    }
}

/**
 * Proxy player events
 */
function onPlayerEvent(event, ...args) {
    let callFunction;

    switch (event) {
        case 'playlistItem':
            callFunction = onPlaylistItem.bind(this, ...args);
            break;
        case 'complete':
            callFunction = onComplete.bind(this, ...args);
            break;
        case 'error':
            callFunction = onError.bind(this, ...args);
            break;
        default:
            callFunction = this.trigger.bind(this, event, ...args);
    }

    callFunction();
}

/**
 * @param {Object} options - Caution, param is bound in constructor
 * @param {Object} settings
 */
function onceConfigReady(options, settings) {
    this.model = new Provider(this.config);

    // important listeners has to be attached before setup
    this.listenTo(this.model, 'all', onPlayerEvent, this);

    this.listenTo(this.model, 'play', function () {
        this.isCompleted = false;
    }, this);

    // turn on cachebreaker for preview mode
    if (settings && settings.preview === true) {
        config.api.cb = true;
    }

    // once per player instance error append stylesheet
    this.once('error', function () {
        utils.addStyleSheet(this.config.get('skin').url);
    }, this);

    Promise.all([
        new Promise((resolve) => {
            const handleReady = () => {
                resolve();
                this.off('assetError', handleError); // eslint-disable-line no-use-before-define
            };

            const handleError = (error) => {
                resolve(error);
                this.off('assetError', handleReady);
            };

            this.once('assetReady', handleReady);
            this.once('assetError', handleError);
        }),
        SvpPlugins.load(options.plugins)
    ]).then(([error]) => {
        utils.each(options.plugins, (pluginConfig, plugin) => {
            const Plugin = SvpPlugins.get(pluginConfig.name || plugin);

            if (Plugin) {
                // support for new loading method
                this.addPlugin(new Plugin(pluginConfig.config || pluginConfig));
            }
        });

        if (!error) {
            this.model.setup();
        }
    });

    // asset can be passed as id or an object
    this.setAsset((options.asset || options.id));
}

/**
 * this always points to Player object
 */
function onAssetReady() {
    const { config: configuration } = this;

    const stream = new Stream(this.asset.attributes);
    const settings = configuration.getSettings();
    const node = document.getElementById(configuration.get('node'));
    const hasPlaylist = stream.hasPlaylist();

    if (node) {
        clearError(node);
    }

    const isAssetFromPlaylist = this.model.isAssetFromPlaylist(stream.getId());

    if (!isAssetFromPlaylist) {
        const masterAsset = hasPlaylist ? new Asset(utils.extend({}, this.asset.attributes)) : null;
        this.model.setMasterAsset(masterAsset);
    }

    // add hasNext checking
    stream.set(
        'hasNext',
        !hasPlaylist || !isAssetFromPlaylist || !stream.isDisabledNextVideo() || NextAsset.hasPlayNext(this.asset)
    );

    if (this.asset.get('access')) {
        const access = Object.keys(this.asset.get('access'))[0] || null;
        stream.set('access', access);
    }

    configuration.setStream(stream);

    // cleanup plugins as they maybe not required for new stream
    utils.each(this.plugins, destroyPlugin, this);

    // set start time if chapter is provided (not applicable for playlists)
    if (!hasPlaylist && !isAssetFromPlaylist && configuration.get('chapter')) {
        const chapter = this.asset.getChapter(configuration.get('chapter'));

        if (chapter) {
            stream.set('playAhead', chapter.timeline);
        }
    }

    // set playahead time
    if (!isAssetFromPlaylist && configuration.get('time')) {
        stream.set('playAhead', configuration.get('time'));
    }

    if (configuration.hasRecommended()) {
        this.addPlugin(new RecommendedPlugin({
            settings: configuration.getRecommended()
        }));
    }

    // stream is not available yet, display countdown
    if (stream.getTimeToStart() > 0) {
        // countdown is disable in preview mode on non live streams
        if (settings.preview !== true || stream.isLive()) {
            this.addPlugin(new CountdownPlugin({
                start: new Date((new Date().getTime()) + stream.getTimeToStart() * 1000)
            }));

            // play stream after countdown ends
            this.on('countdown:end', this.play, this);
        }
    }

    if (this.asset.getAgeLimit() > -1) {
        this.addPlugin(new AgeLimitPlugin({
            ageLimit: this.asset.getAgeLimit(),
            settings: configuration.getAgeLimit()
        }));
    }

    if (configuration.get('showSponsorLabel') && this.asset.isSponsored()) {
        this.addPlugin(new SponsorLabelPlugin({
            sponsor: this.asset.getCategoryTitle()
        }));
    }

    const metadata = this.asset.get('metadata') || {};
    const userStatus = configuration.get('userStatus') || {};

    let experiment;
    const isExperiment = !userStatus.loggedIn && metadata.experimentId && metadata.isPodcast === 'true';

    if (isExperiment) {
        try {
            let experimentRange = JSON.parse(sessionStorage.getItem('svpExperimentRange'));
            if (!experimentRange) {
                experimentRange = Math.random() * 100;
                sessionStorage.setItem('svpExperimentRange', JSON.stringify(experimentRange));
            }

            experiment = {
                id: metadata.experimentId,
                variant: chooseVariant(prepareVariantsForExperiment(metadata), experimentRange)
            };

            this.addPlugin(new PodcastExperimentPlugin(experiment,
                {
                    loginUrl: configuration.get('loginUrl'),
                    subscriptionUrl: configuration.get('subscriptionUrl'),
                    articleUrl: configuration.get('articleUrl')
                }));
        } catch (error) {
            logger('ExperimentPlugin').error('An error occur while initializing experiment', error);
        }
    }


    /**
     * Disable Pulse tracking for mock/ads only playback
     */

    const pulseConfig = configuration.get('pulse');
    if (pulseConfig && pulseConfig.provider && stream.isMock() === false) {
        this.addPlugin(new PulseStatsPlugin(pulseConfig, isExperiment ? experiment : null));
    }

    /**
     * Disable SVP Stats for mock
     */
    if (stream.isMock() === false) {
        this.addPlugin(new SvpStatsPlugin({
            vendor: configuration.get('vendor'),
            mode: configuration.get('stats'),
            env: configuration.get('env')
        }));
    }

    this.addPlugin(new PausePlugin());

    // set stream to display error
    this.model.setStream(stream);

    configuration.isStreamPlayable().then(() => {
        // restart asset state
        // this.isCompleted = false;
        this.trigger('assetReady');
    }).catch((code) => {
        this.trigger('assetError', {
            message: statusCode(code),
            code
        });

        onError.call(this, {
            type: 'assetError',
            // message is translated in next functions
            message: statusCode(code),
            code
        });
    });
}

/**
 * Asset fetch failed. Usually happens when network issue occurred
 * @param {Error} error
 */
function onAssetError(error) {
    const code = (error && error.status === 404) ? NOT_FOUND : GENERAL_ERROR;

    const details = {
        message: statusCode(code),
        code
    };

    this.trigger('assetError', details);

    // load stylesheet as player is not attached
    onError.call(this, Object.assign({
        type: 'assetFetchError'
    }, details));

    logger('SVP').error('error', error);

    // display errors in development mode
    if (config.env === 'development') {
        throw error;
    }
}

const Player = function (configuration) {
    const options = Object.assign({}, configuration);

    // Little bit of monkey patching
    config.api.vendor = options.vendor || 'vgtv';
    config.env = (config.env === 'production') ? 'production' : (options.env || config.env || 'production');

    // Restrict player on blacklisted domains
    if (Domain.isBlacklisted(options.vendor)) {
        return;
    }

    logger('SVP').log('config', utils.extend({}, options));

    /**
     * Allow to embed player without asset
     * This can be useful for preloading player
     * Appnexus vendor is for displaying ads only playback
     */
    if (options.vendor === APPNEXUS_PROVIDER_ID || options.id === -1) {
        options.asset = assetService.getMockAsset();
        delete options.id;
    }

    // load locales for player
    // norwegian by default
    if (typeof options.locale === 'string') {
        locale.setTranslations(config.translations(options.locale));
    }

    // bind player api to pass it as last argument in adn function
    if (options.adn) {
        options.adn.svpPlayer = this;
    }

    // create configuration for SVP player
    this.config = new Config();
    this.listenToOnce(this.config, 'ready', onceConfigReady, this);

    this.config.initialize(options);

    // set dynamic api url
    if (options.api) {
        config.api.url = options.api;
    }

    // allow to override token api url
    if (options.tokenUrl) {
        config.api.tokenUrl = options.tokenUrl;
    } else if (options.api) {
        config.api.tokenUrl = options.api.replace('api/v1/', 'token/v1/');
    }

    // allow to override thumbnails api url
    if (options.thumbnailsUrl) {
        config.api.thumbnailsUrl = options.thumbnailsUrl;
    } else if (options.api) {
        config.api.thumbnailsUrl = options.api.replace('api/v1', 'thumbnails/v1');
    }

    /**
     * Available plugins
     * PausePlugin, EndposterPlugin, ChaptersPlugin, ContinuePlayingPlugin
     *
     * Plugins registered by default (can not be removed)
     * CountdownPlugin, AgeLimitPlugin
     * @type {Object}
     */
    this.plugins = {};

    /**
     * Check if stream has completed playback
     * @type {boolean}
     */
    this.isCompleted = false;

    this.isPlayNextAvailable = true;

    // sync browser time with server
    if (!options.serverTime) {
        ServerTime.fetch(config.time);
    }

    // fetch config for privileged settings to speedup setup
    if (options.settings) {
        Domain.fetch(options.vendor);
    }

    /**
     * Prefetch Pulse tracking library
     */
    if (options.pulse && options.pulse.provider) {
        PulseTracker.load();
    }

    logger('SVP').log(function (message) {
        this.on('all', function (event) {
            message(event, Array.prototype.slice.call(arguments, 1));
        });
    }.bind(this));
};

/**
 * Player public API
 */
Player.prototype = {
    /**
     * Play
     * @param position - seconds (int)
     */
    play(position) {
        // stream will play only if publication date is valid
        if (this.model.getStream().getTimeToStart() < 0) {
            if (position) {
                this.once('play', this.seek.bind(this, position));
            }

            this.model.play();
        }
    },

    /**
     * Pause playback
     * @param force - toggle playback when param is omitted
     */
    pause(force) {
        // pause with force can start stream which is wrong
        if (this.model.getStream().getTimeToStart() < 0) {
            this.model.pause(!force);
        }
    },

    /**
     * Seek in seconds
     * @param time
     */
    seek(time) {
        this.model.seek(time);
    },

    /**
     * Destroy the player instance, reset DOM, clean up listeners
     */
    remove() {
        this.stopListening();

        if (this.model) {
            this.model.remove();
        }
    },

    /**
     * Play next asset by given id
     *
     * @param {number} id
     * @param {Object} [options]
     * @param {Object} [options.pulse]
     * @param {number} [options.time]
     * @param {boolean} [options.disableAutoplay]
     */
    playNext(id, options = {}) {
        const onNextAssetReady = function () {
            const stream = this.model.getStream();
            const { disableAutoplay, time } = options;

            if (time) {
                stream.set('playAhead', time);
            }

            // trick to avoid create of new stream as it's set in assetReady
            this.model.playNext(stream, { disableAutoplay });
            this.isPlayNextAvailable = true;
        }.bind(this);

        if (this.isPlayNextAvailable) {
            const reason = (options.pulse && options.pulse.playbackSource) || 'manualNext';
            // complete current stream
            onComplete.call(this, reason);

            // block multiple occurences
            this.isPlayNextAvailable = false;

            this.once('assetReady', onNextAssetReady, this);

            this.once('assetError', function () {
                if (this.model && this.model.player) {
                    this.pause();
                }

                this.isPlayNextAvailable = true;
                this.off('assetReady', onNextAssetReady);
            }, this);

            // trigger play next always after complete to keep event order the same
            // for videos which completed or has been interrupted with play next
            this.trigger('playNext', id, options);
            this.setAsset(id);
        }
    },

    /**
     * Set playback volume
     *
     * @param volume - number between 0 and 100
     */
    setVolume(volume) {
        this.model.setVolume(volume);
    },

    /**
     * Get playback volume
     * @returns {*}
     */
    getVolume() {
        return this.model.getVolume();
    },

    /**
     * Set mute value for playback
     * Toggling this param will preserve volume value
     *
     * @param value
     */
    setMute(value = true) {
        this.model.setMute(value);
    },

    /**
     * Get playback volume
     * @returns {*}
     */
    getMute() {
        return this.model.getMute();
    },

    /**
     * Get player state
     */
    getState() {
        return this.model.getState();
    },

    /**
     * Get stream duration
     *
     * @returns {*}
     */
    getDuration() {
        return this.model.getDuration();
    },

    /**
     * Get current playback time
     */
    getCurrentTime() {
        return this.model.getCurrentTime();
    },

    /**
     * Get quarter of stream.
     * Can be used for tracking
     *
     * @returns {number}
     */
    getCurrentQuartile() {
        return Math.ceil((this.getCurrentTime() / this.getDuration()) / 0.25);
    },

    /**
     * Get device type
     *
     * @returns {*}
     */
    getDeviceType() {
        if (utils.device.isIPhone()) {
            return 'iPhone';
        }

        if (utils.device.isIPad()) {
            return 'iPad';
        }

        if (utils.device.isAndroid()) {
            return 'android';
        }

        return 'desktop';
    },

    /**
     * Get current playback provider
     */
    getProvider() {
        return this.model.getProvider();
    },

    /**
     * Get current captions list
     */
    getCaptionsList() {
        return this.model.getCaptionsList();
    },

    /**
     * Get currently playing captions
     */
    getCurrentCaptions() {
        return this.model.getCurrentCaptions();
    },

    /**
     * Set current captions by passing its index
     * Setting 0 will hide all captions
     */
    setCurrentCaptions(index) {
        return this.model.setCurrentCaptions(index);
    },

    /**
     * Override captions styles
     * @param styles
     */
    setCaptionsStyles(styles) {
        return this.model.setCaptionsStyles(styles);
    },

    /**
     * Get Player DOM Node
     *
     * @returns {*}
     */
    getContainer() {
        return this.model.getContainer();
    },

    stop() {
        return this.model.stop();
    },

    /**
     * Set asset resource
     * @param asset - Asset model or stream id
     */
    async setAsset(asset) {
        // cleanup old asset
        if (this.asset) {
            this.stopListening(this.asset);
            this.asset.destroy();
            this.asset = null;
        }

        // change asset to object if it is a number
        if (utils.isNumber(asset)) {
            // eslint-disable-next-line no-param-reassign
            asset = {
                id: asset
            };
        }

        this.asset = (asset instanceof Asset) ? asset : new Asset(asset);

        // set vendor for player's default if nothing has been passed
        if (!this.asset.get('vendor')) {
            this.asset.set('vendor', this.config.get('vendor'));
        }

        // asset is ready if status field is in response
        if (this.asset.get('status')) {
            onAssetReady.call(this);
        } else {
            try {
                await this.asset.fetch();
                onAssetReady.call(this);
            } catch (error) {
                onAssetError.call(this, error);
            }
        }
    },

    /**
     * Get current loaded asset
     * @returns {null}
     */
    getAsset() {
        return this.asset;
    },

    /**
     * Add plugin to the player
     *
     * @param plugin
     */
    addPlugin(plugin) {
        const proxyPluginEvent = function (event, ...args) {
            this.trigger(`${plugin.getName()}:${event}`, ...args);
        };

        plugin.setPlayer(this);
        this.listenTo(plugin, 'all', proxyPluginEvent, this);

        this.plugins[plugin.getName()] = plugin;
    },

    /**
     * Get plugin by name
     *
     * @param plugin - name of the plugin
     * @returns {*}
     */
    getPlugin(plugin) {
        return this.plugins[plugin];
    }
};

/**
 * Checks if device can autoplay stream
 *
 * @returns {*}
 */
Player.canDeviceAutoplay = function () {
    // eslint-disable-next-line no-console
    if (console && console.warn) {
        // eslint-disable-next-line no-console
        console.warn('SVP Player SKD deprecation warning. '
            + 'Due to changes in browser policies, player will detect itself if it can autoplay.');
    }

    return utils.device.canAutoplay();
};

utils.extend(Player.prototype, utils.Events);

export default Player;
