import utils from 'utils/utils';
import appnexus from 'player/playback/ads/appnexus';

function getPlaylist(streamUrl, stream, options, settings) {
    const {
        locale, poster, title, description, minDvrWindow
    } = options;

    function addPlaylistMetadata(playlistItem) {
        const mediaId = stream.getExternalId();
        const startTime = stream.getPlaybackTime('begin');

        // set media id only if it exists
        if (mediaId) {
            playlistItem.mediaid = mediaId;
        }

        // add title
        if (title) {
            playlistItem.title = title;
        }

        if (description) {
            playlistItem.description = description;
        }

        // seek stream if play ahead time is set
        if (startTime) {
            playlistItem.starttime = startTime;
        }

        if (minDvrWindow) {
            playlistItem.minDvrWindow = minDvrWindow;
        }
        return playlistItem;
    }

    function addProgressiveMediaSources(playlistItem) {
        utils.each(stream.getMp4Streams(), function (mp4Stream) {
            playlistItem.sources.push({
                label: `${mp4Stream.height}p`,
                file: mp4Stream.source
            });
        });

        return playlistItem;
    }

    function addSnapshots(playlistItem) {
        if (stream.hasSnapshots()) {
            // add snapshots for each stream
            playlistItem.tracks.push({
                file: stream.getSnapshots(),
                kind: 'thumbnails'
            });
        }

        return playlistItem;
    }

    function addCaptions(playlistItem) {
        if (stream.getCaptions().length > 0) {
            utils.each(stream.getCaptions(), function (caption) {
                playlistItem.tracks.push({
                    file: caption.url,
                    label: locale.translate(caption.language),
                    kind: 'captions',
                    default: caption.default === true
                });
            });
        }

        return playlistItem;
    }

    function addAppnexusAds(adConfig, playlistItem) {
        const { adSchedule, adPlacements } = (adConfig || {});

        // check if there are ads
        if (adSchedule && Object.keys(adSchedule).length > 0) {
            utils.extend(playlistItem, {
                adschedule: adSchedule
            });
        }

        // check if there are ads
        if (adPlacements) {
            playlistItem.adPlacements = adPlacements;
        }

        return playlistItem;
    }

    function add3dSupport(playlistItem) {
        if (stream.getDisplayType() === '360') {
            playlistItem.stereomode = 'monoscopic';
        }

        return playlistItem;
    }

    return function (config) {
        const type = stream.get('mediaType') === 'audio' && stream.hasMp4Streams() ? 'mp4' : 'hls';
        return Promise.resolve({
            id: stream.getId(),
            image: poster,
            sources: [{
                file: type === 'mp4' ? stream.getUrl(type) : streamUrl,
                // force stream type for mock stream which contains mp4 blob
                type: stream.isMock() ? 'video/mp4' : type,
                default: true,
                preload: 'auto'
            }],
            mediaType: stream.get('mediaType'),
            tracks: []
        })
            .then(addPlaylistMetadata)
            .then(addProgressiveMediaSources)
            .then(addCaptions)
            .then(addSnapshots)
            .then(add3dSupport)
            .then(function (playlistItem) {
                return appnexus.getConfig(stream, settings).then(function (adConfig) {
                    const adOptions = adConfig.adOptions || {};

                    config.advertising = utils.extend({
                        client: 'vast'
                    }, locale.translate('ads', true));

                    if (typeof adOptions.autoplayMuted !== 'undefined') {
                        config.advertising.autoplayadsmuted = adOptions.autoplayMuted;
                    }

                    if (adOptions.vpaidcontrols === true) {
                        config.advertising.vpaidcontrols = true;
                    }

                    if (adOptions.preloadAds === true) {
                        config.advertising.preloadAds = true;
                    }

                    // do not add any ads to playlist when it is in preload mode
                    if (stream.isMock()) {
                        return playlistItem;
                    }

                    return addAppnexusAds(adConfig, playlistItem);
                });
            })
            .then(function (playlistItem) {
                if (!config.playlist) {
                    config.playlist = [];
                }
                config.playlist.push(playlistItem);

                playlistItem.svp = {
                    stream: stream,
                    settings: settings
                };

                return config;
            });
    };
}

export default getPlaylist;
