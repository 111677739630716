
export default {
    default: 'OjqkBWp9VpYP8utpWdzBZ+eZIJhcfOW5U9g4/w==',
    //
    '100sport': 'Uq5GO7mRz+oxp7pVh3bZEvDEBzEYRTG4h3sr1Q==',
    ab: 'qK+optxkJTV2KblAIiLdoWze6AAGxX5Qxeb57A==',
    adressa: 'BQs7m1EiK0wlK8xAZQgJwU1a4OskbKgoYRSEfA==',
    adressabrandstudio: 'fuz93B3kwPfLDZv+opQh6xasO9nRkraYSbQP0A==',
    altaposten: 'Y0grkUQ6BaCat409fP7mPqr1SbDS+LntWrQIpg==',
    ap: 'ElscszbjUK+A0En7My3UPpJ/4MlOUOn+fiU2/w==',
    bt: 'wgmdmAfgbneZjUBt8ZxqiKTxVCpriTwcC06hzQ==',
    brandstudio: 'uXOJWRxZaxOSRhIisAGWsEbzn3PIhbTlCn99IQ==',
    folkebladet: 'kGoJc75y4Uq1r2kw2n883oYzGk+SrEJ2GEDQUQ==',
    fvn: '88vcnFV8g4crXq1P8jrGz8D7XqMwawGvwFmhJg==',
    harstad: 'C8hK/HIbIL3k9h4dN4xY3lHAPdjLyx7B4WGM/w==',
    itromso: 'QAPvDWM3mHi3lYOZCLqOwHZMstixgFefhc/d1Q==',
    podcast: 'X2K7i+dwWXsIR0BKyX+3C+8s4y9VOWcYgtjeJA==',
    rbnett: 'y7Z4M5Ah6dOyDH7/ZrCQjHZPb3E3xnctwkixbw==',
    riks: 'a5kTQmkeGHV+P2wOaTQvAuXEdivj2lbXoAxTbw==',
    ridenews: '6s5IkHj1LiODLxDOaYr1ze6F7F1Cun92UFuOpA==',
    sa: 'dWpue+L8nJKbDFqh9Ps3o2mxqr92MY2G9tQohg==',
    schibsted: 'Cn+pnRPeR7dlwUgy+QwoSwyu0NXoGUtxD/LVqA==',
    smp: 'eZ34FUhLc6cW3MypHSgtbYwRgbDxKLV/iaABmQ==',
    svd: 'Olwljnb5W0VdUf38yWyv3kjowR9JamQYw4RndA==',
    tronderavisa: '7azVhGsMlCm9EuRPr4EJ8RY6cyVbmAgeS1MkKw==',
    vgtv: 'edF0bFyKcM68omCq24JsMWw/CO/f6cAgS91oiQ==',
    vgtvs: 'D/kRElM9UDSgZxbEqPBESxIKamNBql5PeIabpg=='
};
