
    
    var userAgent = navigator.userAgent;

    function userAgentMatch(regex) {
        return userAgent.match(regex) !== null;
    }

    function lazyUserAgentMatch(regex) {
        return function () {
            return userAgentMatch(regex);
        };
    }

    var isFF = lazyUserAgentMatch(/gecko\//i);
    var isIETrident = lazyUserAgentMatch(/trident\/.+rv:\s*11/i);
    var isIPad = lazyUserAgentMatch(/iPad/i);
    var isIPhone = lazyUserAgentMatch(/iPhone/i);
// Check for Facebook App Version to see if it's Facebook

    function isEdge() {
        return userAgentMatch(/\sEdge\/\d+/i);
    }

    function isMSIE() {
        return userAgentMatch(/msie/i);
    }

    function isChrome() {
        return userAgentMatch(/\s(?:Chrome|CriOS)\//i) && !isEdge();
    }

    function isIE() {
        return isEdge() || isIETrident() || isMSIE();
    }

    function isSafari() {
        return userAgentMatch(/safari/i) && !userAgentMatch(/(?:Chrome|CriOS|chromium|android)/i);
    }

    /** Matches iOS devices **/
    function isIOS(version) {
        var isAppleIOS = userAgentMatch(/iP(hone|ad|od)/i),
            result = null;

        // check if version matches
        if(isAppleIOS && version) {
            result = /OS ([._\d]+)/.exec(userAgent);

            // get version and parse it
            if(result && parseInt(result[1], 10) === version) {
                return true;
            } else {
                // didn't match version
                return false;
            }
        }

        return isAppleIOS;
    }

    function isAndroid() {
        return userAgentMatch(/Android/i);
    }

    /** Matches Android devices **/
    function isAndroidNative() {
        // Android Browser appears to include a user-agent string for Chrome/18
        if (userAgentMatch(/chrome\/[123456789]/i) && !userAgentMatch(/chrome\/18/i) && !isFF()) {
            return false;
        }
        return isAndroid();
    }

    /**
     * If the browser has flash capabilities, return the flash version
     */
    function flashVersion() {
        if (isAndroid()) {
            return 0;
        }

        var plugins = navigator.plugins;
        var flash;

        if (plugins) {
            flash = plugins['Shockwave Flash'];
            if (flash && flash.description) {
                return parseFloat(flash.description.replace(/\D+(\d+\.?\d*).*/, '$1'));
            }
        }

        if (typeof window.ActiveXObject !== 'undefined') {
            try {
                flash = new window.ActiveXObject('ShockwaveFlash.ShockwaveFlash');
                if (flash) {
                    return parseFloat(flash.GetVariable('$version').split(' ')[1].replace(/\s*,\s*/, '.'));
                }
            } catch (e) {
                return 0;
            }

            return flash;
        }
        return 0;
    }

    export default {
        isIOS: isIOS,
        isIPhone: isIPhone,
        isIPad: isIPad,
        isSafari: isSafari,

        isAndroid: isAndroid,
        isAndroidNative: isAndroidNative,
        isChrome: isChrome,

        isFirefox: isFF,

        isEdge: isEdge,
        isIE: isIE,

        isDesktop: function () {
            return !this.isMobile() && !this.isTablet();
        },

        isSamsungInternet: function () {
            return userAgent.indexOf('SamsungBrowser') !== -1;
        },

        isTablet: function () {
            var isTablet = isIPad() || false;

            // android tables are when
            if (this.isAndroid() && (userAgent.indexOf('Mobile') === -1)) {
                isTablet = true;
            }

            return isTablet;
        },

        isMobile: function () {
            return userAgent.match(/(iPhone|iPod|Android(?=.*Mobile).*|HTC|Fennec|IEMobile|BlackBerry|SymbianOS(?=.*AppleWebKit).*|Opera Mobi|Symbian|SonyEricsson|Nokia|SAMSUNG|LG)/i) === null ? false : true;
        },

        isWindowsPhone: function () {
            return userAgent.match(/Windows Phone/i) !== null;
        },

        getChromeVersion: function () {
            var raw = userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

            return raw ? parseInt(raw[2], 10) : false;
        },

        getPixelRatio: function () {
            var devicePixelRatio = window.devicePixelRatio || 1;

            if (devicePixelRatio > 1) {
                devicePixelRatio = 2;
            }

            return devicePixelRatio;
        },

        getFlashVersion: flashVersion,


        /**
         * Tracking is divided by device type
         *
         * @returns {*}
         */
        getDeviceType: function () {
            if (this.isTablet()) {
                return 'tablet';
            }

            if (this.isMobile()) {
                return 'phone';
            }

            return 'desktop';
        },

        canAutoplay: function () {
            var canAutoplay = 'always';

            // default is muted for never ios systems
            if (this.isIOS()) {
                canAutoplay = 'muted';
            }

            // iOS 8 and 9 can not autoplay any kind of stream
            // also Android Devices are lacking this
            if (this.isIOS(9) || this.isIOS(8) || (this.isAndroid())) {
                canAutoplay = 'never';
            }

            // chrome android can autoplay muted
            if (this.isAndroid() && !this.isSamsungInternet() && this.getChromeVersion() >= 53) {
                canAutoplay = 'muted';
            }

            return canAutoplay;
        }
    };

