import utils from './utils';
    /**
     * Server time helper required for syncing browser time with stream vendors servers (SMP Stream)
     * @type {{_time: null, _responseTime: null, fetch: Function, getTime: Function}}
     */
    var ServerTime = {
        _time: null,
        _responseTime: null,

        fetch: function(url) {
            var resolveRequest = function (time) {
                this._time = time;
                this._responseTime = new Date().getTime();
            }.bind(this);

            // TODO server url?
            utils.ajax(url, function(result) {
                // set current microtime if server will return value different than int
                if(isNaN(parseInt(result, 10))) {
                    result = (new Date().getTime());
                } else {
                    // adjust to miliseconds
                    result = result * 1000;
                }
                resolveRequest(result);
            }.bind(this), function() {
                resolveRequest(new Date().getTime());
            }.bind(this), true);
        },

        getTime: function() {
            return this._time + (new Date().getTime() - this._responseTime);
        }
    };

    export default ServerTime;

