const submenuHandler = (container) => {
    container.querySelector('.jw-controlbar .jw-button-container').addEventListener('click', (event) => {
        const { target } = event;

        // eslint-disable-next-line no-console
        if (target.classList.contains('jw-settings-submenu-button')
            || target.classList.contains('jw-settings-sharing')) {
            const submenu = container.querySelector('.jw-settings-menu');

            // eslint-disable-next-line max-len
            const right = Math.round(
                (container.offsetWidth - (target.offsetLeft + target.offsetWidth / 2)) - submenu.offsetWidth / 2
            );

            submenu.style.right = `${right}px`;
        }
    });
};

export default (svpPlayer) => {
    if (!svpPlayer.player.getControls()) {
        return;
    }

    svpPlayer.on('ready', () => submenuHandler(svpPlayer.getContainer()));
};
