/* eslint-disable max-len */
import utils from 'utils/utils';
import locale from 'player/model/locale';
import logger from 'utils/logger';

import playIcon from 'icons/play.svg';
import pauseIcon from 'icons/pause.svg';
import audioIcon from 'icons/audio.svg';
import audioMuted from 'icons/audio-muted.svg';
import homad from './homad-lib';

function getAdPotTag(offset, adschedule) {
    const keys = Object.keys(adschedule);
    let tag = null;

    utils.each(keys, function (key) {
        if (key.indexOf(offset) > -1) {
            // eslint-disable-next-line prefer-destructuring
            tag = adschedule[key].tag;
        }
    });

    return tag;
}

function getTagUrl(potOffset) {
    return function (player) {
        const playlistItem = player.getPlaylist()[0];
        let tag = false;

        if (playlistItem.adschedule) {
            tag = getAdPotTag(potOffset, playlistItem.adschedule);
        }

        if (tag) {
            tag = tag.replace('vmap', 'ssvmap');
        } else {
            tag = false;
        }

        return tag;
    };
}


const getHomadConfig = () => ({
    globalConfig: 'https://s3.amazonaws.com/homad-global-configs.schneevonmorgen.com/global_config.json',
    clientConfig: '/homad-config.json',
    admessage() {
        return locale.translate('adblock').admessage;
    },
    skipable: true,
    skipAdOnContextChange: true,
    runInsideContainer: true,
    adjustAdVolumeToContentPlayer: true,
    postrollUseCompleteEvent: false,
    onReady() {
    },
    muteButton: `<div aria-label="Volume" role="button" tabindex="0" class="jw-icon jw-icon-volume jw-button-color jw-reset">${audioIcon}</div>`,
    unmuteButton: `<div aria-label="Volume" role="button" tabindex="0" class="jw-icon jw-icon-volume jw-button-color jw-reset jw-off">${audioMuted}</div>`,
    playButton: `<div class="jw-icon jw-icon-inline jw-button-color jw-reset" role="button" tabindex="0" aria-label="Play">${playIcon}</div>`,
    pauseButton: `<div class="jw-icon jw-icon-inline jw-button-color jw-reset" role="button" tabindex="0" aria-label="Pause">${pauseIcon}</div>`,
    skipableButton: (function () {
        const adMessages = locale.translate('ads');
        return `<div class="jw-skip jw-reset" tabindex="0" role="button" aria-label="${adMessages.skiptext}" style="bottom: 0">
        <span class="jw-text jw-skiptext jw-reset" style="white-space: nowrap">${adMessages.skipmessage.replace('xx', '[time]')}</span>
        <span class="jw-icon jw-icon-inline jw-skip-icon jw-reset">
        <svg xmlns="http://www.w3.org/2000/svg" class="jw-svg-icon jw-svg-icon-next" viewBox="0 0 240 240">
        <path d="M165,60v53.3L59.2,42.8C56.9,41.3,55,42.3,55,45v150c0,2.7,1.9,3.8,4.2,2.2L165,126.6v53.3h20v-120L165,60L165,60z"></path>
        </svg></span></div>`;
    }()),
    skipButton: (function () {
        const adMessages = locale.translate('ads');

        return `<div class="jw-skip jw-reset jw-skippable" style="bottom: 0" tabindex="0" role="button" aria-label="${adMessages.skiptext}">
        <span class="jw-text jw-skiptext jw-reset">${adMessages.skiptext}</span><span class="jw-icon jw-icon-inline jw-skip-icon jw-reset">
        <svg xmlns="http://www.w3.org/2000/svg" class="jw-svg-icon jw-svg-icon-next" viewBox="0 0 240 240">
        <path d="M165,60v53.3L59.2,42.8C56.9,41.3,55,42.3,55,45v150c0,2.7,1.9,3.8,4.2,2.2L165,126.6v53.3h20v-120L165,60L165,60z"></path></svg></span></div>`;
    }()),
    'innerWarpper.style': '',
    prerollAdTag: getTagUrl('pre'),
    midrollAdTag: getTagUrl('mid'),
    postrollAdTag: getTagUrl('post')
});

/**
 * Homad AAB ads have single event bus for all players
 *
 * @param event
 */
function parseHomadEvent(event) {
    // homad event details are under detils property
    const details = event && event.detail;
    logger('HomadPlugin').log('event', details);

    // skip parsing when there is missing data or player is not registered
    if (!details || !this.events[details.containerId]) {
        return;
    }

    this.events[details.containerId].trigger(details.name, details);
}

export default utils.extend({
    // events hashmap playerId -> eventBus
    // each player has its own events bus
    events: {},
    initialize() {
        const SvpPlayer = window.SVP.Player;

        if (!SvpPlayer.isHomadLoaded) {
            SvpPlayer.isHomadLoaded = true;

            const homadConfig = getHomadConfig();

            // override aab config url
            if (SvpPlayer.defaults.aabConfig) {
                homadConfig.clientConfig = SvpPlayer.defaults.aabConfig;
            }

            homad(homadConfig);

            window.addEventListener('hdEvent', parseHomadEvent.bind(this));
        }
    },

    /**
         * Get events for specific player
         * Events are matched by player id
         *
         * @param playerId
         * @returns {*}
         */
    getEvents(playerId) {
        // create event bus for specific player if this doesn't exist
        if (!this.events[playerId]) {
            this.events[playerId] = utils.extend({}, utils.Events);
        }

        return this.events[playerId];
    },

    /**
         * Remove player events from messaging bus
         * Prevents memory leaks
         *
         * @param playerId
         */
    removeEvents(playerId) {
        if (this.events[playerId]) {
            this.events[playerId].off();
            this.events[playerId].stopListening();

            this.events[playerId] = null;
            delete this.events[playerId];
        }
    }
}, utils.Events);
