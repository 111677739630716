import api from '../api';

const getEndpoint = (categoryId, seasonNumber) => `/categories/${categoryId}/seasons/${seasonNumber}/assets`;

const getNextFromEpisodeList = (assetId, assets) => {
    if (!assets) {
        return null;
    }

    return assets[assets.findIndex((item) => item.id === assetId) - 1];
};

const getNextEpisode = async ({
    vendor, assetId, categoryId, season
}) => {
    const response = await api(
        vendor,
        getEndpoint(categoryId, season)
    );

    const assets = (response && response.assets);

    // empty assetId, get first episode from series which is last in list
    if (assetId === null) {
        return assets[assets.length - 1] || null;
    }

    return getNextFromEpisodeList(assetId, assets);
};


export default {
    getNextEpisode
};
